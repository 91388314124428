import React from "react";
// import Navbar from "../components/Navbar";
import ProjectsName from "../components/ProjectsName";

import TechnologiesPlatforms from "../components/TechnologiesPlatforms";
import GrowthloopsDestination from "../components/GrowthloopsDestination";
import ChooseGrowthLoops from "../components/ChooseGrowthLoops";
import Testimony from "../components/Testimony";
import ContactUsForm from "../components/ContactUsForm";
import CurveTimeline from "../components/CurveTimeline";
import Projects from "../components/Projects";
import WeWork from "../components/WeWork";
import TechnicalExpertise from "../components/TechnicalExpertise";
import Awards from "../components/Awards";
import OurTeam from "../components/OurTeam";
import Navbar from "../components/Navbar";
import TestimonialVideo from "../components/TestimonialVideo";
import CaseStudy from "../components/CaseStudy";

const Home = () => {  
  return (
    <>
      {/* <Navbar /> */}
      <div id="Home">
        <GrowthloopsDestination />
        <Awards />
        <ProjectsName />
        <TechnologiesPlatforms />
        <ChooseGrowthLoops />
        <CurveTimeline />
        <Projects />
        {/* <TechnicalExpertise /> */}
        {/* <OurTeam /> */}
        <CaseStudy />
        <TestimonialVideo />
        <Testimony />
        <WeWork />
        <ContactUsForm />
      </div>
    </>
  );
};

export default Home;
