import React from "react";
// import WeWork_bg from "../assets/images/WeWork/WeWork_bg.png";

import node_icon from "../assets/images/WeWork/node_icon.png";
import react_icon from "../assets/images/WeWork/react-icon.png";
import angularjs from "../assets/images/WeWork/angularjs.png";
import vue_icon from "../assets/images/WeWork/vue_icon.png";
import icon_html from "../assets/images/WeWork/icon_html.png";
import icon_css from "../assets/images/WeWork/icon_css.png";
import icon_js from "../assets/images/WeWork/icon_js.png";
import icon_bootstrap from "../assets/images/WeWork/icon_bootstrap.png";
import icon_aws from "../assets/images/WeWork/icon_aws.png";
import icon_django from "../assets/images/WeWork/icon_django.png";
import icon_kotlin from "../assets/images/WeWork/icon_kotlin.png";
import icon_python from "../assets/images/WeWork/icon_python.png";
import icon_postgres from "../assets/images/WeWork/icon_postgres.png";
import icon_java from "../assets/images/WeWork/icon_java.png";
import icon_blockchain from "../assets/images/WeWork/icon_blockchain.png";
import icon_mysql from "../assets/images/WeWork/icon_mysql.png";
import icon_nextjs from "../assets/images/WeWork/icon_nextjs.png";
import icon_react_native from "../assets/images/WeWork/icon_react_native.png";
import icon_expressjs from "../assets/images/WeWork/icon_expressjs.png";
import icon_flask from "../assets/images/WeWork/icon_flask.png";
import icon_mongoDB from "../assets/images/WeWork/icon_mongoDB.png";
import text_img from "../assets/images/text_img.svg";

const WeWork = () => {
  const icons = [
    { image: node_icon, name: "Node.js" },
    { image: react_icon, name: "React.js" },
    { image: angularjs, name: "Angular" },
    { image: vue_icon, name: "Vue" },
    { image: icon_html, name: "HTML" },
    { image: icon_css, name: "CSS" },
    { image: icon_js, name: "JS" },
    { image: icon_bootstrap, name: "Bootstrap" },
    { image: icon_aws, name: "AWS" },
    { image: icon_django, name: "Django" },
    { image: icon_kotlin, name: "Kotlin" },
    { image: icon_python, name: "Python" },
    { image: icon_postgres, name: "Postgres" },
    { image: icon_java, name: "Java" },
    { image: icon_blockchain, name: "Blockchain" },
    { image: icon_react_native, name: "React Native" },
    { image: icon_mysql, name: "MySQL" },
    { image: icon_nextjs, name: "NextJS" },
    { image: icon_flask, name: "Flask" },
    { image: icon_expressjs, name: "ExpressJS" },
    { image: icon_mongoDB, name: "MongoDB" },
  ];  
  return (
    <div className="flex flex-col items-center justify-center my-[16vh]">
      {/* <div className="absolute">
        <img src={WeWork_bg} alt="WeWork_bg" className="w-[90rem] opacity-60" />
      </div> */}
      <div className="text-center px-4 md:px-8 lg:px-16">
        <h1 className="text-lg md:text-2xl text-darkblue font-semibold">
          Tech
        </h1>
        <div className="flex justify-center">
          <h1 className="text-xl md:text-3xl font-semibold">We Work On</h1>
          <img src={text_img} alt="Button Icon" className="h-6" />
        </div>
        <p className="text-[#595F66] text-[10px] md:text-[18px] font-light md:w-full lg:w-[55rem] mx-auto">
          Empowering Your Vision with Growth Loops Technology. Company's
          commitment to using technology to bring their clients' visions to
          life.
        </p>
      </div>
      <div className="grid grid-cols-3 md:grid-cols-7 gap-x-6 md:gap-x-16 gap-y-12 justify-around mt-[10vh]">
        {icons.map((icon, index) => (
          <div
            key={index}
            // className={`shadow-[inset_-2px_-8px_20px_1px_#e2e8f0] px-3 py-2 rounded-lg flex flex-col items-center transition-transform transform-gpu hover:scale-110 duration-300 ${
            //   Math.floor(index / 7) % 2 === 0 ? evenRowClass : oddRowClass
            // }`}
            className="shadow-[inset_-2px_-8px_20px_1px_#e2e8f0] px-3 py-2 rounded-lg flex flex-col items-center transition-transform transform-gpu hover:scale-110 duration-300"
          >
            <div className="w-[7vh] h-[7vh] flex justify-center items-center bg-gray-200 rounded-full">
              <img src={icon.image} alt={icon.name} />
            </div>
            <div>
              <label className="text-center text-xs md:text-sm font-medium">
                {icon.name}
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeWork;
