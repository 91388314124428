import {
  BrowserRouter,
  Route,
  BrowserRouter as Router,
  Routes,
  Switch,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
// import Careers from "./pages/Careers";
// import Portfolio from "./pages/Portfolio";
// import Navbar from "./components/Navbar";
// import Footer from "./Footer/Footer";
import Schedule from "./pages/Schedule";
import ClutchLanding from "./LandingPage/ClutchLanding";
import { useQueryParams } from "./CustomHooks/useQueryParams";
import baseUrl from "./utils/config";
import AboutUs from "./AboutUs/AboutUs";
import ErrorPage from "./Error";
import HomePage from "./pages/HomePage";
import CaseStudies from "./CaseStudies/CaseStudies";
import HappyChat from "./CaseStudies/HappyChat";
import Imongu from "./CaseStudies/Imongu";
import CreativesMatter from "./CaseStudies/CreativesMatter";
import Jaba from "./CaseStudies/Jaba";
import DroneTjek from "./CaseStudies/DroneTjek";
import Rapiq from "./CaseStudies/Rapiq";
import Simplyfema from "./CaseStudies/Simplyfema";
function App() {
  useQueryParams(baseUrl);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />}>
          <Route path="/" element={<Home />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/case-studies/happychat" element={<HappyChat />} />
          <Route path="/case-studies/imongu" element={<Imongu />} />
          <Route
            path="/case-studies/creativesMatter"
            element={<CreativesMatter />}
          />
          <Route path="/case-studies/jaba" element={<Jaba />} />
          <Route path="/case-studies/DroneTjek" element={<DroneTjek />} />
          <Route path="/case-studies/rapiq" element={<Rapiq />} />
          <Route path="/case-studies/simplyfema" element={<Simplyfema />} />
          <Route path="/clutch" element={<ClutchLanding />} />
          {/* <Route path="/careers" element={<Careers />} /> */}
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
