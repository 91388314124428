import React from "react";
import Expertise_bg from "../assets/images/Expertise_bg.png";
const TechnicalExpertise = () => {
  return (
    <>
      <div className="py-20 my-52 flex flex-col justify-center items-center overflow-hidden">
        <div className="absolute">
          <img
            src={Expertise_bg}
            alt="Project_bg"
            className="w-[70rem] h-[50rem]"
          />
        </div>
        <div className="text-[7.5rem] -rotate-6 text-outline text-nowrap">
          Unlock your <span className="text-[#002298]">potential</span> through
          our
        </div>
        <div className="text-[7.5rem] -rotate-6 text-outline text-nowrap">
          <h1>
            advanced <span className="text-[#002298]">technical expertise</span>
          </h1>
        </div>
      </div>
    </>
  );
};

export default TechnicalExpertise;
{
  /* <div className="flex space-x-4 mt-8">
  <div className="text-center border border-gray-600 rounded-full h-20 w-20">
    We
  </div>
  <div className="text-center border border-gray-600 rounded-full h-20 w-20">
    You
  </div>
</div>
<div className="text-center border bg-gray-500 rounded-full h-20 w-20">
  Us
</div> */
}
