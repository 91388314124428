import React, { useEffect } from "react";
// import Card from "./Project_Comp/ProjCardComponent";
import text_img from "../assets/images/text_img.svg";
// import Project_arrow from "../assets/images/projects/Project_arrow.png";
// import Project_bg from "../assets/images/projects/Project_bg.png";
// import { NavLink } from "react-router-dom";

const Projects = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widget.clutch.co/static/js/widget.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <div className="m-[5vh] mt-[8rem] md:mx-[35vh]">
        <div className="mb-20">
          <div className="flex justify-center">
            <h1 className="md:text-3xl text-xl font-semibold text-center">
              Our <span className="text-[#002298]">Awesome </span>
              <span className="text-black">Projects</span>
            </h1>
            <img
              src={text_img}
              alt="Button Icon"
              className="h-6 hidden lg:block"
            />
          </div>
          <p className="text-[#4E4E4E] text-[10px] md:text-[18px] font-medium px-0 md:px-[8vh] leading-6 mt-4">
            If you are a startup or an individual tinkering with an idea but don't have tech prowesss, we are your best friend.
            We will understand your vision and build a working product in less than 90 days that you can take it to your early users and get feedback and iterate.
          </p>
        </div>

        <div
          className="clutch-widget"
          data-url="https://widget.clutch.co"
          data-widget-type="4"
          data-height="auto"
          data-nofollow="true"
          data-expandifr="true"
          data-scale="100"
          data-reviews="277249,276622,275513,274559,274554"
          data-clutchcompany-id="2287707"
        />
      </div>
    </>
  );
};
export default Projects;
{
  /* <div className="absolute">
  <img
    src={Project_bg}
    alt="Project_bg"
    className="w-[140rem] h-[90rem] opacity-40"
  />
</div>
<div>
  <h1 className="text-[25px] pl-[8vh]">
    Our <span className="text-darkblue">Awesome </span>
    <span className="text-[30px] text-black">Projects</span>
  </h1>

  <p className="tracking-wide text-gray-700 text-xl font-light px-[8vh]">
    We have worked with large enterprise like Upwork to early stage
    startups like Happychat AI. We specialize in building Full Stack AI
    and ML products leveraging latest LLM capabilities. We have built
    multiple AI products spanning accross domains.
  </p>
</div>
<div className="mt-[20vh]">
  <Card />
</div> */
}

{
  /* <div className="flex justify-center items-center m-[10vh] cursor-pointer">
  <span>
    <img
      src={Project_arrow}
      alt="Project_arrow"
      className="h-4 ml-[17vh]"
    />
    View All Projects
  </span>
</div> */
}
