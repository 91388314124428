import React from "react";
import button_icon from "../assets/icons/button_icon.svg";
import CustomForm from "./ContactForm";
import Awards from "../components/Awards";
import ProjectsName from "../components/ProjectsName";
import WeWork from "../components/WeWork";
import cluth_bg from "../assets/images/cluth_bg.svg";
import FAQ from "./FAQ";
import Testimonials from "./Testimonials";
import CaseStudy from "../components/CaseStudy";
import { NavLink } from "react-router-dom";
// import bg_blue from "../assets/images/bg_blue.png";
// import bg_blue1 from "../assets/images/bg_blue1.png";
// import bg_yellow from "../assets/images/bg_yellow.png";

const ClutchLanding = () => { 

  const blue_Style = {
    background: "rgba(34, 203, 250, 0.60)",
  };

  // const yellowStyle = {
  //   background: "#FFE500",
  //   backdropFilter: "blur(162px)",
  // };

  // const blueStyle = {
  //   background: "#0C3AEF",
  //   backdropFilter: "blur(282px)",
  // };
  return (
    <>
      <div className="relative mb-32 shadow overflow-hidden">
        {/* Background Circles */}
        <div
          className="absolute top-8 left-10 -z-10 h-96 w-96 blur-[150px] rounded-full"
          style={blue_Style}
        />
        <div className="absolute top-20 right-0 z-0 h-72 w-72 blur-[200px] bg-[#FFE500] rounded-full" />
        <div className="absolute bottom-0 right-0 z-0 h-72 w-72 blur-[150px] bg-[#0C3AEF] rounded-full" />
        {/* Background Image */}
        <img
          src={cluth_bg}
          alt="AboutUs_bg"
          className="h-full md:h-[500px] absolute top-[200px] md:top-[160px] md:left-[74%] transform -translate-x-1/2 z-0 rounded-full"
        />

        {/* Content */}
        <div className="flex flex-col md:flex-row items-center justify-between pt-[100px] md:pt-[155px] px-4 md:px-8 lg:px-12 mx-auto max-w-screen-xl z-10">
          {/* Left Section */}
          <div className="flex flex-col w-full md:w-1/2">
            <div className="text-[#000] mx-4 md:mx-0 mb-8 md:mb-12 lg:mb-16">
              <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-4xl font-semibold">
                Hire Top Rated Software Engineers from India <br />
              </h1>
              <p className="text-base md:text-[17px] lg:text-[17px] my-4 text-[#4E4E4E]">
              Ready to deploy high performing, collaborative Software engineers and Designers that meets your immediate need.
              </p>
              <NavLink
                to="/schedule"
                className="btn-gradient-1 z-10 button"
                role="button"
                style={{
                  borderWidth: "2px",
                  padding: "0.5rem 2rem",
                }}
              >
                Hire at $12/hour
                <img src={button_icon} alt="Button Icon" className="ml-2" />
              </NavLink>
            </div>
          </div>

          {/* Right Section */}
          <div className="flex flex-col w-full md:w-[450px]  p-4 text-center md:mt-0 bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg rounded-lg">
            <h3 className="text-base md:text-lg lg:text-xl xl:text-xl font-semibold mb-5 md:mb-8 text-[#000F40]">
              Schedule 15 mins Discovery call?
              <div class="grad"></div>
            </h3>
            <CustomForm />
          </div>
        </div>
      </div>

      {/* Other Components */}
      <Awards />
      <ProjectsName />
      {/* <ChooseGrowthLoops /> */}
      {/* <TestimonialVideo /> */}
      <Testimonials />
      {/* <Testimony /> */}
      <CaseStudy />
      <FAQ />
      <WeWork />
    </>
  );
};

export default ClutchLanding;