import React from "react";
import case_bg from "../assets/images/CaseStudies/case_bg.png";
import ProjectNames from "./ProjectNames";
import ContactUsForm from "../components/ContactUsForm";

const CaseStudies = () => {
  return (
    <div>
      <div className="relative mx-4 md:mx-10 mb-12">
        <img
          src={case_bg}
          className="w-full h-auto"
          alt="Case Studies Background"
        />
        <div className="absolute bottom-4 md:bottom-32 lg:bottom-12 mx-2 lg:mx-16">
          <h1 className="text-[#FFF] text-2xl md:text-4xl lg:text-6xl mb-2 md:mb-4">Case Studies</h1>
          <p className="text-[#FFF] text-sm md:text-base lg:text-lg mb-4 md:mb-8">
            Journey through Excellence: Discovering Our Project, Portfolios and
            Methodological achievements
          </p>
        </div>
      </div>
      <ProjectNames />
      <ContactUsForm />
    </div>
  );
};

export default CaseStudies;
