import React from "react";
import Gunendu from "../assets/images/AboutUs/team_member/gunendu.jpg";
import { TiSocialLinkedin } from "react-icons/ti";
import Founder_bg from "../assets/images/AboutUs/founder_bg.png";
import SocialIcons from "../Footer/SocialIcons";
import arrow_up from "../assets/images/AboutUs/arrow-up.svg";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const FounderDirector = ({ Icons }) => {
  const socialIconsData = [
    { name: "logo-linkedin", link: "https://www.linkedin.com/" },
  ];

  return (
    <div className="flex flex-col md:flex-row my-16 md:my-32 mx-6 lg:mx-60">
      <div className="md:w-1/2 relative">
        <img
          src={Gunendu}
          alt="Gunendu"
          className="rounded-xl object-cover h-[250px] w-[200px] md:h-[350px] md:w-[300px]"
        />
        <div className="text-center absolute left-0 -bottom-4 border bg-[#EBF3FF] px-[3rem] py-2.5 md:px-16 md:py-3 lg:px-16 lg:py-3 rounded-tr-[6rem] md:rounded-tr-[6rem] rounded-bl-[6rem] md:rounded-bl-[6rem] ml-16 md:ml-32">
          <h1 className="text-[#000F40] font-semibold text-sm md:text-lg">
            Gunendu Das
          </h1>
        </div>
      </div>
      <div className="md:w-5/6 text-[#000] my-8 md:my-10 space-y-4">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          transition={{ duration: 0.6 }}
          variants={fadeInUp}
        >
          <h2 className="text-xl md:text-3xl text-[#000F40] font-semibold">
            Founder and Director
          </h2>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          variants={fadeInUp}
        >
          <h3 className="text-[16px] leading-6 text-gray-800">
            Gunendu has a unique skill set that lies in the intersection of
            product and technology. He believes in building compelling products
            with the help of the latest technologies. Having worked for more than
            a decade with early-stage startups and large orgs, he sets the vision
            for the company. He holds a computer science degree from NIT
            Allahabad.
          </h3>
        </motion.div>
        <div className="flex items-center my-2 md:my-4 space-x-5">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            variants={fadeInUp}
          >
            <SocialIcons Icons={socialIconsData} />
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            transition={{ duration: 0.6, delay: 0.6 }}
            variants={fadeInUp}
          >
            <NavLink
              to="/schedule"
              target="_blank"
              className="text-white bg-[#091D62] border rounded-full py-1.5 pl-2 md:pl-5 pr-2 relative flex items-center space-x-1 md:space-x-2 cursor-pointer z-10"
            >
              <span className="">Let’s Connect</span>
              <div className="bg-[#5AC4FE] rounded-full p-0.5 md:p-1">
                <img src={arrow_up} alt="arrow_up" className="h-4 md:h-6" />
              </div>
            </NavLink>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default FounderDirector;

