export const QUICKLINKS = [
  { name: "Frontend Development", link: "#" },
  { name: "Backend Development", link: "#" },
  { name: "Mobile App Development", link: "#" },
  { name: "Servers & Cloud Infrastructures", link: "#" },
  { name: "Data Base", link: "#" },
  { name: "AI & Natural Languages", link: "#" },
];
export const FEATURES = [
  { name: "Home", link: "/", path: "/" },
  { name: "Services", path: "Services", id: "services" },
  { name: "Case Studies", link: "#", path: "/case-studies" },
  { name: "About Us", link: "#", path: "/aboutus" },
];
export const ABOUT = [
  { name: "Company", link: "#" },
  { name: "Careers", link: "#" },
  { name: "Contact us", link: "#" },
];

export const Icons = [
  { name: "logo-linkedin", link: "https://www.linkedin.com/company/growth-loop-technology/mycompany/" },
  { name: "logo-facebook", link: "https://www.facebook.com/people/Growth-Loops-Technology/100090273445902/" },
  { name: "logo-x", link: "https://twitter.com/growth_loop_" },
];
