import React from "react";
import { motion } from "framer-motion";
import arrow_1 from "../assets/images/Awards/Arrow 1.svg";
import text_img from "../assets/images/text_img.svg";
import clutch from "../assets/images/Awards/clutch.png";
import goodfirms from "../assets/images/Awards/goodfirms.jpg";
import upwork from "../assets/images/Awards/upwork.png";
import Software_Outsourcing_Journal from "../assets/images/Awards/Software-Outsourcing-Journal.png";

const awardsImages = {
  clutch,
  goodfirms,
  upwork,
  Software_Outsourcing_Journal,
};

const items = [
  {
    name: "clutch",
    link: "https://clutch.co/profile/growth-loops-technology?utm_source=clutch_top_company_badge&utm_medium=image_embed#highlights",
  },
  {
    name: "goodfirms",
    link: "https://www.goodfirms.co/company/growth-loops-technology-pvt-ltd",
  },
  {
    name: "upwork",
    link: "https://www.upwork.com/agencies/1631302858153783296/",
  },
  {
    name: "Software_Outsourcing_Journal",
    link: "https://softwareoutsourcing.com/companies/",
  },
];

const containerVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2,
      duration: 0.2,
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: -30 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
};

const Awards = () => {
  return (
    <motion.div
      className="flex flex-col items-center space-y-6 md:mb-44 mb-32"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.5 }}
      variants={containerVariants}
    >
      <motion.div className="flex items-center" variants={itemVariants}>
        <h1 className="text-[#000F40] text-xl md:text-3xl font-semibold">
          Awards <span className="text-[#002298]">&</span> Recognition
        </h1>
        <img src={text_img} alt="Button Icon" className="h-6 ml-2" />
      </motion.div>
      <motion.p
        className="text-[10px] md:text-[18px] text-center text-[#4E4E4E] mx-6 md:mx-0"
        variants={itemVariants}
      >
        We’re proud to accept Industry allocates for our commitments to unparalleled quality and service.
      </motion.p>
      <motion.div
        className="grid grid-cols-2 md:grid-cols-4 gap-y-6 md:gap-y-0 gap-x-3 md:gap-x-8 lg:gap-x-16 cursor-pointer"
        variants={containerVariants}
      >
        {items.map((item, index) => (
          <motion.a
            key={index}
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            variants={itemVariants}
          >
            <div className="bg-[#FFF] shadow px-1 md:px-4 flex space-x-2 md:space-x-3">
              <img
                src={awardsImages[item.name]}
                alt={item.name}
                className="w-[10vh] md:w-[15vh] h-[10vh] md:h-[15vh] object-contain"
              />
              <div className="flex justify-end items-end">
                <button className="flex items-center">
                  See more
                  <img
                    src={arrow_1}
                    alt="See more"
                    className="w-5 h-10 inline-block ml-1 transition-transform duration-300 ease-in-out transform hover:translate-x-2"
                  />
                </button>
              </div>
            </div>
          </motion.a>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default Awards;


// import React from "react";
// import arrow_1 from "../assets/images/Awards/Arrow 1.svg";
// import text_img from "../assets/images/text_img.svg";
// import clutch from "../assets/images/Awards/clutch.png";
// import goodfirms from "../assets/images/Awards/goodfirms.jpg";
// import upwork from "../assets/images/Awards/upwork.png";
// import Software_Outsourcing_Journal from "../assets/images/Awards/Software-Outsourcing-Journal.png";
// import 'animate.css';

// const awardsImages = {
//   clutch,
//   goodfirms,
//   upwork,
//   Software_Outsourcing_Journal,
// };

// const items = [
//   {
//     name: "clutch",
//     link: "https://clutch.co/profile/growth-loops-technology?utm_source=clutch_top_company_badge&utm_medium=image_embed#highlights",
//   },
//   {
//     name: "goodfirms",
//     link: "https://www.goodfirms.co/company/growth-loops-technology-pvt-ltd",
//   },
//   {
//     name: "upwork",
//     link: "https://www.upwork.com/agencies/1631302858153783296/",
//   },
//   {
//     name: "Software_Outsourcing_Journal",
//     link: "https://softwareoutsourcing.com/companies/",
//   },
// ];

// const Awards = () => {
//   return (
//     <div className="flex flex-col items-center space-y-6 md:mb-44 mb-32">
//       <div className="flex items-center">
//         <h1 className="text-[#000F40] text-xl md:text-3xl font-semibold">
//           Awards <span className="text-[#002298]">&</span> Recognition
//         </h1>
//         <img src={text_img} alt="Button Icon" className="h-6 ml-2" />
//       </div>
//       <p className="text-[10px] md:text-[1rem] text-center text-[#595F66] mx-6 md:mx-0">
//         We’re proud to accept Industry allocates for our commitments to unparalleled quality and service.
//       </p>
//       <div className="grid grid-cols-2 md:grid-cols-4 gap-y-6 md:gap-y-0 gap-x-3 md:gap-x-8 lg:gap-x-16 cursor-pointer">
//         {items.map((item, index) => (
//           <a key={index} href={item.link} target="_blank" rel="noopener noreferrer">
//             <div className="bg-[#FFF] shadow px-1 md:px-4 flex space-x-2 md:space-x-3">
//               <img
//                 src={awardsImages[item.name]}
//                 alt={item.name}
//                 className="w-[10vh] md:w-[15vh] h-[10vh] md:h-[15vh] object-contain"
//               />
//               <div className="flex justify-end items-end">
//                 <button className="flex items-center">
//                   See more
//                   <img
//                     src={arrow_1}
//                     alt="See more"
//                     className="w-5 h-10 inline-block ml-1 transition-transform duration-300 ease-in-out transform hover:translate-x-2"
//                   />
//                 </button>
//               </div>
//             </div>
//           </a>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Awards;
