import React from "react";
import GL_logo from "../assets/images/GL_logo.jpeg";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";

// const CONTACT_DETAILS = [
//   {
//     icon: (
//       <FaPhoneAlt className="p-1 inline-flex items-center rounded-full bg-gray-700 mx-1.5 text-sm" />
//     ),
//     text: "+91 9880806633",
//   },
//   {
//     icon: (
//       <IoMail className="p-1 inline-flex items-center rounded-full bg-gray-700 mx-1.5 text-sm" />
//     ),
//     text: "gunendu@growth-loop.io",
//   },
//   {
//     icon: (
//       <FaLocationDot className="p-1 inline-flex items-center rounded-full bg-gray-700 mx-1.5 text-sm" />
//     ),
//     text: "Cabin No. 23, Unit No. 12W2, 12th Floor, Mani Casadona, Newown Action Area IIF, Plot 2# F/4, Kolkata 700161",
//   },
// ];

const Details = () => {
  return (
    <div className="grid grid-cols-1">
      <div className="flex flex-col mb-[10vh]">
        <img
          src={GL_logo}
          alt="GL_logo"
          className="h-10 w-[24vh] ml-[-1vh] mb-2"
        />
        <span className="sm:col-span-1 lg:col-span-3 text-[#000F40]">
          © Growth Loops Technology 2024
        </span>
      </div>
      <div className="flex flex-col text-[#000F40] text-sm space-y-1">
        <span className="sm:col-span-1 lg:col-span-3 hover:text-teal-400 duration-300">
          <FaPhoneAlt className="p-1 inline-flex items-center rounded-full bg-gray-700 mr-1.5 text-lg text-[#f1f1f1]" />
          <a href="tel:+919880806633">+91 9880806633</a>
        </span>
        <span className="sm:col-span-1 lg:col-span-3 hover:text-teal-400 duration-300">
          <IoMail className="p-1 inline-flex items-center rounded-full bg-gray-700 mr-1.5 text-lg text-[#f1f1f1]" />
          <a href="mailto:gunendu@growth-loop.io">gunendu@growth-loop.io</a>
        </span>
        <span className="sm:col-span-1 lg:col-span-3 hover:text-teal-400 duration-300">
          <FaLocationDot className="p-1 inline-flex items-center rounded-full bg-gray-700 mr-1.5 text-lg text-[#f1f1f1]" />
          <a href="https://maps.google.com/maps?q=Mani+Casadona,+NewTown+Action+Area+IIF,+Plot+2#+F/4,+Kolkata+700161">
            Cabin No. 23, Unit No. 12W2, 12th Floor, Mani Casadona, NewTown
            Action Area IIF, Plot 2# F/4, Kolkata 700161
          </a>
        </span>
      </div>
      {/* <div className=" text-gray-400 list-none">
        {CONTACT_DETAILS.map((detail, index) => (
          <span key={index} className="sm:col-span-1 lg:col-span-3 flex items-center mb-4">
            {detail.icon}
            <span>{detail.text}</span>
          </span>
        ))}
      </div> */}
    </div>
  );
};

export default Details;
