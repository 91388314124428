import React from "react";
import { caseStudies } from "./ConstantMenus";
import TechStackHeader from "./TechStackHeader";
import ProjectOverView from "./ProjectOverView";
import WebsiteGrowth from "./WebsiteGrowth";
import Awards from "../components/Awards";
import ContactUsForm from "../components/ContactUsForm";
import ProductGlimpse from "./ProductGlimpse";
import ClientFeedback from "./ClientFeedback";
import ClientOurVision from "./ClientOurVision";

const CreativesMatter = () => {
  return (
    <>
      <TechStackHeader caseStudy={caseStudies[2]} />
      <ClientOurVision caseStudy={caseStudies[2]} />
      <ProjectOverView caseStudy={caseStudies[2]} />
      <WebsiteGrowth caseStudy={caseStudies[2]} />
      <ProductGlimpse caseStudy={caseStudies[2]} />
      <ClientFeedback caseStudy={caseStudies[2]} />
      <Awards />
      <ContactUsForm />
    </>
  );
};

export default CreativesMatter;
