import React from "react";

const ProjectOverView = ({ caseStudy }) => {
  const numberColor = caseStudy.numberColor
  return (
    <div className="my-20">
      <div className="mx-auto lg:w-[60%] text-center px-4">
        <h1 className="text-2xl lg:text-3xl font-semibold text-[#000F40] mb-4">
          Project Overview
        </h1>
        <p className="text-[12px] lg:text-[18px] font-medium text-[#444]">
          {caseStudy?.overViewDesc}
        </p>
      </div>
      <div className="mx-auto md:mx-10 lg:mx-14 mt-8">
        {caseStudy.overViewCards?.map((overView, index) => (
          <div
            key={index}
            className={`flex flex-col lg:flex-row items-center mb-8 lg:mb-12 ${
              index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
            }`}
          >
            <div className="relative lg:w-1/2 p-4 lg:p-8">
              <span className="text-[3rem] lg:text-[9rem] absolute -top-14 lg:-top-[1rem] number" style={{ color: numberColor }}>
                {overView?.num}
              </span>
              <h1 className="text-[12px] lg:text-[18px] text-[#444] mt-12 lg:mt-20">
                {overView?.desc}
              </h1>
            </div>
            <img
              src={overView?.image}
              className="w-full lg:w-1/2 h-auto object-cover"
              alt="Project Overview Image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectOverView;




// import React from "react";

// const ProjectOverView = ({ caseStudy }) => {
//   return (
//     <div className="my-20">
//       <div className="mx-auto lg:w-[60%] text-center">
//         <h1 className="text-2xl lg:text-3xl font-semibold text-[#000F40]">
//           Project Overview
//         </h1>
//         <p className="text-base lg:text-lg font-medium text-[#444]">
//           {caseStudy?.overViewDesc}
//         </p>
//       </div>
//       <div className="mx-auto md:mx-10 lg:mx-14">
//         {caseStudy.overViewCards?.map((overView, index) => (
//           <div
//             key={index}
//             className="flex flex-col lg:flex-row items-center mb-8 lg:mb-12"
//           >
//             <div
//               className="relative"
//               style={{ order: index % 2 === 0 ? 1 : 2 }}
//             >
//               <span className="text-[6rem] absolute -top-[8.5rem] number">
//                 {overView?.num}
//               </span>
//               <h1 className="text-lg ">{overView?.desc}</h1>
//             </div>
//             <img
//               src={overView?.image}
//               className="h-[600px] w-full"
//               style={{ order: index % 2 === 0 ? 2 : 1 }}
//               alt="Image"
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ProjectOverView;
