import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const ProductGlimpse = ({ caseStudy }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  return (
    <div className="my-20">
      <div className="mx-4 lg:mx-[20rem] text-center">
        <h1 className="text-2xl sm:text-3xl font-semibold text-[#000F40]">
          Product Glimpse
        </h1>
        <p className="text-[12px] md:text-[18px]">{caseStudy?.desc}</p>
      </div>
      <div className="mx-4 sm:mx-10 lg:mx-20">
        <div className="hidden sm:grid sm:grid-cols-2 gap-4">
          {caseStudy.productGlimpse.glimpseImages?.map((image, index) => (
            <img key={index} src={image} className="h-full w-full" alt={`Image ${index + 1}`} />
          ))}
        </div>
        <div className="sm:hidden">
          <Slider {...settings}>
            {caseStudy.productGlimpse.glimpseImages?.map((image, index) => (
              <div key={index}>
                <img src={image} className="h-full w-full" alt={`Image ${index + 1}`} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ProductGlimpse;



// import React from "react";

// const ProductGlimpse = ({ caseStudy }) => {
//   return (
//     <div className="my-20">
//       <div className="mx-[20rem] text-center">
//         <h1 className="text-3xl font-semibold text-[#000F40]">
//           Product Glimpse
//         </h1>
//         <p className="text-lg font-medium">{caseStudy?.desc}</p>
//       </div>
//       <div className="mx-10 grid grid-cols-2">
//         {caseStudy.productGlimpse.glimpseImages?.map((image, index) => (
//           <img key={index} src={image} className="h-full" alt={`Image`} />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ProductGlimpse;
