import { useEffect } from 'react';
import baseUrl from '../utils/config';

export const useQueryParams = () => {
 useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of queryParams.entries()) {
      params[key] = value;
    }

    const handleParams = async (params) => {
      try {
        const payload = {
          crm_data: {
            utm_source: params.utm_source || "",
            utm_medium: params.utm_medium || "",
            utm_campaign: params.utm_campaign || "",
          },
        };

        const response = await fetch(`${baseUrl}/crm`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`API call failed with status ${response.status}`);
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          const data = await response.json();
          console.log("API response:", data);
        } else {
          // Handle non-JSON responses
          const text = await response.text();
          console.log("API response:", text);
        }
      } catch (error) {
        console.error("Error calling API:", error);
      }
    };

    handleParams(params);
 }, [window.location.search, baseUrl]); 
};
