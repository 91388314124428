import React, { useState, useEffect } from "react";
import shopping_cafe from "../assets/images/shopping_cafe.png";
import Logo from "../assets/images/GL_logo.jpeg";
import baseUrl from "../utils/config";
import button_icon1 from "../assets/icons/button_icon1.svg";
import SocialIcons from "../Footer/SocialIcons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUsForm = () => {
  const socialIconsData = [
    { name: "logo-linkedin", link: "https://www.linkedin.com/" },
    { name: "logo-facebook", link: "https://www.facebook.com/" },
    { name: "logo-x", link: "https://twitter.com/" },
  ];

  const initialFormData = {
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    file: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { name, email, phoneNumber, message } = formData;
      const requiredFields = ["name", "email", "message"];

      // Check if any required field is empty
      const isEmpty = requiredFields.some((field) => !formData[field]);
      if (isEmpty) {
        toast.error("Please fill out all fields");
        return;
      }
      const formDataToSend = new FormData();
      formDataToSend.append("name", name);
      formDataToSend.append("from", email);
      formDataToSend.append("phone", phoneNumber);
      formDataToSend.append("subject", `Query from Client ${name} ${email}`);
      formDataToSend.append("content", message);

      const url = baseUrl + "/lead";
      const response = await fetch(url, {
        method: "POST",
        body: formDataToSend,
      });

      if (response.ok) {
        setIsSubmitted(true);
        setFormData(initialFormData);
        diffToast(); // Show toast message upon successful submission
      } else {
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsSubmitted(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [isSubmitted]);

  const diffToast = () => {
    toast.success("Thank You! We will get back to you.");
  };
  return (
    <>
      <div
        id="contactUsForm"
        className="flex flex-col md:flex-row shadow-xl bg-[#FFF] mx-5 my-24 p-5"
      >
        <div className="md:w-1/2">
          <img
            src={shopping_cafe}
            alt="shopping_cafe"
            className="h-auto md:h-[36rem] w-full md:w-[34rem] rounded-md mb-4 object-cover"
          />
          <img src={Logo} alt="Logo" className="h-[2rem] mb-4" />
          <p className="mb-4 text-[#636E92] text-sm">
            Unleashing potential success with new aged AI tools
            <br /> powered by LLM technology.
          </p>
          {/* <SocialIcons Icons={socialIconsData} /> */}
        </div>
        <div className="md:w-2/3 md:text-start md:pt-0 pt-4 md:mr-10 md:mt-16">
          <div className="">
            <h3 className="text-2xl my-1">
              Get in <span className="text-[#002298]">Touch</span>
            </h3>
            <h1 className="text-4xl mb-3 text-[#000F40] font-semibold">
              Let’s Chat
            </h1>
            <span className="text-[#595F66]">
              Do you have any questions, suggestions or feedback? We’d <br />
              love to hear from you!
            </span>
          </div>
          <form className="mx-auto my-10" onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Full Name*"
                className="bg-gray-100 appearance-none border rounded w-full py-4 px-3 text-[#595F66] leading-tight focus:outline-none focus:shadow-outline"
              />
              </div>
              <div className="mb-4">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Work Email*"
                className="bg-gray-100 appearance-none border rounded w-full py-4 px-3 text-[#595F66] leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4 flex space-x-3">
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="Phone Number"
                className="bg-gray-100 appearance-none border rounded w-full py-4 px-3 text-[#595F66] leading-tight focus:outline-none focus:shadow-outline"
              />
              {/* <input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                placeholder="Company Name*"
                className="bg-gray-100 appearance-none border rounded w-full py-4 px-3 text-[#595F66] leading-tight focus:outline-none focus:shadow-outline"
              /> */}
            </div>
            <div className="mb-4">
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Drop your message*..."
                className="bg-gray-100 appearance-none border rounded w-full py-2 px-3 text-[#595F66] leading-tight focus:outline-none focus:shadow-outline h-36 resize-none"
              ></textarea>
            </div>
            {/* <div className="items-center">
              <input type="checkbox" id="agreeCheckbox" className="mr-1.5" />
              <label htmlFor="agreeCheckbox" className="text-[#595F66]">
                I agree to the{" "}
                <a href="/terms-and-conditions" className="text-[#1772BF]">
                  terms and conditions
                </a>{" "}
                and{" "}
                <a href="/privacy-policy" className="text-[#1772BF]">
                  privacy policy
                </a>
                .
              </label>
            </div> */}
            <div className="flex justify-end">
              <button
                type="submit"
                className="btn-gradient-2 button"
                style={{
                  borderWidth: "3px",
                  padding: "0.6rem 2rem",
                }}
                // onClick={diffToast}
              >
                Send Your Message
                <img src={button_icon1} alt="Button Icon" className="ml-2" />
              </button>
            </div>
            {/* <div className="shadow-2xl text-center p-4">
              {isSubmitted && (
              <p className="success-message text-green-500">Thank You</p>
              <button className="text-white bg-darkblue border rounded-xl py-2 px-4">
                Close
              </button>
              )}
            </div> */}
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ContactUsForm;
