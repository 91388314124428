import Item from "./Item";
import Details from "./Details";
import { QUICKLINKS, FEATURES, ABOUT } from "./Menus";
const ItemsContainer = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-[15vh] sm:px-8 px-5 py-16">
      <Details />
      <Item Links={QUICKLINKS} title="Quick Links" />
      <Item Links={FEATURES} title="Features" />
      <Item Links={ABOUT} title="About" />
    </div>
  );
};

export default ItemsContainer;
