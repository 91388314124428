import React from "react";
const ClientOurVision = ({ caseStudy }) => {
  return (
    <div className="my-16 sm:my-24 lg:my-32">
      {caseStudy?.cards?.map((vision, index) => (
        <div key={index} className="mb-8 mx-4 sm:mx-16 lg:mx-40">
          <div
            className={`flex flex-col md:flex-row items-center ${
              index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
            }`}
          >
            <div className="flex flex-col justify-center items-center mb-8 md:mb-0 lg:mb-0 lg:w-1/2 p-4 lg:p-8">
              <h1 className="text-xl lg:text-3xl text-[#000F40] font-semibold mb-4">
                {vision?.Heading}
              </h1>
              <img
                src={vision?.VisionImg}
                alt="Vision"
                className="h-[300px] lg:h-[450px] w-full lg:w-2/3 rounded-2xl" />
            </div>
            <div className="flex-1 lg:w-20 md:ml-8 lg:ml-8 p-4 lg:p-8">
              <ul>
                {vision?.Vision?.map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    className="border rounded-xl py-5 px-8 text-[10px] md:text-sm mb-4"
                    style={{ borderColor: vision.borderColor }}
                  >
                    <li className="max-w-full md:max-w-[700px]">{item}</li>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ClientOurVision;




// import React from "react";

// const ClientOurVision = ({ caseStudy }) => {
//   return (
//     <div className="my-32">
//       {caseStudy?.cards?.map((vision, index) => (
//         <div key={index} className="mb-8 mx-40">
//           <div className="flex justify-around ">
//             <div
//               style={{ order: index % 2 === 0 ? 1 : 2 }}
//               className="flex flex-col justify-center"
//             >
//               <div>
//                 <h1 className="text-xl text-[#000F40] font-semibold text-center">
//                   {vision?.Heading}
//                 </h1>
//               </div>
//               <img
//                 src={vision?.VisionImg}
//                 alt="VisionImg"
//                 className="h-[400px] w-full rounded-2xl"
//               />
//             </div>
//             <div style={{ order: index % 2 === 0 ? 2 : 1 }}>
//               <ul>
//                 {vision?.Vision?.map((item, index) => (
//                   <div
//                     key={index}
//                     className="border rounded-xl p-5 text-[18px] mb-4"
//                     style={{ borderColor: vision.borderColor }}
//                   >
//                     <li className="w-[600px]">{item}</li>
//                   </div>
//                 ))}
//               </ul>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ClientOurVision;
