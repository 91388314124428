import React from "react";
import { TiSocialLinkedin } from "react-icons/ti";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const SocialIcons = ({ Icons }) => {
  const iconComponents = {
    "logo-linkedin": <TiSocialLinkedin />,
    "logo-facebook": <FaFacebookF />,
    "logo-x": <FaXTwitter />,
  };

  const hoverColors = {
    "logo-linkedin": "hover:bg-[#0A66C2]",
    "logo-facebook": "hover:bg-[#1877F2]",
    "logo-x": "hover:bg-black",
  };

  return (
    <div className="text-white">
      {Icons.map((icon) => (
        <a
          key={icon.name}
          href={icon.link}
          className={`p-2 cursor-pointer inline-flex items-center rounded-full bg-gray-700 mx-1.5 text-xl hover:text-gray-100 duration-300 ${
            hoverColors[icon.name]
          }`}
        >
          {iconComponents[icon.name]}
        </a>
      ))}
    </div>
  );
};

export default SocialIcons;
