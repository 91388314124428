import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import backgorund_loop from "../assets/images/hero backgorund loop.png";
import Robot from "../assets/images/Robot.png";
import buttonIcon from "../assets/icons/button_icon.svg";
import buttonIcon1 from "../assets/icons/button_icon1.svg";
import circle from "../assets/images/circule.png";

const GrowthloopsDestination = () => {
  const [isSoftwareDevVisible, setIsSoftwareDevVisible] = useState(true);

  useEffect(() => {
    const loopAnimation = setInterval(() => {
      setIsSoftwareDevVisible((prev) => !prev);
    }, 2000);

    return () => clearInterval(loopAnimation);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.1,
        when: "beforeChildren",
        staggerChildren: 0.5,
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { type: "spring", stiffness: 50 } },
  };

  return (
    <div className="py-[7.5rem] md:py-36 lg:py-36 relative overflow-hidden">
      <div className="absolute top-80 md:top-36 md:left-8">
        <img
          src={backgorund_loop}
          alt="Background Loop"
          className="h-36 md:h-64 blur-2xl rotate-12"
        />
      </div>
      <div className="flex flex-col-reverse md:flex-row items-center mx-auto px-4 md:px-12 lg:px-14 space-y-8 md:space-y-0 md:space-x-16">
        <motion.div
          className="md:max-w-[50rem] text-center md:text-left mt-6"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.h1
            className="text-xl sm:text-4xl md:text-5xl lg:text-5xl text-[#000] font-semibold mb-6"
            variants={textVariants}
          >
            Growth Loops is your destination for{" "}
            <motion.span
              className="leading-tight md:leading-[4.5rem] lg:leading-[4.5rem] text-transparent bg-clip-text bg-gradient-to-r from-[#074787] to-[#9B59B6]"
              variants={textVariants}
            >
              {isSoftwareDevVisible ? "Software & AI Development" : "UI/UX design"}
            </motion.span>
          </motion.h1>
          <motion.p
            className="text-sm md:text-lg text-[#4E4E4E] mb-10"
            variants={textVariants}
          >
            Ready to deploy high performing and collaborative Software engineers that meets your immediate need.
          </motion.p>
          <motion.div
            className="flex flex-col md:flex-row flex-wrap gap-2 justify-center md:justify-start mt-4 md:mt-0"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.div variants={textVariants}>
              <NavLink
                to="/schedule"
                className="btn-gradient-2 button"
                style={{
                  borderWidth: "3px",
                  padding: "0.8rem 2rem",
                }}
                target="_blank"
              >
                Schedule
                <img src={buttonIcon1} alt="Button Icon" className="ml-2" />
              </NavLink>
            </motion.div>
            <motion.div variants={textVariants}>
              <Link
                activeClass="active"
                to="contactUsForm"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
              >
                <button
                  className="btn-gradient-1 z-10 button"
                  style={{
                    borderWidth: "2px",
                    padding: "0.8rem 2rem",
                  }}
                >
                  Contact Us
                  <img src={buttonIcon} alt="Button Icon" className="ml-2" />
                </button>
              </Link>
            </motion.div>
          </motion.div>
        </motion.div>
        <div className="relative order-2 md:order-2">
          <img
            src={circle}
            alt="Background"
            className="infinite-rotate sm:h-[60vh] md:h-[75vh] lg:h-[70vh]"
          />
          <div className="absolute top-[50%] left-[50%] md:top-[50%] md:left-[50%] lg:top-[50%] lg:left-[50%] transform -translate-x-1/2 -translate-y-1/2">
            <img
              src={Robot}
              alt="Robot"
              className="w-16 h-16 md:w-24 md:h-24 lg:w-24 lg:h-24 transition-transform duration-1000 ease-in-out"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrowthloopsDestination;




// import React, { useState, useEffect } from "react";
// import { NavLink } from "react-router-dom";
// import { Link } from "react-scroll";
// import { motion } from "framer-motion";
// import backgorund_loop from "../assets/images/hero backgorund loop.png";
// import Robot from "../assets/images/Robot.png";
// import buttonIcon from "../assets/icons/button_icon.svg";
// import buttonIcon1 from "../assets/icons/button_icon1.svg";
// import circle from "../assets/images/circule.png";

// const GrowthloopsDestination = () => {
//   const [showSoftwareDev, setShowSoftwareDev] = useState(true);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowSoftwareDev(!showSoftwareDev);
//     }, 3000);

//     return () => clearTimeout(timer);
//   }, [showSoftwareDev]);

//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src = "https://assets.calendly.com/assets/external/widget.js";
//     script.async = true;
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   const containerVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: {
//         delay: 0.1,
//         when: "beforeChildren",
//         staggerChildren: 0.5,
//       },
//     },
//   };

//   const leftToRightVariants = {
//     hidden: { opacity: 0, x: "-50vw" },
//     visible: { opacity: 1, x: 0, transition: { type: "spring", stiffness: 50 } },
//   };

//   const topToBottomVariants = {
//     hidden: { opacity: 0, y: "-5vw" },
//     visible: { opacity: 1, y: 0, transition: { type: "spring", stiffness: 50 } },
//   };

//   return (
//     <div className="py-[8.5rem] md:py-36 lg:py-36 relative overflow-hidden">
//       <div className="absolute top-[30rem] md:top-[18rem] md:left-[2rem]">
//         <img
//           src={backgorund_loop}
//           alt="Background Loop"
//           className="h-[150px] md:h-[260px] blur-[9px] rotate-12"
//         />
//       </div>
//       <div className="flex flex-col-reverse md:flex-row items-center mx-auto md:mx-12 lg:mx-14 space-x-0 md:space-x-16">
//         <motion.div
//           className="md:max-w-[50rem] text-center md:text-left mt-6"
//           variants={containerVariants}
//           initial="hidden"
//           animate="visible"
//         >
//           <motion.h1
//             className="text-xl sm:text-4xl md:text-5xl lg:text-5xl text-[#000] font-semibold mb-6"
//             variants={leftToRightVariants}
//           >
//             Growth Loops is your destination for{" "}
//             <span className="md:leading-[4.5rem] lg:leading-[4.5rem] text-transparent bg-clip-text bg-gradient-to-r from-[#074787] to-[#9B59B6]">
//               {showSoftwareDev ? "Software & AI Development" : "UI/UX design"}
//             </span>
//           </motion.h1>
//           <motion.p
//             className="text-[10px] md:text-[18px] text-[#4E4E4E] mb-10"
//             variants={leftToRightVariants}
//           >
//             Ready to deploy high performing and collaborative Software engineers that meets your immediate need.
//           </motion.p>
//           <motion.div
//             className="flex flex-col md:flex-row flex-wrap gap-2 md:justify-start mt-4 md:mt-0 ml-2"
//             variants={containerVariants}
//             initial="hidden"
//             animate="visible"
//           >
//             <motion.div variants={topToBottomVariants}>
//               <NavLink
//                 to="/schedule"
//                 className="btn-gradient-2 button"
//                 style={{
//                   borderWidth: "3px",
//                   padding: "0.8rem 2rem",
//                 }}
//                 target="_blank"
//               >
//                 Schedule
//                 <img src={buttonIcon1} alt="Button Icon" className="ml-2" />
//               </NavLink>
//             </motion.div>
//             <motion.div variants={topToBottomVariants}>
//               <Link
//                 activeClass="active"
//                 to="contactUsForm"
//                 spy={true}
//                 smooth={true}
//                 offset={-70}
//                 duration={1000}
//               >
//                 <button
//                   className="btn-gradient-1 z-10 button"
//                   style={{
//                     borderWidth: "2px",
//                     padding: "0.8rem 2rem",
//                   }}
//                 >
//                   Contact Us
//                   <img src={buttonIcon} alt="Button Icon" className="ml-2" />
//                 </button>
//               </Link>
//             </motion.div>
//           </motion.div>
//         </motion.div>
//         <div className="relative order-2 md:order-2">
//           <img
//             src={circle}
//             alt="Background"
//             className="infinite-rotate sm:h-[60vh] md:h-[75vh] lg:h-[70vh]"
//           />
//           <div className="absolute top-[40%] left-[40%] md:top-[41%] md:left-[43%] lg:top-[40%] lg:left-[40%]">
//             <img
//               src={Robot}
//               alt="Robot"
//               className="w-[4rem] h-[4rem] md:w-[6rem] md:h-[6rem] lg:w-[6rem] lg:h-[6rem] transition-transform duration-1000 ease-in-out"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GrowthloopsDestination;





// import React, { useState, useEffect } from "react";
// import { NavLink } from "react-router-dom";
// import { Link } from "react-scroll";
// import backgorund_loop from "../assets/images/hero backgorund loop.png";
// import Robot from "../assets/images/Robot.png";
// import buttonIcon from "../assets/icons/button_icon.svg";
// import buttonIcon1 from "../assets/icons/button_icon1.svg";
// import circle from "../assets/images/circule.png";
// import 'animate.css';

// const GrowthloopsDestination = () => {
//   const [showSoftwareDev, setShowSoftwareDev] = useState(true);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowSoftwareDev(!showSoftwareDev);
//     }, 3000);

//     return () => clearTimeout(timer);
//   }, [showSoftwareDev]);

//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src = "https://assets.calendly.com/assets/external/widget.js";
//     script.async = true;
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return (
//     <div className="py-[8.5rem] md:py-36 lg:py-36 relative overflow-hidden">
//       <div className="absolute top-[30rem] md:top-[18rem] md:left-[2rem]">
//         <img
//           src={backgorund_loop}
//           alt="Background Loop"
//           className="h-[150px] md:h-[280px] blur-[9px] rotate-12"
//         />
//       </div>
//       <div className="flex flex-col-reverse md:flex-row items-center mx-auto md:mx-12 lg:mx-14 space-x-0 md:space-x-16">
//         <div className="md:max-w-[50rem] text-center md:text-left mt-6 animate__animated animate__fadeInLeft">
//           <h1 className="text-xl sm:text-4xl md:text-5xl lg:text-[48px] text-[#000] font-semibold mb-6">
//             Growth Loops is your destination for{" "}
//             <span className="md:leading-[4.5rem] lg:leading-[4.5rem] text-transparent bg-clip-text bg-gradient-to-r from-[#074787] to-[#9B59B6]">
//               {showSoftwareDev ? "Software Development" : "AI & LLM Solutions"}
//             </span>
//           </h1>
//           <p className="text-[10px] md:text-[1rem] text-[#636E92] mb-10">
//             Innovate, Elevate, Dominate: Unleashing potential success with
//             new-aged AI tools powered by LLM technology like OpenAI.
//           </p>
//           <div className="flex flex-col md:flex-row flex-wrap gap-2 md:justify-start mt-4 md:mt-0 ml-2">
//             <NavLink
//               to="/schedule"
//               className="btn-gradient-2 button"
//               style={{
//                 borderWidth: "3px",
//                 padding: "0.8rem 2rem",
//               }}
//               target="_blank"
//             >
//               Schedule
//               <img src={buttonIcon1} alt="Button Icon" className="ml-2" />
//             </NavLink>
//             <Link
//               activeClass="active"
//               to="contactUsForm"
//               spy={true}
//               smooth={true}
//               offset={-70}
//               duration={1000}
//             >
//               <button
//                 className="btn-gradient-1 z-10 button"
//                 style={{
//                   borderWidth: "2px",
//                   padding: "0.8rem 2rem",
//                 }}
//               >
//                 Contact Us
//                 <img src={buttonIcon} alt="Button Icon" className="ml-2" />
//               </button>
//             </Link>
//           </div>
//         </div>
//         <div className="relative order-2 md:order-2">
//           <img
//             src={circle}
//             alt="Background"
//             className="infinite-rotate sm:h-[60vh] md:h-[75vh] lg:h-[70vh]"
//           />
//           <div className="absolute top-[40%] left-[40%] md:top-[41%] md:left-[43%] lg:top-[40%] lg:left-[40%]">
//             <img
//               src={Robot}
//               alt="Robot"
//               className="w-[4rem] h-[4rem] md:w-[6rem] md:h-[6rem] lg:w-[6rem] lg:h-[6rem] transition-transform duration-1000 ease-in-out"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GrowthloopsDestination;



// import React, { useState, useEffect } from "react";
// import backgorund_loop from "../assets/images/hero backgorund loop.png";
// import Robot from "../assets/images/Robot.png";
// // import Robot_bg from "../assets/images/Robot_bg.png";
// import button_icon from "../assets/icons/button_icon.svg";
// import button_icon1 from "../assets/icons/button_icon1.svg";
// import circle from "../assets/images/circule.png";
// import { NavLink } from "react-router-dom";
// import Schedule from "../pages/Schedule";
// import {
//   Link,
//   Button,
//   Element,
//   Events,
//   animateScroll as scroll,
//   scrollSpy,
// } from "react-scroll";
// const GrowthloopsDestination = () => {
//   const [showSoftwareDev, setShowSoftwareDev] = useState(true);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowSoftwareDev(!showSoftwareDev);
//     }, 3000);

//     // Clear the timer when the component unmounts
//     return () => clearTimeout(timer);
//   }, [showSoftwareDev]);

//   // Initialize Calendly badge widget
//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src = "https://assets.calendly.com/assets/external/widget.js";
//     script.async = true;
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return (
//     <>
//       <div className="py-[8.5rem] md:py-36 lg:py-36 relative overflow-hidden">
//         <div className="absolute top-[30rem] md:top-[18rem] md:left-[2rem]">
//           <img
//             src={backgorund_loop}
//             alt="backgorund_loop"
//             className="h-[150px] md:h-[280px] blur-[9px] rotate-12"
//           />
//         </div>
//         <div className="flex flex-col-reverse md:flex-row items-center mx-auto md:mx-12 lg:mx-14 space-x-0 md:space-x-16">
//           <div className="md:max-w-[50rem] text-center md:text-left mt-6">
//             <h1 className="text-xl sm:text-4xl md:text-5xl lg:text-[48px] text-[#000] font-semibold mb-6 ">
//               Growth Loops is your destination for{" "}
//               <span className="md:leading-[4.5rem] lg:leading-[4.5rem] text-transparent bg-clip-text bg-gradient-to-r from-[#074787] to-[#9B59B6]">
//                 {showSoftwareDev
//                   ? "Software Development"
//                   : "AI & LLM Solutions"}
//               </span>
//             </h1>
//             <p className="text-[10px] md:text-[1rem] text-[#636E92] mb-10">
//               Innovate, Elevate, Dominate : Unleashing potential success with
//               new aged AI <br /> tools powered by LLM technology like OpenAI.
//             </p>
//             <div className="flex flex-col md:flex-row flex-wrap gap-2 md:justify-start mt-4 md:mt-0 ml-2">
//               <NavLink
//                 to="/schedule"
//                 className="btn-gradient-2 button"
//                 style={{
//                   borderWidth: "3px",
//                   padding: "0.8rem 2rem",
//                   // minWidth: "10rem",
//                 }}
//                 target="_blank"
//               >
//                 Schedule
//                 <img src={button_icon1} alt="Button Icon" className="ml-2" />
//               </NavLink>
//               <Link
//                 activeClass="active"
//                 to="contactUsForm"
//                 spy={true}
//                 smooth={true}
//                 offset={-70}
//                 duration={1000}
//               >
//                 <button
//                   className="btn-gradient-1 z-10 button"
//                   style={{
//                     borderWidth: "2px",
//                     padding: "0.8rem 2rem",
//                   }}
//                 >
//                   Contact Us
//                   <img src={button_icon} alt="Button Icon" className="ml-2" />
//                 </button>
//               </Link>
//             </div>
//           </div>
//           <div className="relative order-2 md:order-2">
//             <img
//               src={circle}
//               alt="Background"
//               className="infinite-rotate sm:h-[60vh] md:h-[75vh] lg:h-[70vh]"
//             />

//             {/* <div className="absolute top-[50%] left-[50%] transform-gpu -translate-x-1/2 -translate-y-1/2"> */}
//             <div className="absolute top-[40%] left-[40%] md:top-[41%] md:left-[43%] lg:top-[40%] lg:left-[40%]">
//               <img
//                 src={Robot}
//                 alt="Robot"
//                 className="w-[4rem] h-[4rem] md:w-[6rem] md:h-[6rem] lg:w-[6rem] lg:h-[6rem] transition-transform duration-1000 ease-in-out"
//                 // className="w-[6rem] h-[6rem] transition-transform duration-1000 ease-in-out"

//                 // style={{
//                 //   transform: showSoftwareDev
//                 //     ? "translateY(-10px)"
//                 //     : "translateY(5px)",
//                 // }}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default GrowthloopsDestination;
