import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aniruddha from "../assets/images/AboutUs/team_member/Aniruddha.png";
import Risita from "../assets/images/AboutUs/team_member/Rishita.png";
import Shubham from "../assets/images/AboutUs/team_member/Shubham.png";
import Aman from "../assets/images/AboutUs/team_member/Aman.png";
import Govind from "../assets/images/AboutUs/team_member/Govind.png";
import Stuti from "../assets/images/AboutUs/team_member/Stuti.png";
import Satya from "../assets/images/AboutUs/team_member/Satyajeet.png";
import Hibjul from "../assets/images/AboutUs/team_member/Hibjul.png";
import Rajat from "../assets/images/AboutUs/team_member/Rajat.png";
import Vishal from "../assets/images/AboutUs/team_member/Vishal.png";
import Shivam from "../assets/images/AboutUs/team_member/Shivam.png";
import majee1 from "../assets/images/AboutUs/team_member/Ankana.png";
import Swaroop from "../assets/images/AboutUs/team_member/Swaroop.png";
import Arannyak from "../assets/images/AboutUs/team_member/Aranyak.png";
import Adarsh from "../assets/images/AboutUs/team_member/Adarsh.png";
import Rohit from "../assets/images/AboutUs/team_member/Rohit Ghosh.png";
import Balaram from "../assets/images/AboutUs/team_member/Balaram.png";
import Ayush from "../assets/images/AboutUs/team_member/Ayush.png";
import Debangshi from "../assets/images/AboutUs/team_member/Debangshi.png";
import Santosh from "../assets/images/AboutUs/team_member/Santosh.png";
import Nitesh from "../assets/images/AboutUs/team_member/Nitesh.png";
import Devnik from "../assets/images/AboutUs/team_member/Devnik.png";

import PreviousNextMethods from "../components/PreviousNextMethods";

function SampleArrow(props) {
  const { style } = props;
  return (
    <div
      style={{
        ...style,
        display: "none",
      }}
    />
  );
}

// Define your Slide component
const Slide = ({ testimonial }) => {
  return (
    <div className="md:mx-[3rem] mx-0 relative w-[150px] md:w-[250px] h-[200px] md:h-[300px]">
      <img
        src={testimonial.image}
        className="w-full h-full object-cover rounded-xl"
        alt={testimonial.author}
      />
      <div className="absolute bottom-0 w-full bg-black bg-opacity-50 p-3 rounded-b-xl">
        <div className="text-start">
          <h3 className="text-white text-[12px] md:text-[16px] font-bold">
            {testimonial.author}
          </h3>
          <p className="text-white text-[10px] md:text-[12px]">
            {testimonial.content}
          </p>
        </div>
      </div>
    </div>
  );
};

const TeamMembersName = () => {
  const sliderRef = useRef(null);
  const testimonials = [
    {
      id: 1,
      image: Aniruddha,
      author: "Aniruddha Paul",
      content: "Project Manager",
    },
    {
      id: 2,
      image: Risita,
      author: "Risita Singh",
      content: "HR Manager",
    },
    {
      id: 3,
      image: Shubham,
      author: "Shubham Kumar",
      content: "Tech Lead",
    },
    {
      id: 4,
      image: Aman,
      author: "Aman Kahar",
      content: "UI/UX Designer",
    },
    {
      id: 5,
      image: Govind,
      author: "Govind Binjhani",
      content: "Backend Developer",
    },
    {
      id: 6,
      image: Stuti,
      author: "Stuti Mohta",
      content: "Frontend Developer",
    },
    {
      id: 7,
      image: Satya,
      author: "Satyajit Jena",
      content: "Frontend Developer",
    },
    {
      id: 8,
      image: Hibjul,
      author: "Hibjul Ahmed",
      content: "Frontend Developer",
    },
    {
      id: 9,
      image: Rajat,
      author: "Rajat Behera",
      content: "Frontend Developer",
    }, 
    {
      id: 10,
      image: Vishal,
      author: "Vishal Kumar Sharma",
      content: "Frontend Developer",
    },
    {
      id: 11,
      image: Shivam,
      author: "Shivam Kumar",
      content: "Team Lead",
    },
    {
      id: 12,
      image: majee1,
      author: "Ankana Majee",
      content: "Backend Developer",
    },
    {
      id: 13,
      image: Swaroop,
      author: "Swaroop panchangam",
      content: "Backend Developer",
    },
    {
      id: 14,
      image: Arannyak,
      author: "Arannyak Roy",
      content: "Frontend Developer",
    }, 
    {
      id: 15,
      image: Adarsh,
      author: "Adarsh Pandey",
      content: "AI/ML developer",
    },
    {
      id: 16,
      image: Rohit,
      author: "Rohit Ghosh",
      content: "Frontend Developer",
    },
    {
      id: 17,
      image: Balaram,
      author: "Balaram Bhakat",
      content: "UI/UX Designer",
    },   
    {
      id: 18,
      image: Ayush,
      author: "Ayush Agarwal",
      content: "Project Manager",
    },
    {
      id: 19,
      image: Debangshi,
      author: "Debangshi Mandal",
      content: "Backend Developer",
    },
    {
      id: 20,
      image: Santosh,
      author: "Santhosh Balaji",
      content: "Software Test Engineer",
    },
    {
      id: 21,
      image: Nitesh,
      author: "Nitesh Chowdhury",
      content: "Frontend Developer",
    },
    
    {
      id: 22,
      image: Devnik,
      author: "Debanik Deb",
      content: "AI Engineer",
    },
  ];

  const slickSettings = {
    infinite: true,
    nextArrow: <SampleArrow />,
    prevArrow: <SampleArrow />,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="mt-8 bg-blue-100 p-4">
      <div className="mx-0 md:mx-14 py-6 md:py-10">
        <h3 className=" text-[#4E4E4E] font-semibold text-[16px] md:text-2xl">Our Team</h3>
        <h1 className=" text-[#000] font-semibold text-xl md:text-3xl">
          Meet our awesome <span className="text-[#002298]">Builders</span>
        </h1>
      </div>
      <div>
        <Slider {...slickSettings} ref={sliderRef}>
          {testimonials.map((testimonial) => (
            <Slide key={testimonial.id} testimonial={testimonial} />
          ))}
        </Slider>
      </div>
      <PreviousNextMethods sliderRef={sliderRef} />
    </div>
  );
};

export default TeamMembersName;
