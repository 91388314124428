import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import client1 from "../assets/images/CaseStudies/imongu/client.png";
import client from "../assets/images/CaseStudies/HappyChat/client.png";
import client_CM from "../assets/images/CaseStudies/CM/client.png";
import client_JABA from "../assets/images/CaseStudies/JABA/client.png";
import semi from "../assets/images/semi_col.png";
import background_loop from "../assets/images/hero backgorund loop.png"; 
import LeftArrow from "../assets/icons/AboutUsIcons/LeftArrow.svg";
import RightArrow from "../assets/icons/AboutUsIcons/RightArrow.svg";

function SampleArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={style}
      onClick={onClick}
    />
  );
}

const LeftArrowComponent = (props) => (
  <img
    src={LeftArrow}
    alt="Left Arrow"
    className="slick-arrow slick-prev"
    style={{ left: "-30px", zIndex: 1, width: "30px", height: "30px" }}
    onClick={props.onClick}
  />
);

const RightArrowComponent = (props) => (
  <img
    src={RightArrow}
    alt="Right Arrow"
    className="slick-arrow slick-next"
    style={{ right: "-30px", zIndex: 1, width: "30px", height: "30px" }}
    onClick={props.onClick}
  />
);

const Testimony = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);
  const testimonials = [
    {
      id: 1,
      clientImg: client_CM,
      clientname: "Michael Piedra",
      place: "CEO, Creative Matters",
      content: "The team was patient and knowledgeable, and their work exceeded the client’s expectations...",
      message: "“Growth Loops Technology Pvt Ltd delivered a functional application and fixed bugs diligently. The team was patient and knowledgeable, and their work exceeded the client’s expectations. The team delivered work ahead of schedule and met the project’s milestones, being responsive to the client.”",
      date: "September 3, 2023",
      rating: 5,
    },
    {
      id: 2,
      clientImg: client_JABA,
      clientname: "Jordon Rooney",
      place: "Founder, Built Different LLC",
      content:
        "They work super hard and pay attention to details....",
      message:
        "“We started working with Growth Loops Technology from over an year ago and it has been one of the best relationship we ever had and they have become major part of our team, they pay attention to details and work super hard,I could not recommend them more.”",
      date: "Mar 15, 2024",
      rating: 5
    },
    {
      id: 3,
      clientImg: client,
      clientname: "JC Acevedo",
      place: "CEO, HappyChat AI",
      content: "Growth Loops Technology Pvt Ltd managed the project well...",
      message: "“The delivered chatbot and web app align with the client's expectations, enabling them to launch a functional MVP successfully. Growth Loops Technology Pvt Ltd managed the project well — they addressed queries and concerns promptly and always adhered to deadlines. They were also open to feedback.”",
      date: "August 15, 2023",
      rating: 5,
    },
    {
      id: 4,
      clientImg: client1,
      clientname: "Agness Mnena (Allagh)",
      place: "CEO, iMongu",
      content: "The team took ownership of the client’s product and delivered work on time...",
      message: "“Growth Loops Technology Pvt Ltd work exceeded the client’s expectations. The team was warm and involved in the project. The team took ownership of the client’s product and delivered work on time, having direct communication with the client via email and virtual meetings.”",
      date: "Jan 25, 2024",
      rating: 5,
    }    
  ];

  const renderStars = (rating) => (
    [...Array(rating)].map((_, i) => <span key={i} className="text-yellow-500">★</span>)
  );

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <RightArrowComponent />,
    prevArrow: <LeftArrowComponent />,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => setCurrentSlide(next)
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.innerSlider.list.style.padding = "0px";
    }
  }, []);

  return (
    <div className="bg-gray-100 py-10 my-16">
      <div className="text-start mb-10 px-6 md:px-20 relative">
        <h2 className="text-[#002298] md:text-2xl text-[16px]">Testimonials</h2>
        <h1 className="text-xl md:text-3xl lg:text-3xl font-semibold">What people say about us</h1>
      </div>
      <div className="px-6 md:px-28">
        <Slider ref={sliderRef} {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={testimonial.id} className="relative bg-[#FFF] py-8 md:py-20 px-6 md:px-16 my-8 rounded-lg shadow-lg flex flex-col md:flex-row items-start gap-4 w-full max-w-7xl mx-auto">
              <img src={semi} alt="Quotation Mark" className="absolute -top-5 right-10 md:right-28 w-14 h-10" />
              <div className="absolute top-[15rem] md:top-8 md:left-1/3">
                <img src={background_loop} alt="background loop" className="h-[150px] md:h-[240px] blur-[15px] rotate-3" />
              </div>
              <div className={`flex flex-col md:flex-row items-start w-full gap-4 md:gap-16 ${currentSlide === index ? 'fade-down' : ''}`}>
                <div className="flex items-center w-full md:w-auto">
                  {testimonial.clientImg && (
                    <img src={testimonial.clientImg} alt={`${testimonial.clientname}'s image`} className="w-20 h-20 border-4 p-0.5 bg-blue-200 rounded-full" />
                  )}
                  <div className="ml-0 md:ml-4 mt-4 md:mt-0">
                    <h3 className="text-[18px] font-semibold text-[#000F40]">{testimonial.clientname}</h3>
                    <p className="text-[#4E4E4E] text-[14px]">{testimonial.place}</p>
                  </div>
                </div>
                <div className="flex-grow mt-4 md:mt-0 w-full md:w-2/3">
                  <h4 className="text-xl text-[#000F40] font-semibold">{testimonial.content}</h4>
                  {testimonial.rating && (
                    <div className="flex items-center mt-2">
                      {renderStars(testimonial.rating)}
                      <span className="text-gray-500 ml-2">{testimonial.date}</span>
                    </div>
                  )}
                  <p className="mt-4 text-[#4E4E4E]">{testimonial.message}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimony;








// import React, { useState } from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import semi from "../assets/images/semi_col.png";
// import background_loop from "../assets/images/hero backgorund loop.png";
// import pic from "../assets/images/pic.png";
// import 'animate.css';

// const Testimony = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);

//   const testimonials = [
//     {
//       id: 1,
//       author: "Jack",
//       content: "They have incredibly talented developers who dedicated time and effort to creating my chatbot page...",
//       message:
//       "I had the pleasure of working with Growth Loops Technology team. They have incredibly talented developers who dedicated time and effort to creating my chatbot page. From start to finish, their exceptional problem-solving skills and attention to detail were evident, as they have worked tirelessly to fix every bug and ensure a seamless user experience. They collaborated closely with me, understanding my vision, and actively contributing to the design and development process. Their technical expertise was outstanding, as they utilized the latest frameworks and tools to create a powerful and user-friendly chatbot page that exceeded my expectations. Throughout the project, their professionalism, work ethic, and commitment to excellence were evident, making it a pleasure to work with them. I wholeheartedly recommend this team for their hard work, dedication, and ability to transform ideas into polished and functional solutions.",
//     },
//     {
//       id: 2,
//       author: "Alejandro Rey",
//       content: "They consistently delivered high-quality work and were always open to feedback...",
//       message: "I recently had the pleasure of working with Growth Loops Technology on a complex project, and I couldn't be happier with the results. From the start, they demonstrated excellent communication skills, ensuring that they fully understood my requirements and expectations. Throughout the project, they consistently delivered high-quality work and were always open to feedback. They were proactive in suggesting creative solutions to challenges that arose, which ultimately led to a more polished final product.",
//     },
//     {
//       id: 3,
//       author: "Kevin",
//       content: "Thank You!...",
//       message: "Growth Loops Technology showed skills and expertise and was willing to answer all of our questions as well as make reasonable changes when we asked. Thank you!",
//     },
//     {
//       id: 4,
//       author: "Kevin",
//       content: "Thank You...",
//       message: "Growth Loops Technology completed the job on time with skill and expertise. Thank you for working with us!",
//     },
//     {
//       id: 5,
//       author: "Michael Piedra",
//       content: "They were extremely patient and remained professional throughout the entire process...",
//       message: "Growth Loops Technology team were amazing to work with. They took a complicated idea and turned it into a functioning web application. They were extremely patient and remained professional throughout the entire process. They worked diligently to fix any all bugs and to ensure that I was completely satisfied with the product. I look forward to working with them again in the near future.",
//     },
//   ];

//   const renderStars = (rating) => {
//     const stars = [];
//     for (let i = 0; i < rating; i++) {
//       stars.push(<span key={i} className="text-yellow-500">★</span>);
//     }
//     return stars;
//   };

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 600,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     arrows: true,
//     beforeChange: (current, next) => setCurrentSlide(next),
//   };

//   return (
//     <div className="bg-gray-100 py-16">
//       <div className="text-start mb-10 px-6 md:px-20">
//         <h2 className="text-[#002298] text-lg font-semibold">Testimonials</h2>
//         <h1 className="text-xl md:text-3xl lg:text-3xl font-semibold">What people say about us</h1>
//       </div>
//       <div className="px-6 md:px-28">
//         <Slider {...settings}>
//           {testimonials.map((testimonial, index) => (
//             <div key={testimonial.id} className="relative bg-white py-8 md:py-12 px-6 md:px-16 my-8 rounded-lg shadow-lg flex flex-col gap-4 w-full max-w-7xl">
//               <img src={semi} alt="Quotation Mark" className="absolute -top-5 right-28 w-14 h-10" />
//               <div className="absolute top-[15rem] md:top-8 md:left-1/3">
//                 <img src={background_loop} alt="Background Loop" className="h-[150px] md:h-[240px] blur-[15px] rotate-3" />
//               </div>
//               <div className={`flex flex-col w-full ${currentSlide === index ? 'fade-down' : ''}`}>
//                 <div className="flex-grow mt-4 md:mt-0">
//                   <h4 className="text-xl text-[#000F40] font-semibold">{testimonial.content}</h4>
//                   <div className="flex items-center mt-1">
//                     {renderStars(testimonial.rating || 5)} 
//                   </div>
//                   <p className="mt-4 text-[#4E4E4E]">{testimonial.message}</p>
//                 </div>
//                 <div className="flex items-center md:w-1/3 mt-6">
//                   <img src={pic} className="w-20 h-20 rounded-full" alt="Profile Pic" />
//                   <div className="ml-4">
//                     <h3 className="text-[18px] font-semibold text-[#000F40]">{testimonial.author}</h3>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// };

// export default Testimony;




// import React, { useEffect, useRef } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import removebg_preview from "../assets/images/image-removebg-preview.png";
// import pic from "../assets/images/pic.png";
// import Star from "../assets/images/5 star.png";
// import Card_bg from "../assets/images/Card_bg.png";
// import PreviousNextMethods from "./PreviousNextMethods";
// import text_img from "../assets/images/text_img.svg";

// function SampleArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         display: "none",
//       }}
//       onClick={onClick}
//     />
//   );
// }

// function Slide({ testimonial }) {
//   return (
//     <div className="px-4 relative">
//       <div className=" relative -left-20 transform -translate-x-1/2 top-12">
//         <img src={pic} className="w-28 h-28 rounded-full" alt="Profile Pic" />
//       </div>
//       <div className="border border-[#B9B9B9] shadow-lg bg-[#FFFFFF] rounded-2xl p-8 h-[47vh] overflow-y-scroll no-scrollbar">
//         <div className="flex flex-col mt-6 justify-between">
//           <div className="text-center">
//             <h3 className="text-base lg:text-lg text-[#000F40] font-semibold">
//               {testimonial.author}
//             </h3>
//             <img src={Star} alt="Star" className="h-4 mx-auto" />
//           </div>
//           <p className="text-xs md:text-[15px] lg:text-[15px] text-[#4E4E4E] mt-4 text-start leading-7">
//             {testimonial.content}
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// }

// const Testimony = () => {
//   const testimonials = [
//     {
//       id: 1,
//       author: "Jack",
//       content:
//         "I had the pleasure of working with Growth Loops Technology team.They have incredibly talented developers who dedicated time and effort to creating my chatbot page. From start to finish, their exceptional problem-solving skills and attention to detail were evident, as they have worked tirelessly to fix every bug and ensure a seamless user experience. They collaborated closely with me, understanding my vision, and actively contributing to the design and development process. Their technical expertise was outstanding, as they utilized the latest frameworks and tools to create a powerful and user-friendly chatbot page that exceeded my expectations. Throughout the project, their professionalism, work ethic, and commitment to excellence were evident, making it a pleasure to work with them. I wholeheartedly recommend this team for their hard work, dedication, and ability to transform ideas into polished and functional solutions",
//     },
//     {
//       id: 2,
//       author: "Alejandro Rey",
//       content:
//         "I recently had the pleasure of working with Growth Loops Technology on a complex project, and I couldn't be happier with the results. From the start, they demonstrated excellent communication skills, ensuring that they fully understood my requirements and expectations. Throughout the project, they consistently delivered high-quality work and were always open to feedback. They were proactive in suggesting creative solutions to challenges that arose, which ultimately led to a more polished final product.",
//     },
//     {
//       id: 3,
//       author: "Kevin",
//       content:
//         "Growth Loops Technology showed skills and expertise and was willing to answer all of our questions as well as make reasonable changes when we asked. Thank you!",
//     },
//     {
//       id: 4,
//       author: "Kevin",
//       content:
//         "Growth Loops Technology completed the job on time with skill and expertise. Thank you for working with us!",
//     },
//     {
//       id: 5,
//       author: "Michael Piedra",
//       content:
//         "Growth Loops Technology team were amazing to work with. They took a complicated idea and turned it into a functioning web application. They were extremely patient and remained professional throughout the entire process. They worked diligently to fix any all bugs and to ensure that I was completely satisfied with the product. I look forward to working with them again in the near future.",
//     },
//   ];

//   const Settings = {
//     infinite: true,
//     nextArrow: <SampleArrow />,
//     prevArrow: <SampleArrow />,
//     speed: 500,
//     autoplay: true,
//     slidesToShow: 2,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   const sliderRef = useRef(null);

//   useEffect(() => {
//     const slider = sliderRef.current;
//     if (slider) {
//       slider.innerSlider.list.style.padding = "0px";
//     }
//   }, []);

//   return (
//     <div className="my-8 md:my-[10rem]">
//       <div className="bg-gradient-to-r from-blue-50 via-blue-50 to-blue-50 px-7 md:px-16 my-[8rem] h-[70vh]">
//         <div className="text-start px-6 py-8 ">
//           <h3 className="text-[#002298] text-lg md:text-2xl lg:text-2xl font-medium">
//             Testimonials
//           </h3>
//           <div className="flex">
//             <h1 className="text-xl md:text-3xl lg:text-3xl font-semibold">
//               What people say about us
//             </h1>
//             <img src={text_img} alt="Button Icon" className="h-6" />
//           </div>
//         </div>
//         <div className="flex justify-center relative">
//           <div className="w-full">
//             <Slider {...Settings} ref={sliderRef} className="">
//               {testimonials.map((testimonial) => (
//                 <Slide key={testimonial.id} testimonial={testimonial} />
//               ))}
//             </Slider>
//           </div>
//         </div>
//       </div>
//       <PreviousNextMethods sliderRef={sliderRef} />
//     </div>
//   );
// };

// export default Testimony;






// import React, { useEffect, useRef } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import removebg_preview from "../assets/images/image-removebg-preview.png";
// import pic from "../assets/images/pic.png";
// import Star from "../assets/images/5 star.png";
// import Card_bg from "../assets/images/Card_bg.png";
// import PreviousNextMethods from "./PreviousNextMethods";
// import text_img from "../assets/images/text_img.svg";
// function SampleArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         display: "none",
//       }}
//       onClick={onClick}
//     />
//   );
// }
// const Slide = ({ testimonial }) => {
//   const containerRef = useRef(null);

//   useEffect(() => {
//     const container = containerRef.current;
//     if (container.scrollHeight <= container.clientHeight) {
//       container.classList.add("hide-scrollbar");
//     } else {
//       container.classList.remove("hide-scrollbar");
//     }
//   }, []);
//   return (
//     <div className="mx-2 sm:mx-5 mt-32 bg-slate-50 rounded-tr-[4rem]">
//       <div
//         key={testimonial.id}
//         ref={containerRef}
//         className="h-[52vh] overflow-y-scroll no-scrollbar rounded-tl-lg rounded-tr-[4rem] border-b-0 border border-blue-500 p-4 relative focus:outline-none"
//       >
//         <img
//           src={Card_bg}
//           className="absolute inset-0 "
//           alt="Card Background"
//         />
//         <div>
//           <div className="flex items-center mb-2">
//             <img
//               src={pic}
//               className="w-12 h-12 sm:w-20 sm:h-20 mr-2 z-10"
//               alt="Ellipse"
//             />
//             <div>
//               <h3 className="text-base lg:text-lg font-semibold">
//                 {testimonial.author}
//               </h3>
//               <img src={Star} alt="Star" className="h-4" />
//             </div>
//           </div>
//           <p className="text-xs md:text-sm lg:text-sm text-[#595F66]">
//             {testimonial.content}
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };
// const Testimony = () => {
//   const testimonials = [
//     {
//       id: 1,
//       author: "Jack",
//       content:
//         "I had the pleasure of working with Growth Loops Technology team.They have incredibly talented developers who dedicated time and effort to creating my chatbot page. From start to finish, their exceptional problem-solving skills and attention to detail were evident, as they have worked tirelessly to fix every bug and ensure a seamless user experience. They collaborated closely with me, understanding my vision, and actively contributing to the design and development process. Their technical expertise was outstanding, as they utilized the latest frameworks and tools to create a powerful and user-friendly chatbot page that exceeded my expectations. Throughout the project, their professionalism, work ethic, and commitment to excellence were evident, making it a pleasure to work with them. I wholeheartedly recommend this team for their hard work, dedication, and ability to transform ideas into polished and functional solutions",
//     },
//     {
//       id: 2,
//       author: "Alejandro Rey",
//       content:
//         "I recently had the pleasure of working with Growth Loops Technology on a complex project, and I couldn't be happier with the results. From the start, they demonstrated excellent communication skills, ensuring that they fully understood my requirements and expectations. Throughout the project, they consistently delivered high-quality work and were always open to feedback. They were proactive in suggesting creative solutions to challenges that arose, which ultimately led to a more polished final product.",
//     },
//     {
//       id: 3,
//       author: "Kevin",
//       content:
//         "Growth Loops Technology showed skills and expertise and was willing to answer all of our questions as well as make reasonable changes when we asked. Thank you!",
//     },
//     {
//       id: 4,
//       author: "Kevin",
//       content:
//         "Growth Loops Technology completed the job on time with skill and expertise. Thank you for working with us!",
//     },
//     {
//       id: 5,
//       author: "Michael Piedra",
//       content:
//         "Growth Loops Technology team were amazing to work with. They took a complicated idea and turned it into a functioning web application. They were extremely patient and remained professional throughout the entire process. They worked diligently to fix any all bugs and to ensure that I was completely satisfied with the product. I look forward to working with them again in the near future.",
//     },
//   ];
//   const slickSettings = {
//     centerMode: true,
//     centerPadding: "10px",
//     infinite: true,
//     nextArrow: <SampleArrow />,
//     prevArrow: <SampleArrow />,
//     speed: 500,
//     autoplay: true,
//     slidesToShow: 3,
//     slidesToShow: 2,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 768,
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   const sliderRef = useRef(null);

//   useEffect(() => {
//     const slider = sliderRef.current;
//     if (slider) {
//       slider.innerSlider.list.style.padding = "0px";
//     }
//   }, []);

//   return (
//     <div
//       className="bg-cover bg-center h-auto sm:h-[38rem] p-8 md:p-16 my-8 md:my-[16rem]"
//       style={{
//         backgroundImage: `url(${removebg_preview})`,
//         backgroundSize: "cover",
//       }}
//     >
//       <div className="text-center">
//         <h3 className="text-[#002298] text-xl md:text-2xl lg:text-2xl">
//           Testimonials
//         </h3>
//         <h1 className="text-3xl md:text-4xl lg:text-4xl font-semibold">
//           What people say about us
//         </h1>
//       </div>
//       <div className="flex justify-center">
//         <div className="w-full">
//           <Slider {...slickSettings}>
//             {testimonials.map((testimonial) => (
//               <Slide key={testimonial.id} testimonial={testimonial} />
//             ))}
//           </Slider>
//         </div>
//       </div>

//       <PreviousNextMethods sliderRef={sliderRef} />
//     </div>
//   );
// };

// export default Testimony;

