import React, { useState, useCallback } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { CgMenu, CgCloseR } from "react-icons/cg";
import { Link as ScrollLink } from "react-scroll";
import Logo from "../assets/images/GL_logo.jpeg";
import button_icon from "../assets/icons/button_icon.svg";

const Navbar = () => {
  const [isActiveItem, setIsActiveItem] = useState(
    localStorage.getItem("activeNavItem") || "home"
  );
  const location = useLocation();
  const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const hideElements = location.pathname.includes("/clutch");

  const navItems = [
    { text: "Home", path: "/", id: "home" },
    { text: "Services", path: "services", id: "services" },
    { text: "About Us", path: "/aboutus", id: "aboutus" },
    { text: "Case Studies", path: "/case-studies", id: "case-studies" },
  ];

  const toggleNav = useCallback(() => {
    setIsNavOpen(!isNavOpen);
  }, [isNavOpen]);

  const handleItemClick = useCallback((itemId, path) => {
    setIsActiveItem(itemId);
    setIsNavOpen(false);
    localStorage.setItem("activeNavItem", itemId);
    if (path !== "services") {
      window.scrollTo(0, 0); 
    }
  }, []);

  const isCaseStudyPage = !location.pathname.includes("/case-studies");

  const navClassNames = `md:fixed text-[17px] ${
    isNavOpen ? "flex" : "hidden md:flex"
  } text-[#000F40] border-2 border-white border-opacity-20 md:flex md:justify-end items-center md:gap-[54px] gap-[54px] md:rounded-full backdrop-blur-sm bg-gradient-to-r from-blue-100/60 to-gray-100/80 md:py-3 py-6 md:px-8 px-14 cursor-pointer`;

  const handleLogoClick = () => {
    navigate("/"); 
  };

  return (
    <div className={`py-4 md:py-8 mx-4 md:mx-12 ${isCaseStudyPage && "absolute inset-x-0"}`}>
      <div className="grid grid-cols-8 md:grid-cols-12 gap-4 md:gap-0">
        <div className="col-span-4 z-10" onClick={handleLogoClick}>
            <img src={Logo} alt="Logo" className="md:h-12 h-10 cursor-pointer" />
        </div>
        {!hideElements && (
          <div className="col-span-4 z-50">
            <div className="md:hidden flex justify-end" onClick={toggleNav}>
              {isNavOpen ? (
                <CgCloseR className="h-4 w-4" />
              ) : (
                <CgMenu className="h-8 w-8" />
              )}
            </div>
            <ul className={`${navClassNames} flex-col items-start md:flex-row md:items-center`}>
              {navItems.map((item) => (
                <li key={item.id} className="mb-2 md:mb-0 relative w-full md:w-auto">
                  {item.path === "services" ? (
                    <ScrollLink
                      onClick={() => handleItemClick(item.id, item.path)}
                      activeClass="active"
                      to={item.path}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={1000}
                      className={`w-full md:w-auto ${isActiveItem === item.id ? "text-[#000F40]" : ""}`}
                    >
                      {item.text}
                    </ScrollLink>
                  ) : (
                    <NavLink
                      to={item.path}
                      onClick={() => handleItemClick(item.id, item.path)}
                      className="w-full md:w-auto"
                    >
                      <p className={isActiveItem === item.id ? "text-[#000F40] font-bold" : ""}>
                        {item.text}
                      </p>
                    </NavLink>
                  )}
                  {isActiveItem === item.id && (
                    <span className="absolute top-2.5 -left-2 h-1.5 w-1.5 bg-[#000F40] rounded-full"></span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="md:col-span-4 col-span-9 text-center md:text-right">
          {!hideElements && (
            // <ScrollLink
            //   activeClass="active"
            //   to="contactUsForm"
            //   spy={true}
            //   smooth={true}
            //   offset={-70}
            //   duration={1000}
            // >
              <NavLink
                to="/schedule"
                className="btn-gradient-1 z-10 button"
                style={{
                  borderWidth: "2px",
                  padding: "0.5rem 2rem",
                }}
              >
                Schedule a call
                <img src={button_icon} alt="Button Icon" className="ml-2" />
              </NavLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;



// import React, { useEffect, useState, useCallback } from "react";
// import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import { CgMenu, CgCloseR } from "react-icons/cg";
// import { Link as ScrollLink } from "react-scroll";
// import Logo from "../assets/images/GL_logo.jpeg";
// import button_icon from "../assets/icons/button_icon.svg";

// const Navbar = () => {
//   const [isActiveItem, setIsActiveItem] = useState(
//     localStorage.getItem("activeNavItem") || "home"
//   );
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [isNavOpen, setIsNavOpen] = useState(false);
//   const hideElements = location.pathname.includes("/clutch");

//   const navItems = [
//     { text: "Home", path: "/", id: "home" },
//     { text: "Services", path: "services", id: "services" },
//     { text: "About Us", path: "/aboutus", id: "aboutus" },
//     { text: "Case Studies", path: "/case-studies", id: "case-studies" },
//   ];

//   const toggleNav = useCallback(() => {
//     setIsNavOpen(!isNavOpen);
//   }, [isNavOpen]);

//   const handleItemClick = useCallback((itemId, path) => {
//     setIsActiveItem(itemId);
//     setIsNavOpen(false);
//     localStorage.setItem("activeNavItem", itemId);
//     if (path !== "services") {
//       window.scrollTo(0, 0); // Scroll to top for non-scroll link paths
//     }
//   }, []);

//   const isCaseStudyPage = !location.pathname.includes("/case-studies");

//   const navClassNames = `md:fixed text-[17px] ${
//     isNavOpen ? "flex" : "hidden md:flex"
//   } text-[#000F40] border-2 border-white border-opacity-20 md:flex md:justify-end items-center md:gap-[54px] gap-[54px] md:rounded-full backdrop-blur-sm bg-gradient-to-r from-blue-100/60 to-gray-100/80 md:py-3 py-6 md:px-8 px-14 cursor-pointer`;

//   return (
//     <div className={`py-4 md:py-8 mx-4 md:mx-12 ${isCaseStudyPage && "absolute inset-x-0"}`}>
//       <div className="grid grid-cols-8 md:grid-cols-12 gap-4 md:gap-0">
//         <div className="col-span-4 z-10">
//             <img src={Logo} alt="Logo" className="md:h-12 h-10" />
//         </div>
//         {!hideElements && (
//           <div className="col-span-4 z-50">
//             <div className="md:hidden flex justify-end" onClick={toggleNav}>
//               {isNavOpen ? (
//                 <CgCloseR className="h-4 w-4" />
//               ) : (
//                 <CgMenu className="h-8 w-8" />
//               )}
//             </div>
//             <ul className={`${navClassNames} flex-col items-start md:flex-row md:items-center`}>
//               {navItems.map((item) => (
//                 <li key={item.id} className="mb-2 md:mb-0 relative w-full md:w-auto">
//                   {item.path === "services" ? (
//                     <ScrollLink
//                       onClick={() => handleItemClick(item.id, item.path)}
//                       activeClass="active"
//                       to={item.path}
//                       spy={true}
//                       smooth={true}
//                       offset={-70}
//                       duration={1000}
//                       className={`w-full md:w-auto ${isActiveItem === item.id ? "text-[#000F40]" : ""}`}
//                     >
//                       {item.text}
//                     </ScrollLink>
//                   ) : (
//                     <NavLink
//                       to={item.path}
//                       onClick={() => handleItemClick(item.id, item.path)}
//                       className="w-full md:w-auto"
//                     >
//                       <p className={isActiveItem === item.id ? "text-[#000F40] font-bold" : ""}>
//                         {item.text}
//                       </p>
//                     </NavLink>
//                   )}
//                   {isActiveItem === item.id && (
//                     <span className="absolute top-2.5 -left-2 h-1.5 w-1.5 bg-[#000F40] rounded-full"></span>
//                   )}
//                 </li>
//               ))}
//             </ul>
//           </div>
//         )}
//         <div className="md:col-span-4 col-span-9 text-center md:text-right">
//           {!hideElements && (
//             <ScrollLink
//               activeClass="active"
//               to="contactUsForm"
//               spy={true}
//               smooth={true}
//               offset={-70}
//               duration={1000}
//             >
//               <button
//                 className="btn-gradient-1 z-10 button"
//                 style={{
//                   borderWidth: "2px",
//                   padding: "0.5rem 2rem",
//                 }}
//               >
//                 Become a Client
//                 <img src={button_icon} alt="Button Icon" className="ml-2" />
//               </button>
//             </ScrollLink>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Navbar;



// import React, { useEffect, useState, useCallback } from "react";
// import { NavLink, useLocation } from "react-router-dom";
// import { CgMenu, CgCloseR } from "react-icons/cg";
// import { Link as ScrollLink } from "react-scroll";
// import Logo from "../assets/images/GL_logo.jpeg";
// import button_icon from "../assets/icons/button_icon.svg";

// const Navbar = () => {
//   const [isActiveItem, setIsActiveItem] = useState(
//     localStorage.getItem("activeNavItem") || "home"
//   );
//   const location = useLocation();
//   const [isNavOpen, setIsNavOpen] = useState(false);
//   const hideElements = location.pathname.includes("/clutch");

//   const navItems = [
//     { text: "Home", path: "/", id: "home" },
//     { text: "Services", path: "services", id: "services" },
//     { text: "About Us", path: "/aboutus", id: "aboutus" },
//     { text: "Case Studies", path: "/case-studies", id: "case-studies" },
//   ];

//   const toggleNav = useCallback(() => {
//     setIsNavOpen(!isNavOpen);
//   }, [isNavOpen]);

//   const handleItemClick = useCallback((itemId) => {
//     setIsActiveItem(itemId);
//     setIsNavOpen(false);
//     localStorage.setItem("activeNavItem", itemId);
//   }, []);

//   const isCaseStudyPage = !location.pathname.includes("/case-studies");

//   const navClassNames = `md:fixed text-[17px] ${
//     isNavOpen ? "flex" : "hidden md:flex"
//   } text-[#000F40] border-2 border-white border-opacity-20 md:flex md:justify-end items-center md:gap-[54px] gap-[54px] md:rounded-full backdrop-blur-sm bg-gradient-to-r from-blue-100/60 to-gray-100/80 md:py-3 py-6 md:px-8 px-14 cursor-pointer`;

//   return (
//     <div className={`py-4 md:py-8 mx-4 md:mx-12 ${isCaseStudyPage && "absolute inset-x-0"}`}>
//       <div className="grid grid-cols-8 md:grid-cols-12 gap-4 md:gap-0">
//         <div className="col-span-4 z-10">
//           <img src={Logo} alt="Logo" className="md:h-12 h-10" />
//         </div>
//         {!hideElements && (
//           <div className="col-span-4 z-50">
//             <div className="md:hidden flex justify-end" onClick={toggleNav}>
//               {isNavOpen ? (
//                 <CgCloseR className="h-4 w-4" />
//               ) : (
//                 <CgMenu className="h-8 w-8" />
//               )}
//             </div>
//             <ul className={`${navClassNames} flex-col items-start md:flex-row md:items-center`}>
//               {navItems.map((item) => (
//                 <li key={item.id} className="mb-2 md:mb-0 relative w-full md:w-auto">
//                   {item.path === "services" ? (
//                     <ScrollLink
//                       onClick={() => handleItemClick(item.id)}
//                       activeClass="active"
//                       to={item.path}
//                       spy={true}
//                       smooth={true}
//                       offset={-70}
//                       duration={1000}
//                       className={`w-full md:w-auto ${isActiveItem === item.id ? "text-[#000F40]" : ""}`}
//                     >
//                       {item.text}
//                     </ScrollLink>
//                   ) : (
//                     <NavLink
//                       to={item.path}
//                       onClick={() => handleItemClick(item.id)}
//                       className="w-full md:w-auto"
//                     >
//                       <p className={isActiveItem === item.id ? "text-[#000F40] font-bold" : ""}>
//                         {item.text}
//                       </p>
//                     </NavLink>
//                   )}
//                   {isActiveItem === item.id && (
//                     <span className="absolute top-2.5 -left-2 h-1.5 w-1.5 bg-[#000F40] rounded-full"></span>
//                   )}
//                 </li>
//               ))}
//             </ul>
//           </div>
//         )}
//         <div className="md:col-span-4 col-span-9 text-center md:text-right">
//           {!hideElements && (
//             <ScrollLink
//               activeClass="active"
//               to="contactUsForm"
//               spy={true}
//               smooth={true}
//               offset={-70}
//               duration={1000}
//             >
//               <button
//                 className="btn-gradient-1 z-10 button"
//                 style={{
//                   borderWidth: "2px",
//                   padding: "0.5rem 2rem",
//                 }}
//               >
//                 Become a Client
//                 <img src={button_icon} alt="Button Icon" className="ml-2" />
//               </button>
//             </ScrollLink>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Navbar;



// import React, { useEffect, useState, useCallback } from "react";
// import Logo from "../assets/images/GL_logo.jpeg";
// import button_icon from "../assets/icons/button_icon.svg";
// import { NavLink, useLocation } from "react-router-dom";
// import { CgMenu, CgCloseR } from "react-icons/cg";
// import { Link as ScrollLink } from "react-scroll";

// const Navbar = () => {
//   const [isActiveItem, setIsActiveItem] = useState(() => {
//     // Get the active item from localStorage if available, otherwise default to "home"
//     return localStorage.getItem("activeNavItem") || "home";
//   });
//   const location = useLocation();
//   const [isNavOpen, setIsNavOpen] = useState(false);

//   // const hideContactButton = location.pathname.includes("/clutch");
//   const hideElements = location.pathname.includes("/clutch");


//   const navItems = [
//     {
//       text: "Home",
//       path: "/",
//       id: "home",
//     },
//     { text: "Services", path: "services", id: "services" },
//     { text: "About Us", path: "/aboutus", id: "aboutus" },
//     { text: "Case Studies", path: "/case-studies", id: "case-studies" },
//   ];

//   const toggleNav = useCallback(() => {
//     setIsNavOpen(!isNavOpen);
//   }, [isNavOpen]);

//   const handleItemClick = useCallback(
//     (itemId) => {
//       setIsActiveItem(itemId);
//       setIsNavOpen(false);
//       // Save the active item to localStorage
//       localStorage.setItem("activeNavItem", itemId);
//     },
//     [setIsActiveItem, setIsNavOpen]
//   );

//   const isCaseStudyPage = !location.pathname.includes("/case-studies");

//   const navClassNames = `md:fixed text-[17px] ${
//     isNavOpen ? "md:flex" : "hidden"
//   } text-[#000F40] border-2 border-white border-opacity-20 md:flex md:justify-end items-center md:gap-[54px] gap-[54px] md:rounded-full backdrop-blur-sm bg-gradient-to-r from-blue-100/60 to-gray-100/80 md:py-3 py-6 md:px-8 px-14 cursor-pointer`;

//   return (
//     <div
//       className={`py-4 md:py-8 mx-4 md:mx-12 ${
//         isCaseStudyPage && "absolute inset-x-0"
//       }`}
//     >
//       <div className="grid grid-cols-8 md:grid-cols-12 gap-4 md:gap-0 text-right">
//         <div className="col-span-4 z-10">
//           <img src={Logo} alt="Logo" className="md:h-12 h-10" />
//         </div>
//         {!hideElements && (
//         <div className="col-span-4 z-50">
//           <div className="md:hidden flex justify-end" onClick={toggleNav}>
//             {isNavOpen ? (
//               <CgCloseR className="h-4 w-4" />
//             ) : (
//               <CgMenu className="h-8 w-8" />
//             )}
//           </div>
          
//           <ul className={navClassNames}>
//             {navItems.map((item) => (
//               <li key={item.id} className="mb-2 md:mb-0 relative">
//                 {item.path === "services" ? (
//                   <ScrollLink
//                     onClick={() => handleItemClick(item.id)}
//                     activeClass="active"
//                     to={item.path}
//                     spy={true}
//                     smooth={true}
//                     offset={-70}
//                     duration={1000}
//                     className={isActiveItem === item.id ? "text-[#000F40]" : ""}
//                   >
//                     {item.text}
//                   </ScrollLink>
//                 ) : (
//                   <NavLink
//                     to={item.path}
//                     onClick={() => handleItemClick(item.id)}
//                   >
//                     <p
//                       className={
//                         isActiveItem === item.id
//                           ? "text-[#000F40] font-bold"
//                           : ""
//                       }
//                     >
//                       {item.text}
//                     </p>
//                   </NavLink>
//                 )}
//                 {isActiveItem === item.id && (
//                   <span className="absolute top-2.5 -left-2 h-1.5 w-1.5 bg-[#000F40] rounded-full"></span>
//                 )}
//               </li>
//             ))}
//           </ul>
        
//         </div>
//       )}
//         <div className="md:col-span-4 col-span-9 text-center md:text-right">
//           {!hideElements && (
//             <ScrollLink
//               activeClass="active"
//               to="contactUsForm"
//               spy={true}
//               smooth={true}
//               offset={-70}
//               duration={1000}
//             >
//               <button
//                 className="btn-gradient-1 z-10 button"
//                 style={{
//                   borderWidth: "2px",
//                   padding: "0.5rem 2rem",
//                 }}
//               >
//                 Become a Client
//                 <img src={button_icon} alt="Button Icon" className="ml-2" />
//               </button>
//             </ScrollLink>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Navbar;




