import React from "react";
// import Schedule_bg from "../assets/images/schedule/Schedule_bg.png";
// import Programming from "../assets/images/schedule/Programming-amico.svg";
import Schedule1 from "../assets/images/schedule/Schedule1.png";
import Schedule2 from "../assets/images/schedule/Schedule2.png";
import image_removebg from "../assets/images/schedule/image-removebg.png";
import button_icon1 from "../assets/icons/button_icon1.svg";
import illus from "../assets/images/schedule/illus.svg";
import { FaCheck } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

const OurTeam = () => {
  const expertise = [
    "Expert Software Engineers and Designers",
    "Available in Your Time Zone",
    "Speed to Market",
    "Flexibility and Cost-Efficiency",
    "End-to-End Solutions",
  ];

  return (
    <div className="relative mt-[10vh] lg:mt-[20vh]">
      {/* Right container */}
      <div className="flex flex-col lg:flex-row mx-5 lg:mx-16 justify-between items-center py-2 lg:py-4 pl-2 lg:pl-14 mt-10 lg:mt-0 shadow border rounded-2xl">
        <div className=" lg:order-last">
          <div className="inset-10 z-10">
            <img
              src={illus}
              alt="Programming"
              className="h-[200px] lg:h-[500px] object-cover md:block hidden"
            />
            <img
              src={image_removebg}
              alt="Programming"
              className="h-[200px] lg:h-[500px] object-cover sm:block md:hidden"
            />
          </div>
        </div>
        {/* Left container */}
        <div className="relative w-full lg:w-[38rem] mt-2 md:mt-0 lg:mt-0 text-[#000] py-16 lg:pl-10">
          <h1 className="text-[#002298] text-xl md:text-3xl lg:text-3xl font-semibold mb-6">
            Let’s build something great Together
          </h1>
          <ul className="space-y-4 text-sm md:text-sm font-medium">
            {expertise.map((item, index) => (
              <li key={index} className="flex items-center">
                <FaCheck className="mr-2 text-green-500" />
                {item}
              </li>
            ))}
          </ul>
          {/* <div className="flex flex-col md:flex-row gap-2 justify-center items-center md:justify-start mt-4 md:mt-8 ml-2"> */}
          <div className="mt-4 md:mt-8 flex justify-center md:justify-start">
            <NavLink
              to="/schedule"
              className="btn-gradient-2 button"
              style={{
                borderWidth: "3px",
                padding: "0.6rem 2rem",
              }}
              target="_blank"
            >
              Let’s Build
              <img src={button_icon1} alt="Button Icon" className="ml-2" />
            </NavLink>
          </div>

          {/* <Link
              activeClass="active"
              to="contactUsForm"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
            >
              <button
                className="btn-gradient-1 z-10"
                style={{
                  borderWidth: "2px",
                  padding: "0.6rem 2rem",
                }}
              >
                Contact Us
              </button>
            </Link> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
