import React from 'react';
import Slider from 'react-slick';
import HappyChat from "../assets/images/happychat.png";
import imongu from "../assets/images/imongu2.svg";
import Rapiq from "../assets/images/rapiq_logo.png";
import SimplyFema from "../assets/images/Simply_Fema.png";
import CM from "../assets/images/cm_logo.png";
import Jaba from "../assets/images/jaba.png";
import Dronetek from "../assets/images/Dronetek.svg";

// import HappyChat_img from "../assets/images/CaseStudies/HappyChat_img.png";
import HappyChat_img from "../assets/images/CaseStudies/HappyChat/HAppyChat.png";
import Simplyfema_img from "../assets/images/CaseStudies/Simplyfema_img.png";
// import CM_img from "../assets/images/CaseStudies/CM_img.png";
import CM_img from "../assets/images/CaseStudies/CM/CM1.svg";
// import imongu_img from "../assets/images/CaseStudies/imongu_img.png";
import imongu_img from "../assets/images/CaseStudies/imongu/iMongu.png";
// import rapiq_img from "../assets/images/CaseStudies/rapiq_img.png";
import rapiq_img from "../assets/images/CaseStudies/Rapiq/rapiq.png";
// import dronetjek_img from "../assets/images/CaseStudies/dronetjek_img.png";
import dronetjek_img from "../assets/images/CaseStudies/Dronetjek/Dronetjek.png";
// import jaba from "../assets/images/CaseStudies/jaba.png";
import jaba from "../assets/images/CaseStudies/JABA/jaba.svg";

import { NavLink } from "react-router-dom";
import arrow_up from "../assets/images/AboutUs/arrow-up.svg";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import LeftArrow from "../assets/icons/AboutUsIcons/LeftArrow.svg";
import RightArrow from "../assets/icons/AboutUsIcons/RightArrow.svg";

const LeftArrowComponent = (props) => (
  <img
    src={LeftArrow}
    alt="Left Arrow"
    className="slick-arrow slick-prev left-2 z-10"
    style={{ width: "30px", height: "30px" }}
    onClick={props.onClick}
  />
);

const RightArrowComponent = (props) => (
  <img
    src={RightArrow}
    alt="Right Arrow"
    className="slick-arrow slick-next right-2 z-10"
    style={{ width: "30px", height: "30px" }}
    onClick={props.onClick}
  />
);

const CaseStudy = () => {
  const projectsNames = [
    {
      image: HappyChat_img,
      logo: HappyChat,
      ItemName: "AI Integrated Chatbot Software",
      Skills: "ReactJS, Figma, Python/Flask, AWS, DynamoDB",
      border: "#FFEBCD",
      background: "#FFFEFC",
      route: "/case-studies/happychat",
    },
    {
      image: imongu_img,
      logo: imongu,
      ItemName: "iMongu - OKR Management Software",
      Skills: "ReactJS, Figma, Python/Django, AWS, Postgres",
      border: "#FFD9EE",
      background: "#FFFCFE",
      route: "/case-studies/imongu",
    },
    {
      image: CM_img,
      logo: CM,
      text: "Creatives Matter",
      ItemName: "Creatives Matters LLC - AI driven CRM for marketers",
      Skills: "ReactJS, Figma, Python/Django, AWS, OpenAI, Postgres",
      border: "#CBE0FF",
      background: "#FBFCFF",
      route: "/case-studies/creativesMatter",
    },
    {
      image: jaba,
      logo: Jaba,
      ItemName: "Built Different LLC - AI branch Coach for athletes",
      Skills: "React Native, ReactJS, Figma, Python/FastAPI, AWS, Langchain Agents, MySql",
      border: "#FFD9D9",
      background: "#FFFCFC",
      route: "/case-studies/jaba",
    },
    {
      image: rapiq_img,
      logo: Rapiq,
      ItemName: "Rapiq LLC - Marketplace for Clients and Agencies",
      Skills: "ReactJS, SocketIO, Figma, Python/Django, AWS, OpenAI, ClaudeAPI, Zapier, MySql",
      border: "#D4DDFF",
      background: "#F9FBFF",
      route: "/case-studies/rapiq",
    },
    {
      image: dronetjek_img,
      logo: Dronetek,
      ItemName: "Drone-Tjek Pvt Ltd - Image Annotation tool, Postgres",
      Skills: "ReactJS, Figma, Python/Django",
      border: "#BCFF94",
      background: "#FCFFFA",
      route: "/case-studies/DroneTjek",
    },
    {
      image: Simplyfema_img,
      logo: SimplyFema,
      text: "Simply Fema",
      ItemName: "Unravel Technologies Pvt Ltd - Foreign Exchange Compliance Platform",
      Skills: "ElectronJS,Nextjs,Formik, Figma, Python/Django, Firebase, rxDB",
      border: "#BCFF94",
      background: "#FCFFFA",
      route: "/case-studies/simplyfema",
    },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <RightArrowComponent />,
    prevArrow: <LeftArrowComponent />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '0',
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '0',
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '0',
        }
      }
    ]
  };

  return (
    <div className="m-8 md:mx-[10vw] mt-[4rem]">
      <div className="flex flex-col justify-center items-center text-center mb-8">
        <h1 className="text-xl md:text-3xl text-[#000] font-semibold">Case Studies</h1>
        <p className="text-[10px] md:text-[18px] text-[#4E4E4E] mx-6 md:mx-0">
          Journey through Excellence: Discovering Our Project, Portfolios and Methodological achievements
        </p>
      </div>
      <div className="relative">
        <Slider {...settings}>
          {projectsNames.map((project, index) => (
            <div key={index} className="p-4 flex justify-center">
              <div className="rounded-xl p-4 md:p-14 h-full w-full shadow-lg border" style={{ borderColor: project.border, backgroundColor: project.background }}>
                <div className="flex flex-col-reverse md:flex-row items-center">
                  <div className="md:w-1/2 mb-4 md:mb-0 md:pr-4">
                    <div className="flex items-center gap-2 mb-4 mt-4 md:mt-0">
                      <img src={project.logo} alt={project.ItemName} className="h-10" />
                      <p className='text-[20px] font-semibold'>{project.text}</p>
                    </div>
                    <h2 className="text-[18px] text-[#07217D] font-semibold mb-2">{project.ItemName}</h2>
                    <p className="text-[#383838] text-sm mb-3">{project.ItemType}</p>
                    <p className="text-[#383838] text-sm">
                      <span className="text-[#07217D]">Technology Stack:</span> {project.Skills}
                    </p>
                    <div className="flex justify-start mt-8">
                      <NavLink
                        to={project.route}
                        target="_blank"
                        className="text-[#091D62] border-[#091D62] border rounded-full py-1.5 pl-5 pr-2 relative flex items-center space-x-2 cursor-pointer z-10 button-container"
                      >
                        <span>View Case Studies</span>
                        <div className="bg-[#5AC4FE] rounded-full p-1 ">
                          <img src={arrow_up} alt="arrow_up" className="h-4 md:h-6 arrow-container" />
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="md:w-1/2">
                    <img src={project.image} alt={project.ItemName} className="w-full h-auto" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CaseStudy;
