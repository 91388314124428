import React, { useEffect, useState } from "react";

const Schedule = () => {
  const [showSoftwareDev, setShowSoftwareDev] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSoftwareDev(!showSoftwareDev);
    }, 2000);

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [showSoftwareDev]);

  // Initialize Calendly badge widget
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    // <div
    //   class="calendly-inline-widget"
    //   data-url="https://calendly.com/satyajitjena360/schedule-a-meeting-clone"
    //   style={{ minWidth: "320px", height: "700px" }}
    // ></div>
    <div
      className="calendly-inline-widget"
      data-url="https://calendly.com/growth-loops-techology/15-mins-intro?hide_gdpr_banner=1&primary_color=17dac3"
      style={{ minWidth: "320px", height: "800px" }}
    />
  );
};

export default Schedule;
