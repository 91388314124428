import slack from "../assets/images/WeWork/slack.png";
import react_icon from "../assets/images/WeWork/react-icon.png";
import python from "../assets/images/WeWork/icon_python.png";
import flask from "../assets/images/WeWork/flask.png";
import DB from "../assets/images/WeWork/DB.png";
import apify from "../assets/images/WeWork/apify.png";

import main from "../assets/images/CaseStudies/HappyChat/HAppyChat.png";
import Vision1 from "../assets/images/CaseStudies/HappyChat/Vision1.png";
import Vision2 from "../assets/images/CaseStudies/HappyChat/Vision2.png";
import overView_img1 from "../assets/images/CaseStudies/HappyChat/image1.png";
import overView_img2 from "../assets/images/CaseStudies/HappyChat/image2.png";
import overView_img3 from "../assets/images/CaseStudies/HappyChat/image3.png";
import glimpseImages1 from "../assets/images/CaseStudies/HappyChat/glimpseImages1.png";
import glimpseImages2 from "../assets/images/CaseStudies/HappyChat/glimpseImages2.png";
import glimpseImages3 from "../assets/images/CaseStudies/HappyChat/glimpseImages3.png";
import glimpseImages4 from "../assets/images/CaseStudies/HappyChat/glimpseImages4.png";
import glimpseImages5 from "../assets/images/CaseStudies/HappyChat/glimpseImages5.png";
import glimpseImages6 from "../assets/images/CaseStudies/HappyChat/glimpseImages6.png";
import glimpseImages7 from "../assets/images/CaseStudies/HappyChat/glimpseImages7.png";
import glimpseImages8 from "../assets/images/CaseStudies/HappyChat/glimpseImages8.png";
import HappyChat from "../assets/images/happychat.png";
import client from "../assets/images/CaseStudies/HappyChat/client.png";

import iMongu from "../assets/images/CaseStudies/imongu/iMongu.png";
import overViewimg1 from "../assets/images/CaseStudies/imongu/image1.png";
import overViewimg2 from "../assets/images/CaseStudies/imongu/image2.png";
import glimpseImages_1 from "../assets/images/CaseStudies/imongu/glimpseImages1.png";
import glimpseImages_2 from "../assets/images/CaseStudies/imongu/glimpseImages2.png";
import glimpseImages_3 from "../assets/images/CaseStudies/imongu/glimpseImages3.png";
import glimpseImages_4 from "../assets/images/CaseStudies/imongu/glimpseImages4.png";
import glimpseImages_5 from "../assets/images/CaseStudies/imongu/glimpseImages5.png";
import glimpseImages_6 from "../assets/images/CaseStudies/imongu/glimpseImages6.png";
import glimpseImages_7 from "../assets/images/CaseStudies/imongu/glimpseImages7.png";
import glimpseImages_8 from "../assets/images/CaseStudies/imongu/glimpseImages8.png";
import imongu from "../assets/images/imongu2.svg";
import client1 from "../assets/images/CaseStudies/imongu/client.png";

import CM from "../assets/images/CaseStudies/CM/CM1.svg";
// import CM from "../assets/images/CaseStudies/CM/CM.png";
import django from "../assets/images/CaseStudies/CM/django.png";
import overViewimg_1 from "../assets/images/CaseStudies/CM/image1.png";
import overViewimg_2 from "../assets/images/CaseStudies/CM/image2.png";
import overViewimg_3 from "../assets/images/CaseStudies/CM/image3.png";
import glimpseImg_1 from "../assets/images/CaseStudies/CM/glimpseImages1.png";
import glimpseImg_2 from "../assets/images/CaseStudies/CM/glimpseImages2.png";
import glimpseImg_3 from "../assets/images/CaseStudies/CM/glimpseImages3.png";
import glimpseImg_4 from "../assets/images/CaseStudies/CM/glimpseImages4.png";
import glimpseImg_5 from "../assets/images/CaseStudies/CM/glimpseImages5.png";
import glimpseImg_6 from "../assets/images/CaseStudies/CM/glimpseImages6.png";
import client_CM from "../assets/images/CaseStudies/CM/client.png";
import cm from "../assets/images/cm_logo.png";

import jaba from "../assets/images/CaseStudies/JABA/jaba.svg";
import Viewimg_1 from "../assets/images/CaseStudies/JABA/image1.png";
import Viewimg_2 from "../assets/images/CaseStudies/JABA/image2.png";
import Viewimg_3 from "../assets/images/CaseStudies/JABA/image3.png";
import glimpse_1 from "../assets/images/CaseStudies/JABA/glimpseImages1.png";
import glimpse_2 from "../assets/images/CaseStudies/JABA/glimpseImages2.png";
import glimpse_3 from "../assets/images/CaseStudies/JABA/glimpseImages3.png";
import glimpse_4 from "../assets/images/CaseStudies/JABA/glimpseImages4.png";
import glimpse_5 from "../assets/images/CaseStudies/JABA/glimpseImages5.png";
import glimpse_6 from "../assets/images/CaseStudies/JABA/glimpseImages6.png";
import glimpse_7 from "../assets/images/CaseStudies/JABA/glimpseImages7.png";
import glimpse_8 from "../assets/images/CaseStudies/JABA/glimpseImages8.png";
import client_JABA from "../assets/images/CaseStudies/JABA/client.png";
import Jaba from "../assets/images/CaseStudies/JABA/Jaba_logo.png";

import Dronetjek from "../assets/images/CaseStudies/Dronetjek/Dronetjek.png";
import DronetjekViewimg_1 from "../assets/images/CaseStudies/Dronetjek/image1.png";
import DronetjekViewimg_2 from "../assets/images/CaseStudies/Dronetjek/image2.png";
import DronetjekViewimg_3 from "../assets/images/CaseStudies/Dronetjek/image3.png";
import Dronetjekglimpse_1 from "../assets/images/CaseStudies/Dronetjek/glimpseImages1.png";
import Dronetjekglimpse_2 from "../assets/images/CaseStudies/Dronetjek/glimpseImages2.png";
import Dronetjekglimpse_3 from "../assets/images/CaseStudies/Dronetjek/glimpseImages3.png";
import Dronetjekglimpse_4 from "../assets/images/CaseStudies/Dronetjek/glimpseImages4.png";
import Dronetjekglimpse_5 from "../assets/images/CaseStudies/Dronetjek/glimpseImages5.png";
import Dronetjekglimpse_6 from "../assets/images/CaseStudies/Dronetjek/glimpseImages6.png";

// import client_JABA from "../assets/images/CaseStudies/JABA/client.png";
// import Jaba from "../assets/images/CaseStudies/JABA/Jaba_logo.png";

import Rapiq from "../assets/images/CaseStudies/Rapiq/rapiq.png";
import RapiqViewimg_1 from "../assets/images/CaseStudies/Rapiq/image1.png";
import RapiqViewimg_2 from "../assets/images/CaseStudies/Rapiq/image2.png";
import RapiqViewimg_3 from "../assets/images/CaseStudies/Rapiq/image3.png";
import Rapiqglimpse_1 from "../assets/images/CaseStudies/Rapiq/Rapiqglimpse1.png";
import Rapiqglimpse_2 from "../assets/images/CaseStudies/Rapiq/Rapiqglimpse2.png";
import Rapiqglimpse_3 from "../assets/images/CaseStudies/Rapiq/Rapiqglimpse3.png";
import Rapiqglimpse_4 from "../assets/images/CaseStudies/Rapiq/Rapiqglimpse4.png";
import Rapiqglimpse_5 from "../assets/images/CaseStudies/Rapiq/Rapiqglimpse5.png";
import Rapiqglimpse_6 from "../assets/images/CaseStudies/Rapiq/Rapiqglimpse6.png";
import Rapiqglimpse_7 from "../assets/images/CaseStudies/Rapiq/Rapiqglimpse7.png";
import Rapiqglimpse_8 from "../assets/images/CaseStudies/Rapiq/Rapiqglimpse8.png";
import Rapiqglimpse_9 from "../assets/images/CaseStudies/Rapiq/Rapiqglimpse9.png";
import Rapiqglimpse_10 from "../assets/images/CaseStudies/Rapiq/Rapiqglimpse10.png";


import SF from "../assets/images/CaseStudies/Simplyfema/SF.png";
import  SimplyFemaViewimg_1 from "../assets/images/CaseStudies/Simplyfema/image1.png";
import  SimplyFemaViewimg_2 from "../assets/images/CaseStudies/Simplyfema/image2.png";
import  SimplyFemaViewimg_3 from "../assets/images/CaseStudies/Simplyfema/image3.png";
import SimplyFemaglimpse_1 from "../assets/images/CaseStudies/Simplyfema/glimpseImages1.png";
import SimplyFemaglimpse_2 from "../assets/images/CaseStudies/Simplyfema/glimpseImages2.png";
import SimplyFemaglimpse_3 from "../assets/images/CaseStudies/Simplyfema/glimpseImages3.png";
import SimplyFemaglimpse_4 from "../assets/images/CaseStudies/Simplyfema/glimpseImages4.png";
import SimplyFemaglimpse_5 from "../assets/images/CaseStudies/Simplyfema/glimpseImages5.png";
import SimplyFemaglimpse_6 from "../assets/images/CaseStudies/Simplyfema/glimpseImages6.png";

export const caseStudies = [
  {
    name: "Happychat",
    title:
      "HappyChat enhances your website with a personalized chatbot experience.",
    bgColor:
      "linear-gradient(180deg, rgba(254, 217, 1, 0.80) 0%, rgba(255, 134, 33, 0.80) 100%)",
    techStacks: [slack, react_icon, python, flask, DB, apify],
    bgImage: main,
    cards: [
      {
        borderColor: "#FFD503",
        Heading: "Client Vision",
        VisionImg: Vision1,
        Vision: [
          "Provide a personalized chatbot for websites to enhance user engagement and customer support.",
          "Supercharge websites with AI to turn online visits into sales, leads, and happy customers.",
          "Enable easy creation of chatbots trained on user content with a single click.",
          "Deliver a human-like experience using NLP to interpret user input and provide realistic conversations.",
          "Offer customizable chat widgets to align with the brand's style and website design.",
          "Provide insights through reports to measure chatbot performance and drive business growth.",
        ],
      },
      {
        borderColor: "#FFD503",
        Heading: "Our Execution",
        VisionImg: Vision2,
        Vision: [
          "Developed HappyChat AI to provide personalized chatbot services for websites.",
          "Implemented AI to interpret user input, deliver realistic conversations, and improve precision.",
          "Created a customizable chat widget to align with the brand's style and website design.",
          "Provided insights through HappyChat reports to measure chatbot performance and drive business growth.",
          "Enabled easy creation of chatbots by uploading documents or adding a link to the website.",
          "Trained the chatbot on user data and customized it to match the look and feel of the website.",
        ],
      },
    ],
    numberColor: "#ffeac2",
    overViewDesc:
      "Happy Chat is a personalized chatbot service designed to enhance user engagement and customer support on websites. The chatbot is tailored to meet the specific needs of each website, providing a seamless and interactive experience for visitors.",
    overViewCards: [
      {
        num: 1,
        
        desc: "HappyChat AI enhances customer support by handling repetitive cases 24/7, while also boosting sales through personalized assistance and lead generation, creating a more engaging website experience.",
        image: [overView_img1],
      },
      {
        num: 2,
        numberColor: "#ffeac2",
        desc: "HappyChat AI provides a human-like experience with NLP, engages visitors with personalized messages, and offers customizable widgets and insightful reports.",
        image: [overView_img2],
      },
      {
        num: 3,
        numberColor: "#ffeac2",
        desc: "With HappyChat, unlock AI's potential by effortlessly creating a chatbot trained on your content with a single click. Upload documents or links, train the chatbot, and customize its appearance to seamlessly integrate it into your website for immediate customer interaction.",
        image: [overView_img3],
      },
    ],

    growthMetrics: {
      rateTextColor: "text-[#FF8C20]",
      rateBorderColor: "border-[#FF8C20]",
      rate: [
        {
          percent: "15%",
          title: "User Growth",
          subtitle: "Monthly user growth rate",
        },
        {
          percent: "90%",
          title: "Customer Satisfaction",
          subtitle: "Satisfaction Rate",
        },
        {
          percent: "25%",
          title: "Engagement Metrics",
          subtitle: "Increase compared to last quarter",
        },
        {
          percent: "15%",
          title: "Conversion Rate",
          subtitle: "visitors convert into leads/customers",
        },
      ],
    },

    desc: "HappyChat is an AI-powered platform that enables you to easily create and customize conversational chatbots for your website without any coding required. It helps you engage with your website visitors, provide instant answers to their questions, and ultimately drive sales and leads.",
    productGlimpse: {
      glimpseImages: [
        glimpseImages1,
        glimpseImages2,
        glimpseImages3,
        glimpseImages4,
        glimpseImages5,
        glimpseImages6,
        glimpseImages7,
        glimpseImages8,
      ],
    },
    clientbgColor:
      "var(--liener-testimonial, linear-gradient(90deg, rgba(240, 108, 12, 0.30) 0%, rgba(255, 131, 32, 0.30) 0.01%, rgba(255, 217, 2, 0.30) 100%))",
    feedback: {
      feedbackCard: [
        {
          cardbgColor:
            "linear-gradient(90deg, #F9F9F9 0%, rgba(255, 184, 6, 0.30) 252.88%)",
          message:
            "The delivered chatbot and web app align with the client's expectations, enabling them to launch a functional MVP successfully. Growth Loops Technology Pvt Ltd managed the project well — they addressed queries and concerns promptly and always adhered to deadlines. They were also open to feedback.",
          clientImg: [client],
          clientname: "JC Acevedo",
          logo: [HappyChat],
          place: "Head of Marketing",
        },
      ],
    },
  },

  {
    name: "iMongu",
    title: "Achieve Business Success with Focus, Feedback, and Fulfillment.",
    bgColor:
      "linear-gradient(0deg, rgba(255, 152, 0, 0.50) 0%, rgba(2, 240, 255, 0.50) 136.06%)",
    techStacks: [slack, react_icon, python, DB, apify],
    bgImage: iMongu,
    cards: [
      {
        borderColor: "#FF8821",
        Heading: "Client Vision",
        VisionImg: Vision1,
        Vision: [
          "Set multiple goals for business success",
          "Monitor progress effectively",
          "Tailored for growing businesses",
          "Features for growth, alignment, and engagement",
          "Simple dashboards for goal setting and tracking",
          "Deeper insights into objectives, results, and team performance",
        ],
      },
      {
        borderColor: "#00B7E0",
        Heading: "Our Execution",
        VisionImg: Vision2,
        Vision: [
          "Support managers in identifying top performers and addressing bottlenecks",
          "Take proactive action to maintain performance standards",
          "Real-time performance monitoring for informed decision-making",
          "Customizable OKRs to adapt to evolving business needs",
          "Cultivate a culture of progress through data-driven insights",
          "Seamless integration with existing tools and processes",
        ],
      },
    ],
    numberColor: "#72BAD1",
    overViewDesc:
      "A platform designed to help businesses set and achieve their goals through OKR methodology, providing a comprehensive solution for goal management and performance tracking.",
    overViewCards: [
      {
        num: 1,
        desc: "iMongu is tailored for growing businesses, offering OKR frameworks for focused growth, organizational alignment around key objectives, and feedback loops to support goal achievement.",
        image: [overViewimg1],
      },
      {
        num: 2,
        desc: "iMongu enables organizations to achieve their potential with simple goal-setting dashboards, deeper insights, and agility. It supports managers in identifying top performers, taking proactive action, and fostering a culture of measurable progress.",
        image: [overViewimg2],
      },
      // {
      //   num: 3,
      //   desc: "With HappyChat, unlock AI's potential by effortlessly creating a chatbot trained on your content with a single click. Upload documents or links, train the chatbot, and customize its appearance to seamlessly integrate it into your website for immediate customer interaction.",
      //   image: [overView_img3],
      // },
    ],

    growthMetrics: {
      rateTextColor: "text-[#FF8C20]",
      rateBorderColor: "border-[#FF8C20]",
      rate: [
        {
          percent: "15%",
          title: "User Growth",
          subtitle: "Monthly user growth rate",
        },
        {
          percent: "90%",
          title: "Customer Satisfaction",
          subtitle: "Satisfaction Rate",
        },
        {
          percent: "25%",
          title: "Engagement Metrics",
          subtitle: "Increase compared to last quarter",
        },
        {
          percent: "15%",
          title: "Conversion Rate",
          subtitle: "visitors convert into leads/customers",
        },
      ],
    },

    productGlimpse: {
      desc: "iMongu is a comprehensive OKR solution that supports teams in developing focus, feedback, and fulfillment, enabling businesses to set, meet, and exceed their objectives, fostering a culture of measurable progress and achieving business success.",
      glimpseImages: [
        glimpseImages_1,
        glimpseImages_2,
        glimpseImages_3,
        glimpseImages_4,
        glimpseImages_5,
        glimpseImages_6,
        glimpseImages_7,
        glimpseImages_8,
      ],
    },

    clientbgColor:
      "linear-gradient(90deg, rgba(12, 213, 240, 0.30) -35.46%, rgba(255, 154, 2, 0.30) 100%)",
    feedback: {
      feedbackCard: [
        {
          cardbgColor:
            " linear-gradient(90deg, #F9F9F9 0%, rgba(255, 184, 6, 0.30) 252.88%)",
          message:
            "Growth Loops Technology Pvt Ltd's work exceeded the client's expectations. The team was warm and involved in the project. The team took ownership of the client's product and delivered work on time, having direct communication with the client via email and virtual meetings.",
          clientImg: client1,
          clientname: "Agness Mnena (Allagh) ",
          place: "Gaadi, MBA",
          logo: [imongu],
        },
      ],
    },
  },

  {
    name: "Creatives Matter",
    title: "Empowering Brands and Influencers with Creatives Matter",
    bgColor: "linear-gradient(0deg, #98CBFF 0%, #D1E8FF 100%)",
    techStacks: [react_icon, python, DB, django],
    bgImage: CM,
    cards: [
      {
        borderColor: "#FF8821",
        Heading: "Client Vision",
        VisionImg: Vision1,
        Vision: [
          "Design and development of a web application with AI integration.",
          "AI-powered content generation or analysis (for marketing purposes)",
          "Automating marketing processes enhances efficiency, scalability, and enables a focus on strategic growth and customer engagement.",
          "Data-driven insights and analytics optimize decision-making and performance across all operations.",
          // "Simple dashboards for goal setting and tracking",
          // "Deeper insights into objectives, results, and team performance",
        ],
      },
      {
        borderColor: "#00B7E0",
        Heading: "Our Execution",
        VisionImg: Vision2,
        Vision: [
          "AWS Configuration: Set up of a scalable cloud infrastructure.",
          "Front-end and Back-end Development: Creation of both the user interface and the server-side logic and data handling.",
          "UX/UI Design: Focus on user-friendly and visually appealing design.",
          "AI Integration: Incorporation of artificial intelligence capabilities into the web app.",
          // "Cultivate a culture of progress through data-driven insights",
          // "Seamless integration with existing tools and processes",
        ],
      },
    ],
    numberColor: "#42A0FF",
    overViewDesc:
      "Creatives Matter ia an AI-driven web application designed to optimizes marketing operations with advanced algorithms and data analytics, enhancing efficiency and results for clients.",
    overViewCards: [
      {
        num: 1,
        desc: "Project Focus: Design and development of an AI-powered web application to streamline and enhance marketing operations for the client.",
        image: [overViewimg_1],
      },
      {
        num: 2,
        desc: "Technical Expertise: Growth Loops Technology Pvt Ltd demonstrated proficiency in AWS configuration, front-end/back-end development, UX/UI design, and AI integration to deliver a complex software solution",
        image: [overViewimg_2],
      },
      {
        num: 3,
        desc: "Client-Centric Approach: The project was a success due to timely delivery, adherence to budget, and Growth Loops Technology Pvt Ltd's excellent communication, patience, and ability to explain technical concepts in simple terms, ensuring strong client satisfaction.",
        image: [overViewimg_3],
      },
    ],

    growthMetrics: {
      rateTextColor: "text-[#5BA3F1]",
      rateBorderColor: "border-[#5BA3F1]",
      rate: [
        {
          percent: "15%",
          title: "User Growth",
          subtitle: "Monthly user growth rate",
        },
        {
          percent: "90%",
          title: "Customer Satisfaction",
          subtitle: "Satisfaction Rate",
        },
        {
          percent: "25%",
          title: "Engagement Metrics",
          subtitle: "Increase compared to last quarter",
        },
        {
          percent: "15%",
          title: "Conversion Rate",
          subtitle: "visitors convert into leads/customers",
        },
      ],
    },

    desc: "Creatives Matter is a tool connecting social media influencers to brands, streamlining collaboration for impactful campaigns.",
    productGlimpse: {
      glimpseImages: [
        glimpseImg_1,
        glimpseImg_2,
        glimpseImg_3,
        glimpseImg_4,
        glimpseImg_5,
        glimpseImg_6,
      ],
    },

    clientbgColor:
      "linear-gradient(90deg, rgba(152, 203, 255, 0.30) -35.46%, rgba(222, 222, 222, 0.30) 100%)",
    feedback: {
      feedbackCard: [
        {
          cardbgColor: "linear-gradient(90deg, #F9F9F9 0%, #E2EDFA 252.88%)",
          message:
            "I appreciated Growth Loops Tech's patience, knowledge, and willingness to explain things.",
          clientImg: client_CM,
          clientname: "Michael Piedra",
          place: "Founder , Creatives Matter",
          logo: cm,
        },
      ],
    },
  },

  {
    name: "Jaba",
    title:
      "Transforming athlete branding with intuitive, automated solutions for athletes and their teams.",
    bgColor: "#DEFFA2",
    techStacks: [react_icon, python, DB, django],
    bgImage: jaba,
    cards: [
      {
        borderColor: "#87CF11",
        Heading: "Client Vision",
        VisionImg: Vision1,
        Vision: [
          "Provide a personalized chatbot for websites to enhance Admin engagement and Athlete support.",
          "Enable easy creation of chatbots trained on user content with a single click.",
          "Build an web based application and mobile application.",
          "Offer customizable chat widgets to align with the brand's style and website design.",
          // "Simple dashboards for goal setting and tracking",
          // "Deeper insights into objectives, results, and team performance",
        ],
      },
      {
        borderColor: "#87CF11",
        Heading: "Our Execution",
        VisionImg: Vision2,
        Vision: [
          "We built a personalized chatbot for JABA website to enhance Admin engagement and Athlete support.",
          "Enable easy creation of chatbots trained on user content with a single click.",
          "Delivered an web based application and mobile application.",
          "Seamlessly integrated chat widgets that aligns with the brand’s style and website design.",
          // "Cultivate a culture of progress through data-driven insights",
          // "Seamless integration with existing tools and processes",
        ],
      },
    ],
    numberColor: "#DAF70B",
    overViewDesc:
      "JABA is a platform designed to help athletes build and manage their personal brands. It utilizes an AI-powered chatbot to provide support and guidance.",
    overViewCards: [
      {
        num: 1,
        desc: "AI-driven support for maximizing personal brand impact and NIL opportunities, enabling athletes to achieve maximum impact and success in building their personal brands.",
        image: [Viewimg_1],
      },
      {
        num: 2,
        desc: "JABA's intuitive design ensures seamless brand management on any device, empowering athletes to stay connected and informed on the go.",
        image: [Viewimg_2],
      },
      {
        num: 3,
        desc: "24/7 guidance and trend-aware strategies for athletes and administrators alike.",
        image: [Viewimg_3],
      },
    ],

    growthMetrics: {
      rateTextColor: "text-[#D3E83D]",
      rateBorderColor: "border-[#D3E83D]",
      rate: [
        {
          percent: "15%",
          title: "User Growth",
          subtitle: "Monthly user growth rate",
        },
        {
          percent: "90%",
          title: "Customer Satisfaction",
          subtitle: "Satisfaction Rate",
        },
        {
          percent: "25%",
          title: "Engagement Metrics",
          subtitle: "Increase compared to last quarter",
        },
        {
          percent: "15%",
          title: "Conversion Rate",
          subtitle: "visitors convert into leads/customers",
        },
      ],
    },

    desc: "JABA is a platform designed to help athletes build and manage their personal brands. It utilizes an AI-powered chatbot to provide support and guidance.",
    productGlimpse: {
      glimpseImages: [
        glimpse_1,
        glimpse_2,
        glimpse_3,
        glimpse_4,
        glimpse_5,
        glimpse_6,
        glimpse_7,
        glimpse_8,
      ],
    },

    clientbgColor:
      "linear-gradient(90deg, rgba(225, 255, 4, 0.30) -3.51%, rgba(244, 255, 159, 0.30) 100%)",
    feedback: {
      feedbackCard: [
        {
          cardbgColor: "#FDFFF2",
          message:
          "“We started working with Growth Loops Technology from over an year ago and it has been one of the best relationship we ever had and they have become major part of our team, they pay attention to details and work super hard,I could not recommend them more.”",
          clientImg: client_JABA,
          clientname: "Jordon Rooney",
          place: "Founder , Built Different LLC",
          logo: Jaba,
        },
      ],
    },
  },

  {
    name: "Dronetjek",
    title:
      "DroneTjek offers hassle-free inspections for your roof, facade, or property. Let our drones do the work while you enjoy peace of mind.",
    bgColor: "linear-gradient(182deg, #F8FBFF -19.12%, #C7F79E 96.2%)",
    techStacks: [react_icon, python, DB, django],
    bgImage: Dronetjek,
    cards: [
      {
        borderColor: "#8BCB54",
        Heading: "Client Vision",
        VisionImg: Vision1,
        Vision: [
          "The software enables comprehensive building inspections with annotations from entire structures to detailed images of specific parts.",
          "Clients can efficiently manage inspection workflows with the tool's ability to organize projects, properties and building parts, ensuring systematic analysis and annotation.",
          "Users can customize annotation attributes like color, line thickness, damage type, building part, material, and comments for enhanced specificity and relevance.",
          "Clients can expect interactive report, featuring clickable links between pages and overviews, facilitating comprehensive analysis and exploration of inspection findings.",
        ],
      },
      {
        borderColor: "1px solid #CCC",
        Heading: "Our Execution",
        VisionImg: Vision2,
        Vision: [
          "Intuitive Interface Design: We develop a user-friendly interface for comprehensive building inspections with detailed annotations.",
          "Project Management Integration: Integrate project management features to streamline inspection workflows and ensure systematic analysis.",
          "Customizable Annotations: We Implement customizable annotation attributes for enhanced specificity and relevance in inspection reports.",
          "Interactive Reports: We create interactive reports with clickable links for thorough exploration of inspection findings.",
        ],
      },
    ],
    numberColor: "#A5CF81",
    overViewDesc:
      "Through DroneTjek Application, users can efficiently document findings, generate comprehensive reports, and seamlessly navigate through project overviews to detailed images.",
    overViewCards: [
      {
        num: 1,
        desc: "Project Focus:The main focus of the DroneTjek annotation software is to streamline building inspections by enabling users to annotate various building parts with detailed precision.",
        image: DronetjekViewimg_1,
      },
      {
        num: 2,
        desc: "Client-Centric Approach: Revolutionize your building inspections with DroneTjek's comprehensive annotation software. Effortlessly create detailed reports and interactive dashboards for seamless analysis and decision-making.",
        image: DronetjekViewimg_2,
      },
      {
        num: 3,
        desc: "Technical Expertise: Growth Loops Technology Pvt Ltd demonstrated proficiency in AWS configuration, front-end/back-end development, UX/UI design, and AI integration to deliver a complex software solution",
        image: DronetjekViewimg_3,
      },
    ],

    growthMetrics: {
      rateTextColor: "text-[#D3E83D]",
      rateBorderColor: "border-[#D3E83D]",
      rate: [
        {
          percent: "15%",
          title: "User Growth",
          subtitle: "Monthly user growth rate",
        },
        {
          percent: "90%",
          title: "Customer Satisfaction",
          subtitle: "Satisfaction Rate",
        },
        {
          percent: "25%",
          title: "Engagement Metrics",
          subtitle: "Increase compared to last quarter",
        },
        {
          percent: "15%",
          title: "Conversion Rate",
          subtitle: "visitors convert into leads/customers",
        },
      ],
    },

    desc: "develop an annotation tool to facilitate the creation of building inspection, equipped with a robust database for information storage and the capability to generate reports.",
    productGlimpse: {
      glimpseImages: [
        Dronetjekglimpse_1,
        Dronetjekglimpse_2,
        Dronetjekglimpse_3,
        Dronetjekglimpse_4,
        Dronetjekglimpse_5,
        Dronetjekglimpse_6,
      ],
    },

    // clientbgColor:
    //   "linear-gradient(90deg, rgba(225, 255, 4, 0.30) -3.51%, rgba(244, 255, 159, 0.30) 100%)",
    // feedback: {
    //   feedbackCard: [
    //     {
    //       cardbgColor: "#FDFFF2",
    //       message:
    //       "“We have been working with Growth Loops Technology over an year ago and it has been the best relationship we had and they have become major part of our team, they pay attention to details and work super hard,I could not recommend them more.”",
    //       clientImg: client_JABA,
    //       clientname: "Jordon Rooney",
    //       place: "Founder , Built Different LLC",
    //       logo: Jaba,
    //     },
    //   ],
    // },
  },

  {
    name: "Rapiq",
    title:
      "Connecting clients with agencies seamlessly through AI-driven matchmaking.",
    bgColor: "linear-gradient(178deg, #F8FBFF -13.6%, #96C3FF 83.64%)",
    techStacks: [react_icon, python, DB, django],
    bgImage: Rapiq,
    cards: [
      {
        borderColor: "#004AAD",
        Heading: "Client Vision",
        VisionImg: Vision1,
        Vision: [
          "Eliminate the guesswork in agency selection by leveraging AI.",
          "Streamline the scoping process by automatically generating detailed project plans, timelines, and budgets, ensuring both parties are aligned.",
          "Foster transparent collaboration by providing a centralized platform for communication, file sharing, and progress tracking.",
          "Mitigate project risks through data-driven insights and predictive analytics, minimizing the chances of delays and budget overruns.",
        ],
      },
      {
        borderColor: "#6CE5E8",
        Heading: "Our Execution",
        VisionImg: Vision2,
        Vision: [
          "AI-Powered Matching Algorithm: We developed a sophisticated algorithm that analyzes client project briefs and agency profiles to identify the most suitable matches.",
          "Automated Project Scoping: Our platform leverages AI to automatically generate detailed project plans, including milestones, timelines, and estimated budgets.",
          "Collaboration Workspace: We built a user-friendly workspace that features real-time messaging, file sharing, task management, and progress-tracking tools.",
          "Data Analytics and Insights: We integrated analytics capabilities into the platform, providing clients and agencies with valuable insights into project performance, potential risks, and opportunities for optimization.",
        ],
      },
    ],
    numberColor: "#42A0FF",
    overViewDesc:
      "Through Rapiq Application, users can efficiently document findings, generate comprehensive reports, and seamlessly navigate through project overviews to detailed images.",
    overViewCards: [
      {
        num: 1,
        desc: "Project Focus:we successfully integrated three distinct service models—Freelance, Agency, and Freelance & Agency—into our software platform, mirroring the diverse needs of our clients and providing tailored solutions to accommodate a wide range of users and businesses.",
        image: RapiqViewimg_1,
      },
      {
        num: 2,
        desc: "Client-Centric Approach: Emphasize the benefits and enhanced functionality of the web application due to AI integration, such as improved user experience, enhanced efficiency, and increased performance.",
        image: RapiqViewimg_2,
      },
      {
        num: 3,
        desc: "Technical Expertise: Growth Loops Technology Pvt Ltd demonstrated proficiency in AWS configuration, front-end/back-end development, UX/UI design, and AI integration to deliver a complex software solution",
        image: RapiqViewimg_3,
      },
    ],

    growthMetrics: {
      rateTextColor: "text-[#D3E83D]",
      rateBorderColor: "border-[#D3E83D]",
      rate: [
        {
          percent: "15%",
          title: "User Growth",
          subtitle: "Monthly user growth rate",
        },
        {
          percent: "90%",
          title: "Customer Satisfaction",
          subtitle: "Satisfaction Rate",
        },
        {
          percent: "25%",
          title: "Engagement Metrics",
          subtitle: "Increase compared to last quarter",
        },
        {
          percent: "15%",
          title: "Conversion Rate",
          subtitle: "visitors convert into leads/customers",
        },
      ],
    },

    desc: "Rapiq is a platform designed to help athletes build and manage their personal brands. It utilizes an AI-powered chatbot to provide support and guidance.",
    productGlimpse: {
      glimpseImages: [
        Rapiqglimpse_1,
        Rapiqglimpse_2,
        Rapiqglimpse_3,
        Rapiqglimpse_4,
        Rapiqglimpse_5,
        Rapiqglimpse_6,
        Rapiqglimpse_7,
        Rapiqglimpse_8,
        Rapiqglimpse_9,
        Rapiqglimpse_10,
      ],
    },

    // clientbgColor:
    //   "linear-gradient(90deg, rgba(225, 255, 4, 0.30) -3.51%, rgba(244, 255, 159, 0.30) 100%)",
    // feedback: {
    //   feedbackCard: [
    //     {
    //       cardbgColor: "#FDFFF2",
    //       message:
    //       "“We have been working with Growth Loops Technology over an year ago and it has been the best relationship we had and they have become major part of our team, they pay attention to details and work super hard,I could not recommend them more.”",
    //       clientImg: client_JABA,
    //       clientname: "Jordon Rooney",
    //       place: "Founder , Built Different LLC",
    //       logo: Jaba,
    //     },
    //   ],
    // },
  },

  {
    name: "Simply Fema",
    title:
      "Connecting clients with agencies seamlessly through AI-driven matchmaking.",
    bgColor: "linear-gradient(178deg, #F8FBFF -13.6%, #96C3FF 83.64%)",
    techStacks: [react_icon, python, DB, django],
    bgImage: SF,
    cards: [
      {
        borderColor: "#004AAD",
        Heading: "Client Vision",
        VisionImg: Vision1,
        Vision: [
          "Eliminate the guesswork in agency selection by leveraging AI.",
          "Streamline the scoping process by automatically generating detailed project plans, timelines, and budgets, ensuring both parties are aligned.",
          "Foster transparent collaboration by providing a centralized platform for communication, file sharing, and progress tracking.",
          "Mitigate project risks through data-driven insights and predictive analytics, minimizing the chances of delays and budget overruns.",
        ],
      },
      {
        borderColor: "#6CE5E8",
        Heading: "Our Execution",
        VisionImg: Vision2,
        Vision: [
          "AI-Powered Matching Algorithm: We developed a sophisticated algorithm that analyzes client project briefs and agency profiles to identify the most suitable matches.",
          "Automated Project Scoping: Our platform leverages AI to automatically generate detailed project plans, including milestones, timelines, and estimated budgets.",
          "Collaboration Workspace: We built a user-friendly workspace that features real-time messaging, file sharing, task management, and progress-tracking tools.",
          "Data Analytics and Insights: We integrated analytics capabilities into the platform, providing clients and agencies with valuable insights into project performance, potential risks, and opportunities for optimization.",
        ],
      },
    ],
    numberColor: "#42A0FF",
    overViewDesc:
      "Through Simply Fema Application, users can efficiently document findings, generate comprehensive reports, and seamlessly navigate through project overviews to detailed images.",
    overViewCards: [
      {
        num: 1,
        desc: "Project Focus:we successfully integrated three distinct service models—Freelance, Agency, and Freelance & Agency—into our software platform, mirroring the diverse needs of our clients and providing tailored solutions to accommodate a wide range of users and businesses.",
        image: SimplyFemaViewimg_1,
      },
      {
        num: 2,
        desc: "Client-Centric Approach: Emphasize the benefits and enhanced functionality of the web application due to AI integration, such as improved user experience, enhanced efficiency, and increased performance.",
        image: SimplyFemaViewimg_2,
      },
      {
        num: 3,
        desc: "Technical Expertise: Growth Loops Technology Pvt Ltd demonstrated proficiency in AWS configuration, front-end/back-end development, UX/UI design, and AI integration to deliver a complex software solution",
        image: SimplyFemaViewimg_3,
      },
    ],

    growthMetrics: {
      rateTextColor: "text-[#D3E83D]",
      rateBorderColor: "border-[#D3E83D]",
      rate: [
        {
          percent: "15%",
          title: "User Growth",
          subtitle: "Monthly user growth rate",
        },
        {
          percent: "90%",
          title: "Customer Satisfaction",
          subtitle: "Satisfaction Rate",
        },
        {
          percent: "25%",
          title: "Engagement Metrics",
          subtitle: "Increase compared to last quarter",
        },
        {
          percent: "15%",
          title: "Conversion Rate",
          subtitle: "visitors convert into leads/customers",
        },
      ],
    },

    desc: "Simply Fema is a platform designed to help athletes build and manage their personal brands. It utilizes an AI-powered chatbot to provide support and guidance.",
    productGlimpse: {
      glimpseImages: [
        SimplyFemaglimpse_1,
        SimplyFemaglimpse_2,
        SimplyFemaglimpse_3,
        SimplyFemaglimpse_4,
        SimplyFemaglimpse_5,
        SimplyFemaglimpse_6,
      ],
    },

    // clientbgColor:
    //   "linear-gradient(90deg, rgba(225, 255, 4, 0.30) -3.51%, rgba(244, 255, 159, 0.30) 100%)",
    // feedback: {
    //   feedbackCard: [
    //     {
    //       cardbgColor: "#FDFFF2",
    //       message:
    //       "“We have been working with Growth Loops Technology over an year ago and it has been the best relationship we had and they have become major part of our team, they pay attention to details and work super hard,I could not recommend them more.”",
    //       clientImg: client_JABA,
    //       clientname: "Jordon Rooney",
    //       place: "Founder , Built Different LLC",
    //       logo: Jaba,
    //     },
    //   ],
    // },
  },
];
