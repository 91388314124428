import React from "react";
import PropTypes from "prop-types";
import LeftArrow from "../assets/icons/AboutUsIcons/LeftArrow.svg";
import RightArrow from "../assets/icons/AboutUsIcons/RightArrow.svg";

function PreviousNextMethods({ sliderRef }) {
  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="flex space-x-6 justify-center mt-2">
      <img
        src={LeftArrow}
        alt="Previous"
        className="h-8 w-8 text-green-500 cursor-pointer"
        onClick={previous}
      />
      <img
        src={RightArrow}
        alt="Next"
        className="h-8 w-8 text-green-500 cursor-pointer"
        onClick={next}
      />
    </div>
  );
}

PreviousNextMethods.propTypes = {
  sliderRef: PropTypes.object.isRequired,
};

export default PreviousNextMethods;
