import React, { useEffect } from "react";
// import Footer_bg from "../assets/images/Footer_bg.svg";
import ItemsContainer from "./ItemsContainer";
import button_icon1 from "../assets/icons/button_icon1.svg";
import SocialIcons from "./SocialIcons";
import { Icons } from "./Menus";
import { Link, animateScroll } from "react-scroll";

const Footer = () => {
  const footerStyle = {
    // backgroundImage: `url(${Footer_bg})`,
    background:
      "linear-gradient(90deg, rgba(12, 58, 240, 0.10) 0%, rgba(12, 58, 239, 0.10) 0.01%, rgba(136, 211, 252, 0.10) 100%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://widget.clutch.co/static/js/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <footer className="text-[#000F40]" style={footerStyle}>
      <div className="mx-[10vh]">
        <div className="md:flex md:justify-between md:items-center sm:px-12 px-4 py-7">
          <h1 className="md:text-3xl text-xl md:mb-0 mb-6 lg:leading-normal md:w-2/3 font-semibold">
            Launch your product under 90 days?
          </h1>
          <Link
            activeClass="active"
            to="Home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={1000}
          >
            <div>
              <button
                className="btn-gradient-2 button"
                style={{
                  borderWidth: "3px",
                  padding: "0.6rem 2rem",
                }}
                onClick={() => animateScroll.scrollToTop()}
              >
                Let's Connect
                <img src={button_icon1} alt="Button Icon" className="ml-2" />
              </button>
            </div>
          </Link>
        </div>
        <hr className="border-[#868686] mx-[6vh] w-full sm:w-[80rem]" />

        <ItemsContainer />
        <div className="flex justify-end">
          <div
            className="clutch-widget"
            data-url="https://widget.clutch.co"
            data-widget-type="1"
            data-height="40"
            data-nofollow="true"
            data-expandifr="true"
            data-scale="100"
            data-clutchcompany-id="2287707"
          />
        </div>
        <hr className="border-[#868686] mx-[6vh] w-full sm:w-[80rem]" />
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-4 text-center pt-2 text-gray-400 text-sm pb-8">
          <span className="sm:col-span-1 lg:col-span-3">Follow Us on</span>

          <div className="sm:col-span-1 lg:col-span-3">
            <SocialIcons Icons={Icons} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
