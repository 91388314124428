import React, { useState, useMemo, useEffect } from "react";
import baseUrl from "../utils/config";
import Modal from "./SuccessModal";
import countryList from "react-select-country-list";

const CustomForm = () => {
  const [country, setCountry] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [urlParams, setUrlParams] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });
  const initialFormData = {
    name: "",
    email: "",
    message: "",
    phone: "",
  };

  useEffect(() => {
    const defaultCountry = options.find(
      (option) => option.label === "United States"
    );
    if (defaultCountry) {
      setCountry(defaultCountry);
    }
  }, [options]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of queryParams.entries()) {
      params[key] = value;
    }
    setUrlParams(params);
  }, []);

  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption);
    // console.log(`Selected: ${selectedOption.label}`);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("from", formData.email);
      formDataToSend.append(
        "subject",
        `Query from Client ${formData.name} ${formData.email}`
      );
      formDataToSend.append("phone", formData.phone);
      formDataToSend.append("country", country.label);
      formDataToSend.append("content", formData.message);
      for (const [key, value] of Object.entries(urlParams)) {
        formDataToSend.append(key, value);
      }

      // console.log("Form Data:", formDataToSend);

      const url = baseUrl + "/lead";
      const response = await fetch(url, {
        method: "POST",
        body: formDataToSend,
      });

      if (response.ok) {
        setIsSubmitted(true);
        setFormData(initialFormData);
      } else {
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const closeModal = () => {
    setIsSubmitted(false);
  };

  return (
    <div className="">
      <form
        id="contactForm1"
        className="space-y-2 max-w-xl mx-auto"
        onSubmit={handleSubmit}
      >
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          className="bg-[#F9F9F9] appearance-none border border-[#E4E4E4] rounded w-full py-3 px-3 text-[#595F66] leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Full Name*"
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          className="bg-[#F9F9F9] appearance-none border border-[#E4E4E4] rounded w-full py-3 px-3 text-[#595F66] leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Email*"
        />
        <div className="relative">
          <label htmlFor="InputPhone" className="sr-only">
            Phone no.
          </label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            className="bg-[#F9F9F9] appearance-none border border-[#E4E4E4] rounded w-full py-3 px-3 text-[#595F66] leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Phone(Optional)"
          />
        </div>
        {/* <Select
          id="countrySelect"
          className="w-full px-0 border-b border-gray-300"
          options={options}
          value={country}
          onChange={handleCountryChange}
        /> */}
        <textarea
          name="message"
          value={formData.message}
          onChange={handleInputChange}
          className="bg-[#F9F9F9] appearance-none border border-[#E4E4E4] rounded w-full py-2 px-3 text-[#595F66] leading-tight focus:outline-none focus:shadow-outline h-36 resize-none"
          placeholder="Brief Project Needs"
          rows="4"
        ></textarea>
        <button
          type="submit"
          className="w-full text-lg lg:text-xl rounded-full bg-[#091D62] text-white p-3 hover:bg-green-600 transition duration-300 ease-in-out"
        >
          Book a Meeting
        </button>
      </form>

      <Modal isOpen={isSubmitted} onClose={closeModal} />
    </div>
  );
};

export default CustomForm;
