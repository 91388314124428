import React from "react";
import Jaba from "../assets/images/CaseStudies/JABA/Jaba_logo.png";
import Star from "../assets/images/5 star.png";
import textImg from "../assets/images/text_img.svg";

const TestimonialVideo = () => {
  // Constants for dynamic text content
  const clientName = "Jordon Rooney";
  const clientTitle = "Founder, Built Different LLC";
  const message =
  "“We have been working with Growth Loops Technology from over an year ago and it has been one of the best relationship we ever had and they have become major part of our team, they pay attention to details and work super hard,I could not recommend them more.”"
  const iframeSrc = "https://player.vimeo.com/video/938482262?h=e4163a25d7";

  const bgColor =
    "linear-gradient(90deg, rgba(225, 255, 4, 0.30) -3.51%, rgba(244, 255, 159, 0.30) 100%)";

  return (
    <div className="mt-16" style={{ background: bgColor }}>
      <div className="px-6 py-8 text-center">
        <h3 className="text-[#002298] text-[16px] md:text-2xl lg:text-2xl font-medium">
          Testimonials
        </h3>
        <div className="flex justify-center items-center relative">
          <h1 className="text-xl md:text-3xl lg:text-3xl font-semibold">
            What our Customers love about us
          </h1>
          <img
            src={textImg}
            alt="Button Icon"
            className="h-6 ml-2 md:ml-4 absolute top-0 right-0 md:right-[28rem]"
          />
        </div>
      </div>
      <div className="border rounded-xl py-4 px-7 shadow-lg mx-4 md:mx-[15rem] bg-[#FDFFF2]">
        <div className="flex flex-col md:flex-row justify-between  md:space-x-16">
          <div className="flex flex-col justify-between md:space-y-28 md:w-1/2">
            <p className="text-[16px] text-[#4E4E4E] font-semibold mt-8 md:mt-28">
              {message}
            </p>
            <div className="flex items-start mt-4 md:space-x-7">
              <div>
                <h2 className="text-[#000F40] text-xl md:text-2xl font-semibold">
                  {clientName}
                </h2>
                <h3 className="text-[#4E4E4E] text-[14px] font-semibold">
                  {clientTitle}
                </h3>
              </div>
              <div>
                <img src={Jaba} alt="Jaba Logo" className="h-12 " />
                <img src={Star} alt="Star" className="h-3 mx-auto" />
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 mt-6 md:mt-0">
            {/* Testimonial video */}
            <iframe
              src={iframeSrc}
              className="h-[300px] md:h-[450px] w-full object-cover"
              frameBorder="0"
              allow="autoplay; fullscreen"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialVideo;
