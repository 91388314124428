import React from 'react';
import client1 from "../assets/images/CaseStudies/imongu/client.png";
import client from "../assets/images/CaseStudies/HappyChat/client.png";
import client_CM from "../assets/images/CaseStudies/CM/client.png";
import client_JABA from "../assets/images/CaseStudies/JABA/client.png";
import semi from "../assets/images/semi_col.png";
import backgorund_loop from "../assets/images/hero backgorund loop.png";

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      clientImg: client_CM,
      clientname: "Michael Piedra",
      place: "CEO, Creatives Matter LLC",
      content:
        "The team was patient and knowledgeable, and their work exceeded the client’s expectations...",
      message: "“Growth Loops Technology Pvt Ltd delivered a functional application and fixed bugs diligently. The team was patient and knowledgeable, and their work exceeded the client’s expectations. The team delivered work ahead of schedule and met the project’s milestones, being responsive to the client.”",
      date: "September 3, 2023",
      rating: 5
    },
  {
    id: 2,
    clientImg: client_JABA,
    clientname: "Jordon Rooney",
    place: "Founder , Built Different LLC",
    content:
      "Team is Hard Working, Good communicator..",
    message:
    "“We started working with Growth Loops Technology from over an year ago and it has been one of the best relationship we ever had and they have become major part of our team, they pay attention to details and work super hard,I could not recommend them more.”",
        
    date: "Mar 15, 2024",
    rating: 5
   },
    {
      id: 3,
      clientImg: client,
      clientname: "JC Acevedo",
      place: "CEO, HappyChat AI",
      content:
        "They managed the project well...",
      message: "“The delivered chatbot and web app align with the client's expectations, enabling them to launch a functional MVP successfully. Growth Loops Technology Pvt Ltd managed the project well — they addressed queries and concerns promptly and always adhered to deadlines. They were also open to feedback.”",
      date: "August 15, 2023",
      rating: 5
    },
    {
      id: 4,
      clientImg: client1,
      clientname: "Agness Mnena (Allagh)",
      place: "CEO, iMongu",
      content:
        "The team took ownership of the client’s product and delivered work on time...",
      message: "“Growth Loops Technology Pvt Ltd work exceeded the client’s expectations. The team was warm and involved in the project. The team took ownership of the client’s product and delivered work on time, having direct communication with the client via email and virtual meetings.”",
      date: "Jan 25, 2024",
      rating: 5
    },
    
  ];

  const renderStars = (rating) => (
    [...Array(rating)].map((_, i) => <span key={i} className="text-yellow-500">★</span>)
  );

  return (
    <div className="bg-gray-100 py-16">
      <div className="text-start mb-10 px-6 md:px-20">
        <h2 className='text-[#002298] text-lg font-semibold'>Testimonials</h2>
        <h1 className="text-2xl font-bold">Reviews that Speak Volumes</h1>
        <p className="text-[#4E4E4E]">Don’t just take our word for it, check out what others have to say about their experiences with us.</p>
      </div>
      <div className="flex flex-col px-6 md:px-28">
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="relative bg-[#FFF] py-8 md:py-20 px-6 md:px-16 my-8 rounded-lg shadow-lg flex flex-col md:flex-row items-start gap-4 w-full max-w-7xl mb-6">
            <img src={semi} alt="Quotation Mark" className="absolute -top-5 right-28 w-14 h-10" />
            <div className="absolute top-[15rem] md:top-8 md:left-1/3">
              <img src={backgorund_loop} alt="background loop" className="h-[150px] md:h-[240px] blur-[15px] rotate-3" />
            </div>
            <div className="flex-shrink-0 flex items-center">
              <img src={testimonial.clientImg} alt={`${testimonial.clientname}'s image`} className="w-20 h-20 border-4 p-0.5 bg-blue-200 rounded-full" />
              <div className="ml-4">
                <h3 className="text-[18px] font-semibold text-[#000F40]">{testimonial.clientname}</h3>
                <p className="text-[#4E4E4E] text-[14px]">{testimonial.place}</p>
              </div>
            </div>
            <div className="flex-grow mt-4 md:mt-0">
              <h4 className="text-xl text-[#000F40] font-semibold">{testimonial.content}</h4>
              <div className="flex items-center mt-2">
                {renderStars(testimonial.rating)}
                <span className="text-gray-500 ml-2">{testimonial.date}</span>
              </div>
              <p className="mt-4 text-[#4E4E4E]">{testimonial.message}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
