import React from "react";
import text_img from "../assets/images/text_img.svg";
import Timeline1 from "../assets/images/Timeline1.svg";
// import Timeline_sm from "../assets/images/Timeline_sm.svg";
import Rectangle from "../assets/images/Rectangle.svg";

const CurveTimeline = () => {
  return (
    <>
      <div className="relative mx-[8vh] my-10  md:my-[20vh]">
        <div className="text-xl md:text-3xl font-semibold mt-0 md:mt-[50vh] flex gap-1">
          <h1>
            We have a simple, yet
            <span className="text-[#002298]"> Bulletproof</span>
            <br />
            and <span className="text-[#002298]"> Effective </span>
            process
          </h1>
          <img
            src={text_img}
            alt="Button Icon"
            className="h-6 hidden lg:block"
          />
        </div>
        <img
          src={Timeline1}
          alt="Service"
          className="h-[50rem] w-[90rem] mt-[-50vh] absolute md:block hidden"
        />
        {/* <img
          src={Timeline_sm}
          alt="Service"
          className="h-[40rem] mt-[-20vh] absolute sm:block md:hidden"
        /> */}
        <div className="sm:block md:hidden absolute">
          <div className="absolute shadow p-2 rounded-full top-[12%] left-0 transform -translate-x-1/2 -translate-y-1/2">
            <div className="h-3 w-3 rounded-full bg-gradient-to-b from-[#EEFFEE] to-[#18BF18]" />
          </div>

          <div className="absolute shadow p-2 rounded-full top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2">
            <div className="h-3 w-3 rounded-full bg-gradient-to-b from-[#EEFFEE] to-[#FFA500] " />
          </div>
          <div className="absolute shadow p-2 rounded-full bottom-0 left-0 transform -translate-x-1/2 -translate-y-1/2">
            <div className="h-3 w-3 rounded-full bg-gradient-to-b from-[#EEFFEE] to-[#800080] " />
          </div>
          <img src={Rectangle} alt="Service" className="h-[19rem] mt-[1rem]" />
        </div>
        <div className="flex flex-col md:flex-row justify-between">
          <div className="md:order-1 md:ml-[8vh] w-full md:w-[400px] mt-6 md:mt-[21rem] px-6">
            <h2 className="font-semibold text-[#DE65A6] text-[18px]">
              Discovery Call
            </h2>
            <p className="text-[13px] text-[#000F40] font-light">
              We will setup a 30 mins discovery call to understand your vision and requirement.
            </p>
          </div>
          <div className="md:order-2 w-full  md:w-[400px] md:ml-[28vh] mt-6 md:mt-[16rem] px-6">
            <h2 className="font-semibold text-[#0FA17B] text-[18px]">
              Development & Execution
            </h2>
            <p className="text-sm text-[#000F40] font-light">
              Our skilled developers, designers, and AI specialists come
              together to craft your software solution.
            </p>
          </div>
          <div className="md:order-3 md:mr-[2vh] w-full md:w-[400px] mt-6 md:-mt-32 px-6">
            <h2 className="font-semibold text-[#E87B7B] text-[18px]">
              Launch & Scale
            </h2>
            <p className="text-sm text-[#000F40] font-light">
              Meticulously test, deploy, and support your product for a seamless
              user experience and scalability.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurveTimeline;
