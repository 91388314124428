import React from "react";

const Error = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-red-500 text-5xl">404 Error Page</h1>
      <p className="text-blue-500 text-xl">Page not found</p>
    </div>
  );
};

export default Error;
