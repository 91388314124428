import React, { useState } from "react";
import HappyChat from "../assets/images/happychat.png";
import Upwork from "../assets/images/upwork-1.svg";
import imongu from "../assets/images/imongu2.svg";
import Rapiq from "../assets/images/rapiq_logo.png";
import SimplyFema from "../assets/images/Simply_Fema.png";
import CM from "../assets/images/cm_logo.png";
import Jaba from "../assets/images/jaba.png";
import Dronetek from "../assets/images/Dronetek.svg";

import HappyChat_img from "../assets/images/CaseStudies/HappyChat_img.png";
import Simplyfema_img from "../assets/images/CaseStudies/Simplyfema_img.png";
import CM_img from "../assets/images/CaseStudies/CM_img.png";
import imongu_img from "../assets/images/CaseStudies/imongu_img.png";
import rapiq_img from "../assets/images/CaseStudies/rapiq_img.png";
import dronetjek_img from "../assets/images/CaseStudies/dronetjek_img.png";
// import Upwork_img from "../assets/images/CaseStudies/Upwork_img.png";
import jaba from "../assets/images/CaseStudies/jaba.png";

import { NavLink } from "react-router-dom";
import arrow_up from "../assets/images/AboutUs/arrow-up.svg";

const ProjectNames = () => {
  
  const [visibleProjects, setVisibleProjects] = useState(6);
  const [projects] = useState([
    {
      image: HappyChat_img,
      logo: HappyChat,
      ItemName: "AI Integrated Chatbot Software",
      Skills: "ReactJS, Figma, Python/Flask, AWS, DynamoDB",
      border: "#FFEBCD",
      background: "#FFFEFC",
      route: "/case-studies/happychat",
    },
    {
      image: imongu_img,
      logo: imongu,
      ItemName: "iMongu - OKR Management Software",
      Skills: "ReactJS, Figma, Python/Django, AWS, Postgres",
      border: "#FFD9EE",
      background: "#FFFCFE",
      route: "/case-studies/imongu",
    },

    {
      image: CM_img,
      logo: CM,
      ItemName: "Creatives Matters LLC - AI driven CRM for marketers",
      Skills: "ReactJS, Figma, Python/Django, AWS, OpenAI, Postgres",
      border: "#CBE0FF",
      background: "#FBFCFF",
      route: "/case-studies/creativesMatter",
    },
    {
      image: jaba,
      logo: Jaba,
      ItemName: "Built Different LLC - AI branch Coach for athlethes",
      Skills: "React Native, ReactJS, Figma, Python/FastAPI, AWS, Langchain Agents, MySql",
      border: "#FFD9D9",
      background: "#FFFCFC",
      route: "/case-studies/jaba",
    },
    {
      image: rapiq_img,
      logo: Rapiq,
      ItemName: "Rapiq LLC - Marketplace for Clients and Agencies",
      Skills: "ReactJS, SocketIO, Figma, Python/Django, AWS, OpenAI, ClaudeAPI, Zapier, MySql",
      border: "#D4DDFF",
      background: "#F9FBFF",
      route: "/case-studies/rapiq",
    },
    {
      image: dronetjek_img,
      logo: Dronetek,
      ItemName: "Drone-Tjek Pvt Ltd - Image Annotation tool, Postgres",
      Skills: "ReactJS, Figma, Python/Django",
      border: "#BCFF94",
      background: "#FCFFFA",
      route: "/case-studies/DroneTjek",
    },
    {
      image: Simplyfema_img,
      logo: SimplyFema,
      ItemName: "Unravel Technologies Pvt Ltd - Foreign Exchange Compliance Platform",
      Skills: "ElectronJS,Nextjs,Formik, Figma, Python/Django, Firebase, rxDB",
      border: "#BCFF94",
      background: "#FCFFFA",
      route: "/case-studies/simplyfema",
    },
  ]);


  const handleLoadMore = () => {
    setVisibleProjects(prev => prev + 6);
  };
  return (
    <>
    <div className="flex flex-wrap justify-center">
      {projects.slice(0, visibleProjects).map((project, index) => (
        <div key={index} className="m-4 flex justify-center">
          <div className="rounded-xl p-8 h-full w-[300px] md:w-[400px] lg:w-[400px] shadow-lg border">
            <img src={project.image} alt={project.Itemimg} className="mb-4" />
            <div className="flex flex-row gap-0.5 items-center mb-4">
              <img src={project.logo} alt={project.logo} className="h-10" />
              {project.text && <p className="text-xl">{project.text}</p>}
            </div>
            <h2 className="text-[18px] text-[#07217D] font-semibold mb-2">{project.ItemName}</h2>
            <p className="text-[#383838] text-sm mb-3">{project.ItemType}</p>
            <p className="text-[#383838] text-sm">
              <span className="text-[#07217D]">Technology Stack:</span> {project.Skills}
            </p>
            <div className="flex justify-center mt-8">
                <NavLink
                  to={project.route}
                  target="_blank"
                  className="text-[#091D62] border-[#091D62] border rounded-full py-1.5 pl-2 md:pl-5 pr-2 relative flex items-center space-x-1 md:space-x-2 cursor-pointer z-10 button-container"
                >
                  <span className="">View Case Studies</span>
                  <div className="bg-[#5AC4FE] rounded-full p-0.5 md:p-1">
                    <img src={arrow_up} alt="arrow_up" className="h-4 md:h-6 arrow-container" />
                  </div>
                </NavLink>
              </div>
          </div>
        </div>
      ))}
    </div>
      {visibleProjects < projects.length && (
        <div className="flex justify-center mt-8">
          <button
            className="text-[#091D62] border-[#091D62] border rounded-full py-1.5 pl-2 md:pl-5 pr-2 relative flex items-center space-x-1 md:space-x-2 cursor-pointer z-10"
            onClick={handleLoadMore}
          >
            <span className="">Load More</span>
            <div className="bg-[#5AC4FE] rounded-full p-0.5 md:p-1">
              <img src={arrow_up} alt="arrow_up" className="h-4 md:h-6" />
            </div>
          </button>
        </div>
      )}
    </>
  );
};

export default ProjectNames;


// import React from "react";
// import HappyChat from "../assets/images/happychat.png";
// import Upwork from "../assets/images/upwork-1.svg";
// import imongu from "../assets/images/imongu2.svg";
// import Rapiq from "../assets/images/rapiq_logo.png";
// import SimplyFema from "../assets/images/Simply_Fema.png";
// import CM from "../assets/images/cm_logo.png";
// import Jaba from "../assets/images/jaba.png";
// import Dronetek from "../assets/images/Dronetek.svg";

// import HappyChat_img from "../assets/images/CaseStudies/HappyChat_img.png";
// import Simplyfema_img from "../assets/images/CaseStudies/Simplyfema_img.png";
// import CM_img from "../assets/images/CaseStudies/CM_img.png";
// import imongu_img from "../assets/images/CaseStudies/imongu_img.png";
// import rapiq_img from "../assets/images/CaseStudies/rapiq_img.png";
// import dronetjek_img from "../assets/images/CaseStudies/dronetjek_img.png";
// // import Upwork_img from "../assets/images/CaseStudies/Upwork_img.png";
// import jaba from "../assets/images/CaseStudies/jaba.png";

// import { NavLink } from "react-router-dom";
// import arrow_up from "../assets/images/AboutUs/arrow-up.svg";

// const ProjectNames = () => {
//   const projecItems = [
//     {
//       image: HappyChat_img,
//       logo: HappyChat,
//       ItemName: "AI Integrated Chatbot Software",
//       Skills: "React, Figma, Python/Flask, AWS",
//       border: "#FFEBCD",
//       background: "#FFFEFC",
//       route: "/case-studies/happychat",
//     },
//     {
//       image: imongu_img,
//       logo: imongu,
//       ItemName: "iMongu - Goal Management Software",
//       Skills: "React, Figma, Python/Django, AWS",
//       border: "#FFD9EE",
//       background: "#FFFCFE",
//       route: "/case-studies/imongu",
//     },

//     {
//       image: CM_img,
//       logo: CM,
//       text: "Creatives Matter",
//       ItemName: "Creatives Matter - A Robust CRM Tool",
//       Skills: "React, Figma, Python/Djnago, AWS",
//       border: "#CBE0FF",
//       background: "#FBFCFF",
//       route: "/case-studies/creativesMatter",
//     },
//     {
//       image: jaba,
//       logo: Jaba,
//       // text: "Jaba",
//       ItemName: "Jaba",
//       ItemType:
//         "Immerse ourselves in your vision, objectives, and audience to lay the foundation for success.",
//       Skills: "React, Figma, Python/FastAPI, AWS",
//       border: "#FFD9D9",
//       background: "#FFFCFC",
//       route: "/case-studies/jaba",
//     },
//     {
//       image: rapiq_img,
//       logo: Rapiq,
//       ItemName: "Rapiq",
//       Skills: "React, Figma, Python/FastAPI, AWS",
//       border: "#D4DDFF",
//       background: "#F9FBFF",
//       route: "/case-studies/rapiq",
//     },
//     {
//       image: dronetjek_img,
//       logo: Dronetek,
//       // text: "DroneTjek",
//       ItemName: "DroneTjek",
//       Skills: "React, Figma, Python/Django",
//       border: "#BCFF94",
//       background: "#FCFFFA",
//       route: "/case-studies/DroneTjek",
//     },
//     {
//       image: Simplyfema_img,
//       logo: SimplyFema,
//       text: "Simply Fema",
//       ItemName: "Simplyfema",
//       Skills: "React, Figma, Python/Django",
//       border: "#BCFF94",
//       background: "#FCFFFA",
//       route: "/simplyfema-case-study",
//     },
//   ];
//   return (
//     <div className="flex flex-wrap justify-center">
//       {projecItems.map((project, index) => (
//         <div key={index} className="m-4 ">
//           <div
//             className="rounded-xl p-8 h-full w-[300px] md:w-[450px] lg:w-[450px] shadow-lg border"
//             // style={{
//             //   border: `1px solid ${project.border}`,
//             //   background: project.background,
//             // }}
//           >
//             <img src={project.image} alt={project.Itemimg} className=" mb-4" />
//             <div
//               key={index}
//               className="flex flex-row gap-0.5 items-center mb-4"
//             >
//               <img src={project.logo} alt={project.logo} className="h-10" />
//               {project.text && <p className="text-xl">{project.text}</p>}
//             </div>
//             <h2 className="text-[18px] text-[#07217D] font-semibold mb-2">
//               {project.ItemName}
//             </h2>
//             <p className="text-[#383838] text-sm mb-3">{project.ItemType}</p>
//             <p className="text-[#383838] text-sm">
//               <span className="text-[#07217D]">Skills:</span> {project.Skills}
//             </p>
//             <div className="flex items-end mt-10">
//               <NavLink
//                 to={project.route}
//                 target="_blank"
//                 className="text-[#091D62] border-[#091D62] border rounded-full py-1.5 pl-2 md:pl-5 pr-2 relative flex items-center space-x-1 md:space-x-2 cursor-pointer z-10"
//               >
//                 <span className="">View Case Studies</span>
//                 <div className="bg-[#5AC4FE] rounded-full p-0.5 md:p-1">
//                   <img src={arrow_up} alt="arrow_up" className="h-4 md:h-6" />
//                 </div>
//               </NavLink>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
    
//   );
// };

// export default ProjectNames;
