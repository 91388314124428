import React from "react";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Modal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleContinue = () => {
    onClose();
    navigate("/"); // Navigate to the home page
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 w-full max-w-md relative">
        <button
          className="absolute top-0 right-0 mt-2 mr-2 text-gray-500 hover:text-red-500 transition duration-200 ease-in-out"
          onClick={onClose}
        >
          <MdClose size={24} />
        </button>
        <div className="py-4 sm:py-6" role="alert">
          Thank you for reaching out to us. We appreciate your interest and will
          get back to you as soon as possible.
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md w-full"
          onClick={handleContinue}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Modal;
