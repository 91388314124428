import React from "react";

const ClientFeedback = ({ caseStudy }) => {
  return (
    <div
      className="w-full min-h-[80vh] py-16 my-20 relative"
      style={{ background: caseStudy?.clientbgColor }}
    >
      <h1 className="text-2xl sm:text-3xl text-[#5c381f] font-semibold text-center sm:text-left ml-4 sm:ml-10 py-4 sm:py-10">
        Our Client Feedback
      </h1>
      {caseStudy?.feedback?.feedbackCard?.map((feedback, index) => (
        <div
          key={index}
          className="relative border rounded-xl py-8 px-4 sm:px-7 shadow-lg mx-4 sm:mx-10 lg:mx-[15rem] my-10 sm:my-16 bg-white"
          style={{ background: feedback?.cardbgColor }}
        >
          <div className="flex flex-col sm:flex-row justify-between relative items-center">
            <div className="flex flex-col justify-between sm:mr-4 relative">
              <span className="absolute -top-44 -left-4 text-[400px] text-[#c8e943] opacity-25">“</span>
              <p className="sm:text-lg md:text-[16px] font-semibold text-[#4E4E4E] mt-8 ml-10">
                {feedback?.message}
              </p>
              <div className="flex flex-col sm:flex-row items-center sm:space-x-16 mt-4 sm:mt-8">
                <div className="text-center sm:text-left mb-4 sm:mb-0">
                  <p className="text-[#000F40] text-lg sm:text-xl font-semibold">
                    {feedback?.clientname}
                  </p>
                  <span className="text-[#4E4E4E] text-sm sm:text-md font-semibold">
                    {feedback?.place}
                  </span>
                </div>
                <img src={feedback?.logo} alt="Client Logo" className="h-10" />
              </div>
            </div>
            <img
              src={feedback?.clientImg}
              alt="Client"
              className="mt-4 sm:mt-1 h-[200px] sm:h-[300px] object-cover"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ClientFeedback;


// import React from "react";

// const ClientFeedback = ({ caseStudy }) => {
//   return (
//     <div
//       className="w-full h-[60vh] relative my-[10rem]"
//       style={{ background: caseStudy?.clientbgColor }}
//     >
//       <h1 className="text-3xl text-[#5c381f] font-semibold ml-10 py-10">
//         Our Client Feedback
//       </h1>
//       {caseStudy?.feedback?.feedbackCard?.map((feedback, index) => (
//         <div
//           key={index}
//           className="absolute -bottom-16 border rounded-xl py-4 px-7 shadow-lg mx-[15rem] z-10"
//           style={{ background: feedback?.cardbgColor }}
//         >
//           <div className="flex justify-between relative">
//             <div className="flex flex-col justify-between">
//               <span className="absolute semi -top-24">“</span>
//               <p className="text-[18px] text-[#4E4E4E] font-semibold mt-8">
//                 {feedback?.message}
//               </p>
//               <div className="flex space-x-16">
//                 <div>
//                   <p className="text-[#000F40] text-2xl font-semibold">
//                     {feedback?.clientname}
//                   </p>
//                   <span className="text-[#4E4E4E] text-[14px] font-semibold">
//                     {feedback?.place}
//                   </span>
//                 </div>
//                 <img src={feedback?.logo} alt="Client Logo" className="h-10" />
//               </div>
//             </div>
//             <img
//               src={feedback?.clientImg}
//               alt="Client"
//               className="mt-1 h-[300px]"
//             />
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ClientFeedback;
