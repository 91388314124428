import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import Frontend from "../assets/images/services/Frontend.svg";
import Backend from "../assets/images/services/Backend.png";
import Mobile_App from "../assets/images/services/MobileApp.png";
import Servers_Cloud from "../assets/images/services/Servers & Cloud.png";
import Databases from "../assets/images/services/Databases.png";
import AI_Natural from "../assets/images/services/AI & Natural.svg";
import text_img from "../assets/images/text_img.svg";

const TechnologiesPlatforms = () => {
  const technologiesNames = [
    {
      image: Frontend,
      ItemName: "UI/UX Design",
      ItemType:
        "Using the latest technology and frameworks, we bring your vision to life with pixel-perfect precision.",
      border: "#FFEBCD",
      background: "#FFFEFC",
    },
    {
      image: Backend,
      ItemName: "Frontend / Backend Development",
      ItemType:
        "Our Backend and Frontend developers excels at architecting and implementing the backbone of your software.",
      border: "#BCFF94",
      background: "#FCFFFA",
    },
    {
      image: Mobile_App,
      ItemName: "Website / Mobile App Development",
      ItemType:
        "Whether you’re targeting iOS, Android, or cross-platform development, we have the expertise to bring your app to life.",
      border: "#CBE0FF",
      background: "#FBFCFF",
    },
    {
      image: Servers_Cloud,
      ItemName: "Servers & Cloud Infrastructure",
      ItemType:
        "We leverage industry-leading cloud platforms, such as AWS, Azure, Google Cloud, to create agile and scalable solutions.",
      border: "#FFD9EE",
      background: "#FFFCFE",
    },
    {
      image: Databases,
      ItemName: "Databases",
      ItemType:
        "Our experts design robust database architectures tailored to your specific needs, ensuring efficiency & scalability.",
      border: "#D4DDFF",
      background: "#F9FBFF",
    },
    {
      image: AI_Natural,
      ItemName: "AI & Natural Languages",
      ItemType:
        "With our expertise in AI and LLMs, we help you create custom solutions that propel your business to new heights.",
      border: "#FFD9D9",
      background: "#FFFCFC",
    },
  ];

  const [showTitle, setShowTitle] = useState(false);
  const [showCards, setShowCards] = useState(false);
  const titleControls = useAnimation();
  const cardControls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const sectionTop = document.getElementById("services").offsetTop;
      if (scrollY > sectionTop - window.innerHeight / 2) {
        setShowTitle(true);
        setShowCards(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (showTitle) {
      titleControls.start({ opacity: 1, y: 0, transition: { duration: 0.5 } });
    }
    if (showCards) {
      cardControls.start((i) => ({
        opacity: 1,
        y: 0,
        transition: { delay: i * 0.4, duration: 0.8, ease: "easeInOut" },
      }));
    }
  }, [showTitle, showCards, titleControls, cardControls]);

  return (
    <div id="services" className="flex flex-col mt-[20vh] mb-[10vh]">
      <div className="text-center text-xl md:text-3xl font-semibold mb-10 relative">
        <motion.div className="flex flex-col items-center relative"
          initial={{ opacity: 0, y: 20 }}
          animate={titleControls}
        >
          <h1
            className="text-[#000F40] mb-2"
          >
            We are development experts on all <br />
            <span className="text-[#002298]">Technologies</span> &amp;
            <span className="text-[#002298]"> Platforms</span>
          </h1>
          <img
            src={text_img}
            alt="Button Icon"
            className="h-6 absolute -top-2 right-1 md:right-[32%] md-transform md-translate-x-[-50%] lg:right-[32%] lg-transform lg-translate-x-[-50%]"
          />
        </motion.div>
      </div>

      <div className="flex flex-wrap justify-center">
        {showCards &&
          technologiesNames.map((tech, index) => (
            <motion.div
              key={index}
              className="m-4"
              initial={{ opacity: 0, y: 20 }}
              animate={cardControls}
              custom={index}
            >
              <div
                className="rounded-lg p-8 h-full w-[300px] md:w-[400px]"
                style={{
                  border: `1px solid ${tech.border}`,
                  background: tech.background,
                }}
              >
                <img
                  src={tech.image}
                  alt={tech.ItemName}
                  className="w-12 h-12 mb-4"
                />
                <h2 className="text-[18px] text-[#000F40] font-semibold mb-2">
                  {tech.ItemName}
                </h2>
                <p className="text-[#4E4E4E] leading-6 text-sm">{tech.ItemType}</p>
              </div>
            </motion.div>
          ))}
      </div>
    </div>
  );
};

export default TechnologiesPlatforms;




{
  /* <div className="absolute">
  <img
    src={Service_bg}
    alt="Service_bg"
    className="h-[47rem] w-[98rem]"
  />
</div> */
}
{
  /* <div className="flex justify-center text-center w-full my-[10vh]">
  {!hideServices && (
    <div className="relative w-1/6">
      {technologiesNames.slice(0, 3).map((item, index) => (
        <div
          key={index}
          className={`mb-[8vh] ${
            index === 0 || index === 2 ? "mr-[-12rem]" : ""
          }`}
        >
          <div className="flex flex-col items-center">
            <h2
              className="text-2xl font-medium"
              style={{ color: item.color, whiteSpace: "nowrap" }}
            >
              {item.ItemName}
            </h2>
            <p className="text-gray-500 text-sm w-[18rem]">
              {item.ItemType}
            </p>
          </div>
        </div>
      ))}
    </div>
  )}
  <div className="relative mx-[6vh]">
    <img
      src={Services_struc}
      alt="Service"
      className="h-[22rem] w-[40rem] mb-[14vh]"
    />
    <div className="absolute mb-[5vh] inset-0 flex items-center justify-center">
      <div className="w-[24vh] h-[24vh] bg-white rounded-full shadow-2xl mb-[8vh]">
        <div className="flex flex-col">
          <p className="text-[25px] font-medium mt-[9vh] text-darkblue">
            Services
          </p>
          <div className="ml-12 mt-3">
            <div className="flex space-x-2">
              {[...Array(numberOfCircles)].map((_, index) => (
                <div
                  key={index}
                  className="h-2 w-2 rounded-full"
                  style={{
                    background: `linear-gradient(to right, ${
                      colors[index % colors.length]
                    }, ${colors2[index % colors2.length]})`,
                    animationDelay: `${index * 0.2}s`,
                  }}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  {!hideServices && (
    <div className="relative w-1/6">
      {technologiesNames.slice(3).map((item, index) => (
        <div
          key={index}
          className={`mb-[8vh] ${
            index === 0 || index === 2 ? "ml-[-12rem]" : ""
          }`}
        >
          <div className="flex flex-col items-center">
            <h2
              className="text-2xl font-medium"
              style={{ color: item.color, whiteSpace: "nowrap" }}
            >
              {item.ItemName}
            </h2>
            <p className="text-gray-500 text-sm w-[18rem]">
              {item.ItemType}
            </p>
          </div>
        </div>
      ))}
    </div>
  )}
</div> */
}

