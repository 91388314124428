import React from "react";

const Map = () => {
  return (
    <div className="my-16 mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
      <div className="flex flex-col items-center justify-center md:flex-row space-y-6 md:space-y-0 md:space-x-16">
        <div className="text-start md:text-left">
          <h1 className="text-xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-2">
            Know about us in <span className="text-[#002298]">Details</span>
          </h1>
          <p className="text-[10px] md:text-[18px] text-[#4E4E4E]">
            We are based out of India, and we have an amazing team of Backend
            and AI/ML engineers, Frontend Engineers, and Product Managers.
          </p>
        </div>
        <div className="w-full md:w-1/2 lg:w-3/5 xl:w-2/3">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5573.935225357483!2d88.4860687801493!3d22.586430309819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a020b2730768437%3A0x8c363ad110418c95!2sMani%20Casadona!5e0!3m2!1sen!2sin!4v1702271586817!5m2!1sen!2sin"
            className="w-full h-[300px] md:h-[400px] lg:h-[500px] xl:h-[600px]"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Map;
