import React from "react";

const TechStackHeader = ({ caseStudy }) => {
  return (
    <div className="mx-4 sm:mx-6 md:mx-10 my-6">
      <div
        style={{ background: caseStudy?.bgColor }}
        className="flex flex-col md:flex-row items-center justify-center w-full h-auto md:h-[80vh] rounded-[2rem] p-4"
      >
        <div className="max-w-3xl mx-4 md:mx-8">
          <h1 className="text-md sm:text-lg font-bold">{`Case Study: ${caseStudy?.name}`}</h1>
          <p className="text-2xl sm:text-3xl md:text-4xl font-bold my-4 md:my-8">{caseStudy?.title}</p>
          <div>
            <h2 className="text-md sm:text-lg font-bold mb-2 md:mb-4">Tech Stack:</h2>
            <div className="flex flex-wrap space-x-2">
              {caseStudy?.techStacks.map((tech, index) => (
                <div key={index} className="rounded-xl bg-black">
                  <img src={tech} alt="Tech" className="h-10 sm:h-12 md:h-14 p-2" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-4 md:mt-0 md:ml-8">
          <img
            src={caseStudy?.bgImage}
            alt="bgImage"
            className="h-auto md:h-[350px] w-full md:w-full object-cover rounded-[1rem]"
          />
        </div>
      </div>
    </div>
  );
};

export default TechStackHeader;





// import React from "react";

// const TechStackHeader = ({ caseStudy }) => {
//   return (
//     <div className="mx-10 my-6">
//       <div
//         style={{ background: caseStudy?.bgColor }}
//         className="flex items-center justify-center w-full h-[80vh] rounded-[2rem]"
//       >
//         <div className="max-w-4xl mx-8">
//           <h1 className="text-lg font-bold">Case Study:{caseStudy?.name}</h1>
//           <p className="text-4xl font-bold my-8">{caseStudy?.title}</p>
//           <div>
//             <h2 className="text-lg font-bold mb-4">Tech Stack:</h2>
//             <div className="flex space-x-2">
//               {caseStudy?.techStacks.map((tech, index) => (
//                 <div key={index} className="rounded-xl bg-black">
//                   <img src={tech} alt="Tech" className="h-14 p-2" />
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//         <div className="mr-8">
//           <img
//             src={caseStudy?.bgImage}
//             alt="bgImage"
//             className="h-[350px] w-[160vh]"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TechStackHeader;
