import React, { useEffect, useState } from "react";
import HappyChat from "../assets/images/happychat.png";
import CloudEng from "../assets/images/cloud-eng.png";
import CM from "../assets/images/cm_logo.png";
import adgpt from "../assets/images/adgpt.png";
import Upwork from "../assets/images/upwork-1.svg";
import Dronetek from "../assets/images/Dronetek.svg";
import Skipit from "../assets/images/skipit.png";
import AiTopTools from "../assets/images/AiTopTools.png";
import imongu from "../assets/images/imongu2.svg";
import ancoraa from "../assets/images/ancoraa.svg";
import Jaba from "../assets/images/jaba.png";
import Rapiq from "../assets/images/rapiq_logo.png";
import SimplyFema from "../assets/images/Simply_Fema.png";
import text_img from "../assets/images/text_img.svg";

const projectsData = [
  { name: "HappyChat", image: HappyChat },
  { name: "Rapiq", image: Rapiq },
  { name: "Jaba", image: Jaba },
  { name: "Upwork", image: Upwork, text: "Upwork" },
  { name: "imongu", image: imongu },
  { name: "Dronetek", image: Dronetek },
  { name: "SimplyFema", image: SimplyFema, text: "Simply Fema" },
  { name: "ancoraa", image: ancoraa },
  { name: "Skipit", image: Skipit, text: "Skipit.ai" },
  { name: "CloudEng", image: CloudEng },
  { name: "AiTopTools", image: AiTopTools },
  { name: "CM", image: CM, text: "Creatives Matter" },
  { name: "adgpt", image: adgpt },
];

const ProjectsName = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const marqueeWidth = projectsData.length * 100; // Adjust this width according to your design

  useEffect(() => {
    const scroll = setInterval(() => {
      setScrollPosition((scrollPosition) => {
        if (scrollPosition < marqueeWidth) {
          return scrollPosition + 1;
        } else {
          return 0;
        }
      });
    }, 30); // Adjust the scrolling speed as needed

    return () => clearInterval(scroll);
  }, [marqueeWidth]);

  return (
    <>
      <div className="hidden md:flex justify-center my-12">
        <h1 className="text-xl md:text-3xl text-center relative z-10 text-[#000] font-semibold">
          Already trusted by <span className="text-[#002298]">Brands</span>
        </h1>
        <img src={text_img} alt="Button Icon" className="h-6 hidden md:block" />
      </div>

      <div className="block md:hidden overflow-hidden shadow-xl">
        <div className="flex items-center">
          <div className="bg-[#002298] p-3" style={{ width: '140px' }}>
            <h1 className="text-[16px] text-[#fff]">
              Already trusted by Brands
            </h1>
          </div>
          <div className="flex overflow-hidden" style={{ width: 'calc(100% - 140px)' }}>
            <div className="flex" style={{ transform: `translateX(-${scrollPosition}px)`, whiteSpace: 'nowrap' }}>
              {projectsData.map((project, index) => (
                <div key={index} className="flex flex-row gap-0.5 items-center mr-4">
                  <img
                    src={project.image}
                    alt={project.name}
                    className="max-w-xs h-8 mx-auto"
                  />
                  {project.text && <span className="block text-center">{project.text}</span>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="hidden md:flex flex-wrap justify-center gap-14 mb-4">
        {projectsData.map((project, index) => (
          <div key={index} className="flex flex-row gap-0.5 items-center mb-4">
            <img src={project.image} alt={project.name} className="h-8 mx-auto" />
            {project.text && <p className="text-xl">{project.text}</p>}
          </div>
        ))}
      </div>
    </>
  );
};

export default ProjectsName;






// import React from "react";
// import { useEffect, useState } from "react";
// import { motion } from "framer-motion";
// import Vector from "../assets/images/Vector.png";
// import HappyChat from "../assets/images/happychat.png";
// import CloudEng from "../assets/images/cloud-eng.png";
// import CM from "../assets/images/cm_logo.png";
// import adgpt from "../assets/images/adgpt.png";
// import Upwork from "../assets/images/upwork-1.svg";
// import Dronetek from "../assets/images/Dronetek.svg";
// import Skipit from "../assets/images/skipit.png";
// import AiTopTools from "../assets/images/AiTopTools.png";
// import imongu from "../assets/images/imongu2.svg";
// import ancoraa from "../assets/images/ancoraa.svg";
// import Jaba from "../assets/images/jaba.png";
// import Rapiq from "../assets/images/rapiq_logo.png";
// import SimplyFema from "../assets/images/Simply_Fema.png";
// import text_img from "../assets/images/text_img.svg";

// const projectsData = [
//   { name: "HappyChat", image: HappyChat },
//   { name: "Rapiq", image: Rapiq },
//   { name: "Jaba", image: Jaba },
//   { name: "Upwork", image: Upwork, text: "Upwork" },
//   { name: "imongu", image: imongu },
//   { name: "Dronetek", image: Dronetek },
//   { name: "SimplyFema", image: SimplyFema, text: "Simply Fema" },
//   { name: "ancoraa", image: ancoraa },
//   { name: "Skipit", image: Skipit, text: "Skipit.ai" },
//   { name: "CloudEng", image: CloudEng },
//   { name: "AiTopTools", image: AiTopTools },
//   { name: "CM", image: CM, text: "Creatives Matter" },
//   { name: "adgpt", image: adgpt },
// ];

// const containerVariants = {
//   hidden: { opacity: 0, y: -50 },
//   visible: {
//     opacity: 1,
//     y: 0,
//     transition: {
//       delay: 0.2,
//       duration: 0.6,
//       when: "beforeChildren",
//       staggerChildren: 0.3,
//     },
//   },
// };

// const itemVariants = {
//   hidden: { opacity: 0, y: -30 },
//   visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
// };

// const ProjectsName = () => {
//   const lines = [projectsData.slice(0, 6), projectsData.slice(6, 12)];

//   return (
//     <motion.div
//       initial="hidden"
//       whileInView="visible"
//       viewport={{ once: true, amount: 0.5 }}
//       variants={containerVariants}
//     >
//       <motion.div className="flex justify-center my-12" variants={itemVariants}>
//         <h1 className="text-xl md:text-3xl text-center relative z-10 text-[#000] font-semibold">
//           Already trusted by <span className="text-[#002298]">Brands</span>
//         </h1>
//         <img src={text_img} alt="Button Icon" className="h-6 hidden md:block" />
//       </motion.div>
      // {lines.map((line, lineIndex) => (
      //   <div
      //     key={lineIndex}
      //     className="flex flex-wrap justify-center gap-14 mb-4"
      //   >
      //     {line.map((project, index) => (
      //       <motion.div
      //         key={index}
      //         className="flex flex-row gap-0.5 items-center mb-4"
      //         variants={itemVariants}
      //       >
      //         <img
      //           src={project.image}
      //           alt={project.name}
      //           className="h-8 mx-auto"
      //         />
      //         {project.text && <p className="text-xl">{project.text}</p>}
      //       </motion.div>
      //     ))}
      //   </div>
      // ))}
//     </motion.div>
//   );
// };

// export default ProjectsName;



// import React from "react";
// import Vector from "../assets/images/Vector.png";
// import HappyChat from "../assets/images/happychat.png";
// import CloudEng from "../assets/images/cloud-eng.png";
// import CM from "../assets/images/cm_logo.png";
// import adgpt from "../assets/images/adgpt.png";
// import Upwork from "../assets/images/upwork-1.svg";
// import Skipit from "../assets/images/skipit.png";
// import AiTopTools from "../assets/images/AiTopTools.png";
// import imongu from "../assets/images/imongu2.svg";
// import ancoraa from "../assets/images/ancoraa.svg";
// import Jaba from "../assets/images/jaba.png";
// import Rapiq from "../assets/images/rapiq_logo.png";
// import SimplyFema from "../assets/images/Simply_Fema.png";
// import text_img from "../assets/images/text_img.svg";

// const projectsData = [
//   { name: "HappyChat", image: HappyChat },
//   { name: "Rapiq", image: Rapiq },
//   { name: "Jaba", image: Jaba },
//   { name: "Upwork", image: Upwork, text: "Upwork" },
//   { name: "imongu", image: imongu },
//   { name: "SimplyFema", image: SimplyFema, text: "Simply Fema" },
//   { name: "ancoraa", image: ancoraa },
//   { name: "Skipit", image: Skipit, text: "Skipit.ai" },
//   { name: "CloudEng", image: CloudEng },
//   { name: "AiTopTools", image: AiTopTools },
//   { name: "CM", image: CM, text: "Creatives Matter" },
//   { name: "adgpt", image: adgpt },
// ];

// const ProjectsName = () => {
//   const lines = [projectsData.slice(0, 6), projectsData.slice(6, 12)];

//   return (
//     <>
//       <div className="flex justify-center my-12">
//         <h1 className="text-xl md:text-3xl text-center relative z-10 text-[#000] font-semibold">
//           Already trusted by <span className="text-[#002298]">Brands</span>
//         </h1>
//         <img src={text_img} alt="Button Icon" className="h-6 hidden md:block" />
//       </div>
//       {lines.map((line, lineIndex) => (
//         <div
//           key={lineIndex}
//           className="flex flex-wrap justify-center gap-14 mb-4"
//         >
//           {line.map((project, index) => (
//             <div
//               key={index}
//               className="flex flex-row gap-0.5 items-center mb-4"
//             >
//               <img
//                 src={project.image}
//                 alt={project.name}
//                 className="h-10 mx-auto"
//               />
//               {project.text && <p className="text-xl">{project.text}</p>}
//             </div>
//           ))}
//         </div>
//       ))}
//       {/* </div> */}
//     </>
//   );
// };

// export default ProjectsName;
