// import React, { useState } from 'react';
// import FAQ_Img from '../assets/images/FAQ.png';
// import arrow_Up from "../assets/icons/arrow-up.svg";
// import arrow_Down from "../assets/icons/arrow-down.svg";

// const FAQ = () => {
//   const [expandedIndex, setExpandedIndex] = useState(null);

//   const faqCards = [
//     {
//       question: "What services do you offer?",
//       answer: "We offer a comprehensive range of software services including custom software development, web and mobile application development, software consulting, system integration, cloud solutions, and maintenance and support. Our team of experts is dedicated to delivering tailored solutions that meet your specific business needs."
//     },
//     {
//       question: "How do you ensure the quality of your software?",
//       answer: "We ensure the quality of our software through rigorous testing and quality assurance processes. Our approach includes code reviews, automated testing, and manual testing to identify and fix issues early. We also follow best practices and industry standards to ensure the software is reliable, efficient, and scalable."
//     },
//     {
//       question: "What is your project development process?",
//       answer: "Our project development process begins with requirement analysis to understand your needs. We then proceed with planning, design, development, testing, and deployment. Throughout the process, we maintain clear communication and collaboration with you to ensure the project meets your expectations and business goals."
//     },
//     {
//       question: "How do you handle data security and privacy?",
//       answer: "We prioritize data security and privacy by implementing robust security measures such as data encryption, access controls, and regular security audits. Our practices comply with industry standards and regulations to ensure your data is protected at all times."
//     },
//     {
//       question: "Can you provide examples of your past projects?",
//       answer: "Yes, we have successfully delivered projects across various industries. For example, we developed a mobile banking app for a financial institution, an e-commerce platform for a retail client, and a cloud-based ERP system for a manufacturing company. Our portfolio showcases our ability to deliver high-quality solutions tailored to our clients' needs."
//     },
//   ];

//   const toggleAnswer = (index) => {
//     setExpandedIndex(expandedIndex === index ? null : index);
//   };

//   return (
//     <div className="max-w-full mx-auto p-6 bg-[#F8F6FF] ">
//       <div className="text-center my-10 mx-72">
//         <h1 className="text-3xl font-bold mb-4">Frequently Asked <span className='text-[#002298]'>Questions</span></h1>
//         <p className="text-[#4E4E4E]">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
//       </div>
//       <div className="flex flex-col md:flex-row items-start md:items-center">
//         <div className="w-full md:w-1/2 p-16 space-y-4">
//           {faqCards?.map((card, index) => (
//             <div key={index} className="border-b border-[#EAE8E9] pb-4">
//               <h2
//                 className={`text-lg font-semibold cursor-pointer flex items-center justify-between ${expandedIndex === index ? 'text-[#002298]' : ''}`}
//                 onClick={() => toggleAnswer(index)}
//               >
//                 {card?.question}
//                 <img 
//                   src={expandedIndex === index ? arrow_Up : arrow_Down} 
//                   alt={expandedIndex === index ? "Arrow Up" : "Arrow Down"} 
//                   className="w-4 h-4 ml-2" 
//                 />
//               </h2>
//               {expandedIndex === index && <p className="mt-2 text-sm text-[#4E4E4E] leading-6">{card?.answer}</p>}
//             </div>
//           ))}
//         </div>
//         <div className="w-full md:w-1/2 mt-8 md:mt-0">
//           <img src={FAQ_Img} alt="FAQ Illustration" className="w-[450px] h-[450px] object-cover" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FAQ;

import React, { useState } from 'react';
import FAQ_Img from '../assets/images/FAQ.png';
import arrow_Up from "../assets/icons/arrow-up.svg";
import arrow_Down from "../assets/icons/arrow-down.svg";

const FAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const faqCards = [
    {
      question: "What services do you offer?",
      answer: "We offer a comprehensive range of software services including custom software development, web and mobile application development, software consulting, system integration, cloud solutions, and maintenance and support. Our team of experts is dedicated to delivering tailored solutions that meet your specific business needs."
    },
    {
      question: "How do you ensure the quality of your software?",
      answer: "We ensure the quality of our software through rigorous testing and quality assurance processes. Our approach includes code reviews, automated testing, and manual testing to identify and fix issues early. We also follow best practices and industry standards to ensure the software is reliable, efficient, and scalable."
    },
    {
      question: "What is your project development process?",
      answer: "Our project development process begins with requirement analysis to understand your needs. We then proceed with planning, design, development, testing, and deployment. Throughout the process, we maintain clear communication and collaboration with you to ensure the project meets your expectations and business goals."
    },
    {
      question: "How do you handle data security and privacy?",
      answer: "We prioritize data security and privacy by implementing robust security measures such as data encryption, access controls, and regular security audits. Our practices comply with industry standards and regulations to ensure your data is protected at all times."
    },
    {
      question: "Can you provide examples of your past projects?",
      answer: "Yes, we have successfully delivered projects across various industries. Please checkout our case studies section for more details https://growth-loop.io/case-studies"
    },
  ];

  const toggleAnswer = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="max-w-full mx-auto p-6 bg-[#F8F6FF]">
      <div className="text-center my-10 mx-4 md:mx-16 lg:mx-32 xl:mx-64">
        <h1 className="text-3xl font-bold mb-4">Frequently Asked <span className='text-[#002298]'>Questions</span></h1>
        <p className="text-[#4E4E4E]"></p>
      </div>
      <div className="flex flex-col md:flex-row items-start md:items-center">
        <div className="w-full md:w-1/2 p-4 md:p-8 lg:p-16 space-y-4">
          {faqCards?.map((card, index) => (
            <div key={index} className="border-b border-[#EAE8E9] pb-4">
              <h2
                className={`text-lg font-semibold cursor-pointer flex items-center justify-between ${expandedIndex === index ? 'text-[#002298]' : ''}`}
                onClick={() => toggleAnswer(index)}
              >
                {card?.question}
                <img 
                  src={expandedIndex === index ? arrow_Up : arrow_Down} 
                  alt={expandedIndex === index ? "Arrow Up" : "Arrow Down"} 
                  className="w-4 h-4 ml-2" 
                />
              </h2>
              {expandedIndex === index && <p className="mt-2 text-sm text-[#4E4E4E] leading-6">{card?.answer}</p>}
            </div>
          ))}
        </div>
        <div className="w-full md:w-1/2 mt-8 md:mt-0 flex justify-center">
          <img src={FAQ_Img} alt="FAQ Illustration" className="w-64 h-64 md:w-80 md:h-80 lg:w-96 lg:h-96 object-cover" />
        </div>
      </div>
    </div>
  );
};

export default FAQ;

