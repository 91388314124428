import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const Item = ({ Links, title }) => {
  const [activeItem, setActiveItem] = useState(localStorage.getItem("activeFooterItem") || "");

  useEffect(() => {
    const currentPath = window.location.pathname;
    const currentItem = Links.find(link => link.path === currentPath || link.link === currentPath);
    if (currentItem) {
      setActiveItem(currentItem.name);
    }
  }, [Links]);

  const handleItemClick = (name) => {
    setActiveItem(name);
    localStorage.setItem("activeFooterItem", name);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <h3 className="mb-6 font-semibold">{title}</h3>
      <ul>
        {Links.map((link) => (
          <li key={link.name} className="mb-4">
            {link.path ? (
              <NavLink
                to={link.path}
                onClick={() => handleItemClick(link.name)}
                className={`text-[#4E5663] hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6 hover-underline ${
                  activeItem === link.name ? "font-bold" : ""
                }`}
                activeClassName="text-blue-600"
              >
                {link.name}
              </NavLink>
            ) : (
              <a
                href={link.link}
                onClick={() => handleItemClick(link.name)}
                className={`text-[#4E5663] hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6 hover-underline ${
                  activeItem === link.name ? "font-bold" : ""
                }`}
              >
                {link.name}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Item;




// import React from "react";

// const Item = ({ Links, title }) => {
//   return (
//     <ul className="space-y-3">
//       <h1 className="mb-1 text-[#000F40] font-semibold">{title}</h1>
//       {Links.map((link) => (
//         <li key={link.name}>
//           <a
//             className="text-[#4E5663] hover:text-teal-400 duration-300
//           text-sm cursor-pointer leading-6"
//             href={link.link}
//           >
//             <span className="hover-underline">{link.name}</span>
//           </a>
//         </li>
//       ))}
//     </ul>
//   );
// };

// export default Item;
