import React from "react";

const WebsiteGrowth = ({ caseStudy }) => {
  return (
    <div
      className={`border-2 border-x-0 py-16 my-10 bg-white ${caseStudy?.growthMetrics?.rateBorderColor}`}
    >
      <div className="mx-4 sm:mx-6 mb-4 text-start">
        <h1 className="text-2xl sm:text-3xl font-semibold">Website Growth Metrics</h1>
      </div>
      <div className="grid grid-cols-2 gap-4 sm:flex sm:flex-row justify-between items-center mx-4 sm:mx-8 lg:mx-16 my-6">
        {caseStudy.growthMetrics.rate?.map((growth, index) => (
          <div key={index} className="flex flex-col items-center text-center">
            <div
              className={`border-[3px] my-4 py-2.5 px-1.5 rounded-full ${caseStudy?.growthMetrics?.rateBorderColor}`}
            >
              <span
                className={`text-base md:text-[15px] lg:text-[16px] ${caseStudy?.growthMetrics?.rateTextColor}`}
              >
                {growth?.percent}
              </span>
            </div>
            <h1 className="text-md sm:text-lg text-[#444] font-semibold">
              {growth?.title}
            </h1>
            <h2 className="text-sm sm:text-base text-[#444]">
              {growth?.subtitle}
            </h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WebsiteGrowth;


// import React from "react";

// const WebsiteGrowth = ({ caseStudy }) => {
//   return (
//     <div
//       className={`border-2 border-x-0 py-16 my-10 bg-white ${caseStudy?.growthMetrics?.rateBorderColor}`}
//     >
//       <div className="mx-6 mb-4 text-start">
//         <h1 className="text-3xl font-semibold ">Website Growth Metrics</h1>
//       </div>
//       <div className="flex justify-between items-center mx-16 my-6">
//         {caseStudy.growthMetrics.rate?.map((growth, index) => (
//           <div key={index} className="flex flex-col items-center">
//             <div
//               className={`border-[3px] my-4 p-2 rounded-[100%] ${caseStudy?.growthMetrics?.rateBorderColor}`}
//             >
//               <span
//                 className={`text-[12px] ${caseStudy?.growthMetrics?.rateTextColor}`}
//               >
//                 {growth?.percent}
//               </span>
//             </div>
//             <h1 className="text-lg text-[#444] font-semibold">
//               {growth?.title}
//             </h1>
//             <h2 className="text-[#444] text-[15px]">{growth?.subtitle}</h2>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default WebsiteGrowth;
