import React from "react";
import Image_1 from "../assets/images/image-1.png";
import Image_2 from "../assets/images/image-2.png";
import Image_3 from "../assets/images/image-3.png";
import Image_4 from "../assets/images/image-4.png";
import Image_5 from "../assets/images/image-5.png";
import Image_6 from "../assets/images/image-6.png";
import text_img from "../assets/images/text_img.svg";

const ChooseGrowthLoops = () => {
  const menuItems = [
    {
      text: "Agile & Adaptive",
      paragraph:
        "Our iterative approach ensures we can quickly respond to changes and deliver solutions that evolve alongside your needs.",
      image: Image_1,
    },
    {
      text: "Rapid Development",
      paragraph:
        "Our streamlined workflows and efficient coordination enable swift project delivery.",
      image: Image_2,
    },
    {
      text: "Flexible Engagement",
      paragraph:
        "We offer a variety of engagement models to fit your business needs, including co-development, fixed price, or time and materials (T&M).",
      image: Image_3,
    },
    {
      text: "Affordable Cost",
      paragraph:
        "We provide exceptional value at competitive rates with transparent pricing models.",
      image: Image_4,
    },
    {
      text: "Local Convenience",
      paragraph:
        " We can align our working hours with your time zone for unmatched convenience.",
      image: Image_5,
    },
    {
      text: "Remote Development",
      paragraph:
        "We have the capabilities to collaborate seamlessly with you regardless of location.",
      image: Image_6,
    },
  ];

  return (
    <>
      <div className="flex flex-col lg:flex-row justify-between">
        <div className="lg:w-1/2 text-center lg:text-left">
          <div className="mt-10 lg:mt-[30vh] ml-4 md:ml-12 lg:ml-16">
            <div className="flex justify-center lg:justify-start relative">
              <h3 className="md:text-2xl text-[16px] text-[#000]">Why Choose</h3>
              <img
                src={text_img}
                alt="Text Icon"
                className="h-6 ml-5 absolute -top-1 right-24 md:-top-1 md:left-28 lg:-top-1 lg:left-28"
              />
            </div>
            <h1 className="text-[#002298] text-xl md:text-3xl lg:text-3xl mt-2 font-semibold">
              Growth Loops <span className="text-[#000]">Technology?</span>
            </h1>
          </div>
        </div>
        <div className="lg:w-2/3">
          <div className="flex flex-col justify-start w-full mt-10 lg:mt-[20vh] overflow-y-auto h-[20.8rem] md:h-[28rem] no-scrollbar">
            {menuItems.map((item, index) => (
              <div
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-[#F3F6FF]" : "bg-white"
                } p-6 sm:p-10 lg:p-20 flex justify-center items-center w-full h-[14rem]`}
              >
                <div className="flex flex-row lg:flex-row items-center w-full">
                  {index % 2 === 0 ? (
                    <>
                      <img
                        src={item.image}
                        alt={item.text}
                        className="h-[70px] lg:h-32 my-4 lg:my-0"
                      />
                      <div className="flex-1 ml-4">
                        <h3 className="text-[18px] text-[#000F40] font-semibold mb-2">
                          {item.text}
                        </h3>
                        <p className="text-sm md:text-sm lg:text-sm text-[#4E4E4E]">
                          {item.paragraph}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex-1 mr-4">
                        <h3 className="text-[18px] text-[#000F40] font-semibold mb-2">
                          {item.text}
                        </h3>
                        <p className="text-sm md:text-sm lg:text-sm text-[#4E4E4E]">
                          {item.paragraph}
                        </p>
                      </div>
                      <img
                        src={item.image}
                        alt={item.text}
                        className="h-[70px] lg:h-32 my-4 lg:my-0"
                      />
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseGrowthLoops;




// import React from "react";
// import Image_1 from "../assets/images/image-1.png";
// import Image_2 from "../assets/images/image-2.png";
// import Image_3 from "../assets/images/image-3.png";
// import Image_4 from "../assets/images/image-4.png";
// import Image_5 from "../assets/images/image-5.png";
// import Image_6 from "../assets/images/image-6.png";
// import text_img from "../assets/images/text_img.svg";

// const ChooseGrowthLoops = () => {
//   const menuItems = [
//     {
//       text: "Agile & Adaptive",
//       paragraph:
//         "Our iterative approach ensures we can quickly respond to changes and deliver solutions that evolve alongside your needs.",
//       image: Image_1,
//     },
//     {
//       text: "Rapid Development",
//       paragraph:
//         "Our streamlined workflows and efficient coordination enable swift project delivery.",
//       image: Image_2,
//     },
//     {
//       text: "Flexible Engagement",
//       paragraph:
//         "We offer a variety of engagement models to fit your business needs, including co-development, fixed price, or time and materials (T&M).",
//       image: Image_3,
//     },
//     {
//       text: "Affordable Cost",
//       paragraph:
//         "We provide exceptional value at competitive rates with transparent pricing models.",
//       image: Image_4,
//     },
//     {
//       text: "Local Convenience",
//       paragraph:
//         " We can align our working hours with your time zone for unmatched convenience.",
//       image: Image_5,
//     },
//     {
//       text: "Remote Development",
//       paragraph:
//         "We have the capabilities to collaborate seamlessly with you regardless of location.",
//       image: Image_6,
//     },
//   ];

//   return (
//     <>
//       <div className="flex flex-col lg:flex-row justify-between">
//         <div className="lg:w-1/2 text-center lg:text-left">
//           <div className="mt-10 lg:mt-[30vh] ml-4 md:ml-12 lg:ml-16 font-semibold">
//             <div className="flex justify-center lg:justify-start relative">
//               <h3 className="md:text-xl text-[16px] text-[#000]">Why Choose</h3>
//               <img
//                 src={text_img}
//                 alt="Button Icon"
//                 className="h-6 ml-2 absolute -top-1 right-24 md:-top-1 md:left-28 lg:-top-1 lg:left-28"
//               />
//             </div>
//             <h1 className="text-[#002298] text-xl md:text-3xl lg:text-3xl mt-2">
//               Growth Loops <span className="text-[#000]">Technology?</span>
//             </h1>
//           </div>
//         </div>
//         <div className="lg:w-2/3">
//           <div className="flex flex-col justify-start w-full mt-10 lg:mt-[20vh]">
//             {menuItems.map((item, index) => (
//               <div
//                 key={index}
//                 className={`${
//                   index % 2 === 0 ? "bg-[#E8F5FF]" : "bg-[#FFFFFF]"
//                 } p-6 sm:p-10 lg:p-20 flex justify-center items-center w-full h-[14rem]`}
//               >
//                 <div className="flex flex-row lg:flex-row items-center w-full">
//                   {index % 2 === 0 ? (
//                     <>
//                       <img
//                         src={item.image}
//                         alt={item.text}
//                         className="h-[70px] lg:h-32 my-4 lg:my-0"
//                       />
//                       <div className="flex-1">
//                         <h3 className="text-lg lg:text-xl text-[#000F40] font-semibold mb-2">
//                           {item.text}
//                         </h3>
//                         <p className="text-sm md:text-sm lg:text-sm text-[#464646]">
//                           {item.paragraph}
//                         </p>
//                       </div>
//                     </>
//                   ) : (
//                     <>
//                       <div className="flex-1">
//                         <h3 className="text-lg lg:text-xl text-[#000F40] font-semibold mb-2">
//                           {item.text}
//                         </h3>
//                         <p className="text-sm md:text-sm lg:text-sm text-gray-500">
//                           {item.paragraph}
//                         </p>
//                       </div>
//                       <img
//                         src={item.image}
//                         alt={item.text}
//                         className="h-[70px] lg:h-32 my-4 lg:my-0"
//                       />
//                     </>
//                   )}
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ChooseGrowthLoops;
