import React from "react";
import KnowAboutUs from "./KnowAboutUs";
import OurMission from "./OurMission";
import FounderDirector from "./FounderDirector";
import TeamMembersName from "./TeamMembersName";
import Map from "./Map";
const AboutUs = () => {
  return (
    <>
      <KnowAboutUs />
      <OurMission />
      <FounderDirector />
      <TeamMembersName />
      <Map />
    </>
  );
};

export default AboutUs;
