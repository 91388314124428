import React from "react";
import Ourmission from "../assets/images/AboutUs/OurMission.png";
import img_1 from "../assets/images/AboutUs/OurMissionImg.png";
import misson from "../assets/images/AboutUs/mission.png";
import { motion } from "framer-motion";

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const OurMission = () => {
  return (
    <div
      className="relative h-screen bg-cover bg-center my-2 md:my-14"
      style={{ backgroundImage: `url(${Ourmission})` }}
    >
      <div className="absolute inset-0 flex flex-col justify-center items-center md:flex-row p-4 md:p-8">
        <div className="flex flex-row items-center">
          <img
            src={misson}
            alt="Mission Image"
            className="h-24 md:h-64 mb-4 md:mb-0 md:mr-4"
          />
          <img
            src={img_1}
            alt="Mission Shape"
            className="h-36 md:h-96"
          />
        </div>
        <div className="text-black text-start px-4 md:px-8 max-w-2xl mt-4 md:mt-0">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            transition={{ duration: 0.6 }}
            variants={fadeInUp}
          >
            <h1 className="text-xl md:text-3xl mb-4 font-semibold">
              Our <span className="text-[#002298]">Mission</span>
            </h1>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            variants={fadeInUp}
          >
            <p className="text-sm text-gray-700 leading-4 md:leading-8">
              Our mission is to help entrepreneurs take their ideas from inception
              to reality. Our team of experienced developers, designers, and
              project managers work closely with clients to understand their
              vision and bring it to life through the development of custom
              software solutions. Whether it's a mobile app, web application, or
              software product, we are committed to delivering high-quality,
              scalable, and user-friendly solutions that meet the unique needs of
              each client. From ideation to launch and beyond, we are dedicated to
              providing ongoing support and maintenance to ensure the success of
              our clients' businesses.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default OurMission;

