import React, { useEffect } from "react";
import AboutUs_bg from "../assets/images/AboutUs/aboutus_bg.png";
import arrow_up from "../assets/images/AboutUs/arrow-up.svg";
import Rectangle2 from "../assets/images/AboutUs/Rectangle2.png";
import Rectangle3 from "../assets/images/AboutUs/Rectangle3.png";
import Rectangle4 from "../assets/images/AboutUs/Rectangle4.png";
import Rectangle6 from "../assets/images/AboutUs/Rectangle6.png";
import Rectangle8 from "../assets/images/AboutUs/Rectangle8.png";
import office_img1 from "../assets/images/AboutUs/office_img1.jpg";
import office_img2 from "../assets/images/AboutUs/office_img2.jpg";
import office_img3 from "../assets/images/AboutUs/office_img3.jpg";
import office_img4 from "../assets/images/AboutUs/office_img4.jpg";
import { NavLink } from "react-router-dom";

const KnowAboutUs = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="relative">
      <img
        src={AboutUs_bg}
        alt="AboutUs_bg"
        className="md:h-full h-[20rem] w-full relative"
      />
      <div className="absolute md:left-36 left-2 md:top-[17rem] top-[9rem] space-y-2">
        <h3 className="text-[10px] md:text-[18px]">
          Helping Brands and Businesses
        </h3>
        <div className="text-xl md:text-3xl font-semibold">
          <h1>
            GET TO KNOW <span className="text-[#002298]">ABOUT US</span>
          </h1>
        </div>
        <div className="flex items-end">
          <NavLink
            to="/schedule"
            target="_blank"
            className="text-white bg-[#091D62] border rounded-full py-1.5 pl-2 md:pl-5 pr-2 relative flex items-center space-x-1 md:space-x-2 cursor-pointer z-10"
          >
            <span className="">Let’s Connect</span>
            <div className="bg-[#5AC4FE] rounded-full p-0.5 md:p-1">
              <img src={arrow_up} alt="arrow_up" className="h-4 md:h-6" />
            </div>
          </NavLink>
        </div>
      </div>
      <div className="mr-14 md:bottom-0 bottom-0 left-0 right-0 absolute">
        <div className="flex justify-end">
          <img
            src={office_img1}
            alt="office_img"
            className="md:h-[170px] h-[50px] w-[50px] md:w-[178px] object-cover rounded-tr-[5rem]"
          />
        </div>
        <div className="flex justify-end">
          <img
            src={Rectangle4}
            alt="office_img"
            className="md:h-[170px] h-[50px]"
          />
          <img
            src={office_img3}
            alt="office_img"
            className="md:h-[170px] h-[50px] w-[50px] md:w-[178px] object-cover rounded-tl-[5rem]"
          />
        </div>
        <div className="flex justify-end">
          <img
            src={office_img2}
            alt="office_img"
            className="md:h-[170px] h-[50px] w-[100px] md:w-[650px] object-cover rounded-tl-[5rem] rounded-br-[5rem]"
          />
          <img
            src={Rectangle2}
            alt="office_img"
            className="md:h-[170px] h-[50px]"
          />
          <img
            src={Rectangle3}
            alt="office_img"
            className="md:h-[170px] h-[50px]"
          />
          <img
            src={office_img4}
            alt="office_img"
            className="md:h-[170px] h-[50px] object-cover w-[50px] md:w-[174px]"
          />
          <img
            src={Rectangle6}
            alt="office_img"
            className="md:h-[170px] h-[50px]"
          />
        </div>
      </div>
    </div>
  );
};

export default KnowAboutUs;
